import CommentForm from "./CommentForm";
import React from "react";
import {
  doAddPostComment,
  doUpdatePostComment,
} from "../../../state/actions/posts";
import { connect } from "react-redux";

const EditPostComment = ({
  groupId,
  postId,
  comment,
  updateComment,
  cancelEdit,
}) => {
  const update = (content) => {
    updateComment(groupId, postId, comment._id, { ...comment, content });
    cancelEdit();
  };

  return (
    <CommentForm comment={comment} onSubmit={update} cancel={cancelEdit} edit />
  );
};

const actions = {
  updateComment: doUpdatePostComment,
};

export default connect(null, actions)(EditPostComment);
