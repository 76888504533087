import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getContent, getTldr } from "../../../services/ContentService";
import DocEditor from "./DocEditor";
import DocTitle from "./DocTitle";
import DocContent from "./DocContent";
import { doAddDoc, doUpdateDoc } from "../../../state/actions/docs";
import { connect } from "react-redux";
import GroupChatContent from "../chat/GroupChatContent";
import GroupChatMessageForm from "../chat/GroupChatMessageForm";
import ChatFilesContainer from "../chat/ChatFilesContainer";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalEditor from "../../common/texteditor/lexical/LexicalEditor";
import { $getRoot } from "lexical";
import RichTextView from "../../common/texteditor/RichTextView";
import { Sparkles } from "lucide-react";

function DocContainer({ updateDoc }) {
  const { groupId, docId } = useParams();
  const [doc, setDoc] = useState(null);
  const [edit, setEdit] = useState(false);
  const [commentsPanelOpen, setCommentsPanelOpen] = useState(false);
  const [tab, setTab] = useState("summary");
  const editorRef = useRef(null);
  const [summary, setSummary] = useState("");

  useEffect(() => {
    loadDoc();
  }, []);

  const loadDoc = async () => {
    setDoc(await getContent(groupId, "doc", docId));
  };

  const onDocUpdate = (data) => {
    updateDoc(groupId, doc._id, data);
    cancelEdit();
  };

  const cancelEdit = () => {
    setEdit(false);
  };

  const generateSummary = async () => {
    const editor = editorRef.current;
    if (editor !== null) {
      const editorState = editor.getEditorState();
      editorState.read(async () => {
        const parsedText = $getRoot().getTextContent();
        let { tldr } = await getTldr({ content: parsedText });
        if (tldr) {
          tldr = tldr.replace("```html", "");
          tldr = tldr.replace("```", "");
          setSummary(tldr);
        }
      });
    }
  };

  if (!doc) {
    return null;
  }

  return (
    <div>
      <div
        className={`d-flex ${commentsPanelOpen ? "justify-content-between" : "justify-content-center"}`}
      >
        <div className={edit ? "col-md-9" : "col-md-8"}>
          {edit ? (
            <DocEditor
              groupId={groupId}
              docId={doc._id}
              doc={doc}
              submit={onDocUpdate}
              toggleDocs={cancelEdit}
              setCommentsPanelOpen={setCommentsPanelOpen}
            />
          ) : (
            <div className="d-flex">
              <div className="white-box flex-fill">
                <DocTitle
                  doc={doc}
                  groupId={groupId}
                  startEdit={() => setEdit(true)}
                  onDocDelete={() => setEdit(false)}
                />
                <DocContent
                  doc={doc}
                  setCommentsPanelOpen={setCommentsPanelOpen}
                />
              </div>
            </div>
          )}
        </div>
        {!edit && (
          <div className="col-md-4 ml-2">
            <div className="white-box">
              <div className="tabs-container">
                <ul className="nav nav-tabs d-flex border-bottom ">
                  <li onClick={() => setTab("summary")}>
                    <a
                      className={`nav-link1 ${tab === "summary" ? "active" : ""} dfac`}
                      style={{ fontWeight: 500 }}
                    >
                      <Sparkles
                        style={{ width: 20 }}
                        className="text-primary mr-2"
                      />
                      <span>Summary</span>
                    </a>
                  </li>
                  {/*<li onClick={() => setTab("files")}>*/}
                  {/*  <a*/}
                  {/*    className={`nav-link ${tab === "files" ? "active" : ""}`}*/}
                  {/*    style={{ fontWeight: 500 }}*/}
                  {/*  >*/}
                  {/*    Comments*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
                {tab === "summary" && (
                  <div className="my-3">
                    {summary ? (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: summary }} />
                      </>
                    ) : (
                      <>
                        <div className="my-5 text-center">
                          <button
                            className="btn btn-xs btn-dark"
                            onClick={generateSummary}
                          >
                            Generate summary
                          </button>
                        </div>

                        <div style={{ display: "none" }}>
                          <LexicalEditor
                            placeholder="Document content goes here..."
                            content={doc.content}
                            editorStyle={{ height: 0 }}
                            commentsEnabled={false}
                            setCommentsPanelOpen={setCommentsPanelOpen}
                            editorRef={editorRef}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const actions = {
  updateDoc: doUpdateDoc,
};

export default connect(null, actions)(DocContainer);
