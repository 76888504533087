import React, { Component, useState } from "react";
import TaskNodes from "./TaskNodes";
import ModalPopup from "../../modal/ModalPopup";
import TaskForm from "./TaskForm";
import TaskView from "./TaskView";
import Icon from "../../icons/Icon";
import { getTypeIcon } from "./Shared";
import TaskTitle from "./TaskTitle";

export default function TaskViewModal({ groupId, task, toggleEditTask, size }) {
  const [titleEdit, setTitleEdit] = useState(false);

  return (
    <ModalPopup
      show={true}
      centered={true}
      size={size ? size : "lg"}
      handleClose={toggleEditTask}
      // title={"Edit Task"}
    >
      <TaskView
        taskId={task._id}
        groupId={groupId}
        onEditTask={toggleEditTask}
      />
    </ModalPopup>
  );
}
