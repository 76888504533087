import React, { useState, forwardRef, useImperativeHandle } from "react";
import LexicalEditor from "../../common/texteditor/lexical/LexicalEditor";

const TaskCommentForm = forwardRef(function (props, ref) {
  const [comment, setComment] = useState(props.comment || "");

  useImperativeHandle(ref, function getDescription() {
    return comment;
  });

  return (
    <LexicalEditor
      placeholder="Type your comment here."
      content={comment}
      onChange={(content) => setComment(content)}
      editorStyle={{ height: 200 }}
      editorType="minimal"
    />
  );
});

export default TaskCommentForm;
