import {
  SET_GROUP,
  RESET_GROUP,
  REMOVE_CONTENT,
  LOAD_CONTENTS,
  EDIT_CONTENT,
  ADD_CONTENT,
  SHOW_MOVE_OR_COPY,
  HIDE_MOVE_OR_COPY,
  TOGGLE_TYPE_VIEW,
  TOGGLE_CALENDAR_OPEN,
  TOGGLE_ACTIVITIES,
  TOGGLE_CHAT_OPEN,
  TOGGLE_ALL_TYPES_VIEW,
  SHOW_ALL_TYPES_VIEW,
  LOAD_SUB_CONTENTS,
  ADD_SUB_CONTENT,
  SET_CONTENT_LOADED,
  REMOVE_SUB_CONTENT,
  EDIT_SUB_CONTENT,
} from "../actionTypes";

const initialState = {
  groupData: null,
  contents: {},
  moveOrCopy: { show: false, data: null },
  contentLoaded: {},
  disabledTypesInDefaultView: [],
  calendarOpen: false,
  activitiesOpen: false,
  chatOpen: true,
  chats: {}, //{"id":{meta:{}, messages:[]}}
};

function groupReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_GROUP: {
      return initialState;
    }
    case SET_GROUP: {
      const { group } = action.payload;
      return {
        ...state,
        groupData: group,
      };
    }

    case SET_CONTENT_LOADED:
      const { type } = action.payload;
      let { contentLoaded } = state;
      return {
        ...state,
        contentLoaded: { ...contentLoaded, [type]: true },
      };

    case LOAD_CONTENTS: {
      const { items = [], replace, type } = action.payload;
      let { contents, contentLoaded } = state;
      let existingItems = contents[type] || [];
      if (replace) {
        contents[type] = items;
      } else {
        // contents[type] = existingItems.concat(items);
        contents = { ...contents, [type]: existingItems.concat(items) };
      }
      contentLoaded = { ...contentLoaded, [type]: true };

      return {
        ...state,
        contents: { ...contents },
        contentLoaded: { ...contentLoaded },
      };
    }

    case LOAD_SUB_CONTENTS: {
      const { items = [], type, contentId, subtype } = action.payload;

      let { contents, contentLoaded } = state;
      let existingItems = contents[type] || [];
      if (contentId && subtype) {
        let index = existingItems.findIndex((i) => i._id === contentId);
        if (index !== -1 && subtype) {
          // existingItems[index] = { ...existingItems[index], [subtype]: items };
          existingItems = [
            ...existingItems.slice(0, index),
            { ...existingItems[index], [subtype]: items },
            ...existingItems.slice(index + 1),
          ];
          contents = { ...contents, [type]: [...existingItems] };
          return {
            ...state,
            contents: { ...contents },
            contentLoaded: { ...contentLoaded },
          };
        }
      }

      return state;
    }

    case REMOVE_CONTENT: {
      let { itemId, type } = action.payload;
      let contents = state.contents;
      let items = contents[type] || [];
      items = items.filter((item) => item._id !== itemId);
      contents = { ...contents, [type]: items };
      return {
        ...state,
        contents,
      };
    }

    case EDIT_CONTENT: {
      let { itemId, type, content } = action.payload;
      let contents = state.contents;
      let items = contents[type] || [];
      contents = { ...contents, [type]: editContent(items, itemId, content) };
      return {
        ...state,
        contents: { ...contents },
      };
    }

    case ADD_CONTENT: {
      let { item, type } = action.payload;
      let contents = state.contents;
      let items = contents[type] || [];
      contents = { ...contents, [type]: [item, ...items] };
      return {
        ...state,
        contents,
      };
    }

    case ADD_SUB_CONTENT: {
      let { item, type, contentId, subtype } = action.payload;
      let contents = state.contents;
      let items = contents[type] || [];
      if (contentId && subtype) {
        const index = items.findIndex((i) => i._id === contentId);
        if (index !== -1) {
          let subContents = items[index][subtype] || [];
          items = [
            ...items.slice(0, index),
            { ...items[index], [subtype]: [item, ...subContents] },
            ...items.slice(index + 1),
          ];
          contents = { ...contents, [type]: [...items] };
          return {
            ...state,
            contents: { ...contents },
          };
        }
      }

      return state;
    }

    case REMOVE_SUB_CONTENT: {
      let { type, contentId, subtype, subContentId } = action.payload;
      let contents = state.contents;
      let items = contents[type] || [];
      if (contentId && subtype) {
        const index = items.findIndex((i) => i._id === contentId);
        if (index !== -1) {
          let subContents = items[index][subtype] || [];
          let subIndex = subContents.findIndex((sc) => sc._id === subContentId);
          if (subIndex !== -1) {
            items = [
              ...items.slice(0, index),
              {
                ...items[index],
                [subtype]: [
                  ...subContents.slice(0, subIndex),
                  ...subContents.slice(subIndex + 1),
                ],
              },
              ...items.slice(index + 1),
            ];
            contents = { ...contents, [type]: [...items] };
            return {
              ...state,
              contents: { ...contents },
            };
          }
        }
      }

      return state;
    }

    case EDIT_SUB_CONTENT: {
      let { type, contentId, subtype, subContentId, item } = action.payload;
      let contents = state.contents;
      let items = contents[type] || [];
      if (contentId && subtype) {
        items = editSubContent(items, contentId, subtype, subContentId, item);
        contents = { ...contents, [type]: [...items] };
        return {
          ...state,
          contents: { ...contents },
        };
      }

      return state;
    }

    case SHOW_MOVE_OR_COPY: {
      let { data } = action.payload;
      return { ...state, moveOrCopy: { show: true, data } };
    }

    case HIDE_MOVE_OR_COPY: {
      return { ...state, moveOrCopy: { show: false, data: null } };
    }

    case TOGGLE_TYPE_VIEW: {
      let { type } = action.payload;
      let { disabledTypesInDefaultView } = state;
      disabledTypesInDefaultView = [...disabledTypesInDefaultView];
      let index = disabledTypesInDefaultView.indexOf(type);
      if (index !== -1) {
        disabledTypesInDefaultView.splice(index, 1);
      } else {
        disabledTypesInDefaultView.push(type);
      }
      return {
        ...state,
        disabledTypesInDefaultView: [...disabledTypesInDefaultView],
      };
    }

    case TOGGLE_ALL_TYPES_VIEW: {
      let { types } = action.payload;
      let { disabledTypesInDefaultView = [] } = state;
      types.forEach((type) => {
        if (disabledTypesInDefaultView.indexOf(type) === -1) {
          disabledTypesInDefaultView.push(type);
        }
      });

      return {
        ...state,
        disabledTypesInDefaultView: [...disabledTypesInDefaultView],
      };
    }

    case SHOW_ALL_TYPES_VIEW: {
      return {
        ...state,
        disabledTypesInDefaultView: [],
      };
    }

    case TOGGLE_CALENDAR_OPEN: {
      return { ...state, calendarOpen: !state.calendarOpen };
    }

    case TOGGLE_CHAT_OPEN: {
      return { ...state, chatOpen: !state.chatOpen };
    }

    case TOGGLE_ACTIVITIES: {
      return { ...state, activitiesOpen: !state.activitiesOpen };
    }

    default:
      return state;
  }
}

export default groupReducer;

function editContent(items, itemId, content) {
  let index = items.findIndex((i) => i._id === itemId);
  if (index === -1) {
    return items;
  }
  return [
    ...items.slice(0, index),
    { ...items[index], ...content },
    ...items.slice(index + 1),
  ];
}

function editSubContent(items, itemId, subtype, subContentId, content) {
  let index = items.findIndex((i) => i._id === itemId);
  if (index === -1) {
    return items;
  }

  const item = items[index];
  const subContents = item[subtype];
  const subIndex = subContents.findIndex((sc) => sc._id === subContentId);
  if (subIndex === -1) {
    return items;
  }
  const updatedContent = {
    ...item,
    [subtype]: [
      ...subContents.slice(0, subIndex),
      { ...subContents[subIndex], ...content },
      ...subContents.slice(subIndex + 1),
    ],
  };

  items = [...items.slice(0, index), updatedContent, ...items.slice(index + 1)];
  return items;
}
