import { useEffect } from "react";
import FeedsView from "./FeedsView";
import { fetchFeeds } from "../../state/actions/feed";
import { connect } from "react-redux";

const FeedsContainer = (props) => {
  useEffect(() => {
    props.loadFeeds();
  }, []);

  return <FeedsView {...props} />;
};

const actions = {
  loadFeeds: fetchFeeds,
};

const data = (state) => {
  let { items, loaded } = state.feeds || {};
  return {
    feeds: items,
    feedsLoaded: !!loaded,
  };
};

export default connect(data, actions)(FeedsContainer);
