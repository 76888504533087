import format from "date-fns/format";
import { formatDistanceStrict } from "date-fns";

export function preProcessTask(inTask) {
  const task = { ...inTask };
  task.assignee = task.assignee
    ? { value: task.assignee.id, label: task.assignee.name }
    : null;
  task.dueOn = task.dueOn ? format(new Date(task.dueOn), "yyyy-MM-dd") : null;
  return task;
}

export function groupTasks(tasks) {
  const group = {
    TODAY: [],
    DUE_EARLIER: [],
    THIS_WEEK: [],
    LATER: [],
  };

  tasks.forEach((task) => {
    if (!task.dueOn) {
      group.LATER.push(task);
      return;
    }

    const dueOn = midnightify(new Date(task.dueOn));
    const now = midnightify(new Date());

    const distance = calculateDistance(dueOn, now);
    let key = "LATER";
    if (distance < 0) {
      key = "DUE_EARLIER";
    } else if (distance === 0) {
      key = "TODAY";
    } else if (distance > 0 && distance <= 5) {
      let dueDayOfWeek = dueOn.getDay();
      if (dueDayOfWeek <= 5) {
        key = "THIS_WEEK"; //todo add more things to cover week
      }
    }

    const existing = group[key];
    if (existing.length === 0) {
      existing.push(task);
    } else {
      const last = existing[existing.length - 1];
      if (
        calculateDistance(
          midnightify(new Date(task.dueOn)),
          midnightify(new Date(last.dueOn)),
        ) > 0
      ) {
        existing.push(task);
      } else {
        existing[existing.length - 1] = task;
        existing.push(last);
      }
    }
  });

  return group;
}

function calculateDistance(date1, date2) {
  const diffTime = date1 - date2;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function midnightify(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}
