import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { updateGroup } from "../../services/GroupService";

class GroupName extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = { name: props.group.name, isOwner: props.isOwner };
  }

  setEditName = () => {
    if (this.state.isOwner) {
      this.setState({ edit: !this.state.edit }, () => {
        if (this.state.edit) {
          this.textInput.current.focus();
        }
      });
    }
  };

  onChange = (e) => {
    this.setState({ name: e.target.value });
  };

  submit = (e) => {
    e.preventDefault();
    this.setEditName();
    updateGroup(
      this.props.group._id,
      {
        name: this.state.name,
      },
      () => {},
    );
  };

  render() {
    let { edit = false, name } = this.state;
    let { isOwner, group } = this.props;
    if (edit) {
      return (
        <form onSubmit={this.submit}>
          <input
            className="form-control"
            ref={this.textInput}
            value={name}
            onChange={this.onChange}
            style={{ border: 0, background: "#f5f5f5" }}
          />
        </form>
      );
    }
    return (
      <React.Fragment>
        <OverlayTrigger
          key={"bottom"}
          placement={"bottom"}
          overlay={
            group.description ? (
              <Tooltip id={`tooltip-bottom`}>{group.description}</Tooltip>
            ) : (
              <span />
            )
          }
        >
          <div className="d-flex align-items-center">
            <div className="chat-area-title">{name}</div>
          </div>

          {/*<h3*/}
          {/*  style={{*/}
          {/*    fontWeight: "500",*/}
          {/*    fontSize: "18px",*/}
          {/*    marginBottom: 0,*/}
          {/*    display: "-webkit-box",*/}
          {/*    WebkitBoxOrient: "vertical",*/}
          {/*    WebkitLineClamp: 1,*/}
          {/*    overflow: "hidden",*/}
          {/*    textOerflow: "ellipsis",*/}
          {/*    maxWidth: "15ch",*/}
          {/*    color:"#fff"*/}
          {/*  }}*/}
          {/*  // onClick={() => this.setEditName(isOwner)}*/}
          {/*>*/}
          {/*  {name}*/}
          {/*</h3>*/}
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

export default GroupName;
