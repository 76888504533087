import ChatsView from "./ChatsView";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchChatGroups,
  fetchMessages,
  selectChatGroup,
} from "../../../state/actions/chat";

function ChatContainer(props) {
  useEffect(() => {
    props.fetchGroups();
  }, []);

  return <ChatsView {...props} />;
}

const actions = {
  fetchGroups: fetchChatGroups,
  selectChatGroup,
  loadMessages: fetchMessages,
};

const data = (state) => {
  let chat = state.chat || {};
  let { groups, selectedGroup, messages, messagesLoaded } = chat;
  return {
    groups,
    selectedGroup,
    messages: selectedGroup ? messages[selectedGroup._id] : [],
    messagesLoaded: selectedGroup ? messagesLoaded[selectedGroup._id] : [],
  };
};

export default connect(data, actions)(ChatContainer);
