import React from "react";
import { formatDistanceToNowStrict } from "date-fns";

const DateTime = ({ value }) => {
  let distance = formatDistanceToNowStrict(value);
  if (distance === "0 seconds") {
    distance = "moments";
  }
  return <span className="text-muted small">{distance + " ago"}</span>;
};

export default DateTime;
