import React from "react";
import { getNameInitials } from "../../../utils/Utils";
import Avatar from "../../widgets/Avatar";
import DateTime from "../../widgets/DateTime";
import Icon from "../../icons/Icon";
import DeleteIcon from "../../icons/DeleteIcon";
import { getUser } from "../../../services/DataService";

function PostTitle({ post, startEdit, startDelete }) {
  const currentUser = getUser();

  return (
    <div className="dfjbac">
      <div className="dfac">
        <Avatar className="mr-2" text={getNameInitials(post.createdBy.name)} />
        <div style={{ fontSize: "16px", fontWeight: "500" }}>
          {post.createdBy.name}
        </div>
      </div>
      <div className="d-flex">
        <div className="text-muted small">
          <DateTime value={new Date(post.createdOn)} />
        </div>
        {currentUser._id === post.createdBy.id && (
          <div className="ml-2">
            <a onClick={startEdit} className="mx-1">
              <Icon name="edit_note" />
            </a>

            <a onClick={startDelete}>
              <span className="text-danger">
                <DeleteIcon className="mr-h" />
              </span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default PostTitle;
