import React, { useState } from "react";
import LexicalEditor from "../../common/texteditor/lexical/LexicalEditor";

export default function QuestionEditor({
  newContent,
  question = {},
  submit,
  cancel,
  noCancel,
}) {
  const [content, setContent] = useState(question ? question.content : "");
  const [parsedText, setParsedText] = useState("");

  const onChangeContent = (content, parsed) => {
    setContent(content);
    setParsedText(parsed);
  };

  const submitQuestion = () => {
    submit({ ...question, content, parsedText });
  };

  return (
    <div>
      <LexicalEditor
        placeholder="Type a question ..."
        content={content}
        onChange={onChangeContent}
        editorStyle={{ height: 200 }}
        editorType="minimal"
      />

      <div className="dfjbac m-t-md">
        {noCancel ? (
          <div></div>
        ) : (
          <button
            style={{ width: 100, height: 39 }}
            className="btn btn-default btn-sm mb-0 mr-1"
            onClick={(e) => {
              e.preventDefault();
              cancel();
            }}
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="btn btn-dark mb-0"
          onClick={submitQuestion}
        >
          {newContent ? "Create" : "Update"}
        </button>
      </div>
    </div>
  );
}
