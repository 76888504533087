import Ajax from "./Ajax";

export function createOrg(data) {
  return Ajax.post("/api/v1/orgs", data).then(function (json) {
    return json.data;
  });
}

export function getOrgs() {
  return Ajax.get(`/api/v1/orgs`).then(function (json) {
    return json.data;
  });
}

export async function getOrg(id) {
  return Ajax.get("/api/v1/orgs/" + id).then(function (json) {
    return json.data;
  });
}

export function updateOrg(groupId, data) {
  Ajax.patch("/api/v1/orgs/" + groupId, data).then(function (json) {
    return json.data;
  });
}

export function updateLastUsedOrg(orgId) {
  Ajax.post("/api/v1/users/orgs/" + orgId, {}).then(function (json) {
    return json.data;
  });
}
