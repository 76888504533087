import Ajax from "./Ajax";

export function uploadFiles(groupId, files) {
  let data = new FormData();
  files.forEach(function (f) {
    data.append("group-files", f);
  });

  let api = "/api/v1/files?groupId=" + groupId;
  return Ajax.uploadFiles(api, data);
}

export function getFiles(groupId) {
  let api = "/api/v1/files?groupId=" + groupId;

  return Ajax.get(api).then(function (json) {
    return json.data;
  });
}

export function getFile(groupId, fileId, cb) {
  return Ajax.downloadFile(
    "/api/v1/files/" + fileId + "?groupId=" + groupId,
  ).then((data) => {
    if (!data || data.size === 0) {
      throw new Error();
    }
    return URL.createObjectURL(data);
  });
}

export function updateFile(groupId, fileId, data) {
  return Ajax.patch("/api/v1/files/" + fileId + "?groupId=" + groupId, data);
}

export function deleteFile(groupId, fileId) {
  return Ajax.delete("/api/v1/files/" + fileId + "?groupId=" + groupId);
}
