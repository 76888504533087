import React from "react";
import Dropzone from "react-dropzone";
import { uploadFiles } from "../../../services/FileService";
import { BarLoader } from "react-spinners";
import DeleteIcon from "../../icons/DeleteIcon";
import { toastMessage } from "../../../state/actions/toaster";
import { compose } from "redux";
import { connect } from "react-redux";

class FilesUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { files: [], loading: false };
  }

  uploadFiles = () => {
    let files = this.state.files;
    this.setState({ loading: true });
    let { listId, group } = this.props;
    uploadFiles(this.props.listId, group ? group._id : null, files)
      .then((res) => {
        this.props.toastMessage(
          "Files have been uploaded successfully!",
          "success",
          3,
        );
        this.props.onFilesUpload(res);
        this.setState({ files: [], loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  };

  removeFile = (index) => {
    let files = this.state.files;
    files.splice(index, 1);
    this.setState({ files: files });
  };

  onDrop = (files) => {
    this.setState({
      files,
    });
  };

  render() {
    let { loading } = this.state;
    return (
      <div>
        <Dropzone
          style={{
            cursor: "pointer",
            width: "100%",
            height: "150px",
            border: "1px dashed #3e2d42",
            display: "flex",
            alignItems: "center",
          }}
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .mp3"
          onDrop={this.onDrop}
        >
          <p style={{ margin: "45px 20px" }}>
            Drop some files here, or click to select files to upload.
          </p>
        </Dropzone>

        <aside>
          <ul className="list-group clear-list m-t">
            {this.state.files.map((f, index) => (
              <li className="list-group-item" key={f.name}>
                {f.name}
                <a
                  style={{ marginLeft: "15px" }}
                  onClick={() => {
                    this.removeFile(index);
                  }}
                >
                  <DeleteIcon className="mr-h" />
                </a>
              </li>
            ))}
          </ul>
        </aside>

        <BarLoader
          color={"#2d85c6"}
          loading={loading}
          css={"display: block; margin: 0 auto; border-color: red;"}
          size={150}
        />

        {this.state.files.length > 0 && !loading && (
          <div>
            <a
              className="btn btn-primary"
              onClick={this.uploadFiles}
              style={{ width: "100%" }}
            >
              Upload
            </a>
          </div>
        )}
      </div>
    );
  }
}

function actions(dispatch) {
  return {
    toastMessage: toastMessage,
  };
}

export default compose(connect(null, actions))(FilesUploader);
