import { CLEAR_TOAST, NEW_TOAST } from "../actionTypes";

export function toastMessage(message, type, duration) {
  return {
    type: NEW_TOAST,
    payload: { message: message, type: type, duration: duration },
  };
}

export function clearMessage() {
  return {
    type: CLEAR_TOAST,
  };
}
