import "./editor.css";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import * as React from "react";
import {
  SharedHistoryContext,
  useSharedHistoryContext,
} from "./context/SharedHistoryContext";
import EditorNodes from "./nodes/EditorNodes";
import { TableContext } from "./plugins/TablePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import MentionsPlugin from "./plugins/MentionsPlugin";
import EmojisPlugin from "./plugins/EmojisPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import ContentEditable from "./ui/ContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import TabFocusPlugin from "./plugins/TabFocusPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import CodeActionMenuPlugin from "./plugins/CodeActionMenuPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import EnterKeyPlugin from "./plugins/EnterKeyPlugin";
import MentionClickPlugin from "./plugins/MentionClickPlugin";
import { useEffect, useState } from "react";
import { CAN_USE_DOM } from "./shared/canUseDOM";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import SetEditorPlugin from "./plugins/SetEditorPlugin";

export default function LexicalEditorBubble({
  placeholder = "Enter text here...",
  content,
  onSubmit,
  editable = true,
  editorContainerStyle,
  onChange: propsOnChange,
  setEditor,
}) {
  const initialConfig = {
    editorState: content || undefined,
    namespace: "lexical-editor",
    nodes: [...EditorNodes],
    onError: (error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
    editable,
  };

  const { historyState } = useSharedHistoryContext();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;
      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);
    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  function onChange(editorState) {
    // Call toJSON on the EditorState object, which produces a serialization safe string
    const editorStateJSON = editorState.toJSON();
    // However, we still have a JavaScript object, so we need to convert it to an actual string with JSON.stringify
    if (propsOnChange) {
      propsOnChange(JSON.stringify(editorStateJSON));
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <div
            className="editor-shell-bubble"
            style={editorContainerStyle || {}}
          >
            <DragDropPaste />
            <AutoFocusPlugin />
            <ClearEditorPlugin />
            {/*<ComponentPickerPlugin />*/}
            <EmojiPickerPlugin />
            <MentionsPlugin />
            <EmojisPlugin />
            <AutoLinkPlugin />
            <HistoryPlugin externalHistoryState={historyState} />
            <RichTextPlugin
              contentEditable={
                <div className="editor-scroller-bubble">
                  <div className="editor-bubble" ref={onRef}>
                    <ContentEditable placeholder={placeholder} />
                  </div>
                </div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <CodeHighlightPlugin />
            <ListPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <LinkPlugin />
            <TabFocusPlugin />
            <TabIndentationPlugin />
            {floatingAnchorElem && !isSmallWidthViewport && (
              <>
                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingLinkEditorPlugin
                  anchorElem={floatingAnchorElem}
                  isLinkEditMode={isLinkEditMode}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                  setIsLinkEditMode={setIsLinkEditMode}
                  isCommentsEnabled={false}
                />
              </>
            )}
            <EnterKeyPlugin onSubmit={onSubmit} />
            <SetEditorPlugin setEditor={setEditor} />
            <OnChangePlugin onChange={onChange} />
            {floatingAnchorElem && (
              <MentionClickPlugin anchorElem={floatingAnchorElem} />
            )}
          </div>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}
