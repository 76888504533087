import { SET_UI_PAGE } from "../actionTypes";

export const navigateToPage = (page) => async (dispatch) => {
  dispatch({
    type: SET_UI_PAGE,
    payload: {
      page,
    },
  });
};
