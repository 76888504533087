import React, { useState } from "react";
import ModalPopup from "../../modal/ModalPopup";
import PostEditor from "./PostEditor";
import PostView from "./PostView";

function PostsView({ groupId, posts = [], addPost, updatePost, doDeletePost }) {
  const [showAddPost, setShowAddPost] = useState(false);
  const [postToEdit, setPostToEdit] = useState(null);

  const toggleShowAddPost = () => {
    setShowAddPost(!showAddPost);
  };

  const cancelEdit = () => {
    setPostToEdit(null);
  };

  const add = (data) => {
    addPost(groupId, data);
    setShowAddPost(false);
  };

  const update = (data) => {
    updatePost(groupId, postToEdit._id, data);
    setPostToEdit(null);
  };

  const deletePost = (post) => {
    doDeletePost(groupId, post._id);
  };

  return (
    <div className="col-md-8 offset-md-2">
      <div className={`float-e-margins`}>
        <div className="d-flex justify-content-between align-items-center group-header mb-3 ">
          <h3>Posts</h3>

          <div>
            <a
              style={{ width: 100 }}
              className={`btn ${
                showAddPost ? "btn-default" : "btn-dark"
              } btn-sm mb-0`}
              onClick={(e) => {
                e.preventDefault();
                toggleShowAddPost();
              }}
            >
              {showAddPost ? "Cancel" : "Create Post"}
            </a>
          </div>
        </div>

        <div className="px-3">
          {posts.length === 0 && (
            <div className="text-center">There are no posts at the moment.</div>
          )}

          {posts.map((post) => {
            return (
              <div
                key={post._id}
                className="mb-3"
                style={{
                  padding: "15px 20px 20px 20px",
                  borderRadius: 7,
                  border: "1px solid #0000001d",
                  backgroundColor: "#fff",
                }}
              >
                <PostView
                  key={post._id}
                  groupId={groupId}
                  post={post}
                  startEdit={() => setPostToEdit(post)}
                  startDelete={() => deletePost(post)}
                />
              </div>
            );
          })}
        </div>

        {showAddPost && (
          <ModalPopup
            title="New Post"
            handleClose={toggleShowAddPost}
            fullscreen
          >
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-12 col-md-8 col-md-offset-2">
                <PostEditor groupId={groupId} submit={add} newContent />
              </div>
            </div>
          </ModalPopup>
        )}

        {postToEdit && (
          <ModalPopup title="Edit Post" handleClose={cancelEdit} fullscreen>
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-12 col-md-8 col-md-offset-2">
                <PostEditor
                  groupId={groupId}
                  post={postToEdit}
                  submit={update}
                />
              </div>
            </div>
          </ModalPopup>
        )}
      </div>
    </div>
  );
}

export default PostsView;
