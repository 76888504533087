import React, { useState } from "react";
import LexicalEditor from "../../common/texteditor/lexical/LexicalEditor";

function DocEditor({
  groupId,
  newContent,
  style,
  doc = {},
  toggleDocs,
  submit,
  setCommentsPanelOpen,
  editorHeight,
  btnLabel,
  noCancel,
}) {
  const [title, setTitle] = useState(doc.title);
  const [content, setContent] = useState(doc.content);
  const [parsedText, setParsedText] = useState();

  const onChangeContent = (content, text) => {
    setContent(content);
    setParsedText(text);
  };

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const submitDoc = () => {
    let data = { title, content };
    data.groupId = groupId;
    data.parsedText = parsedText;
    submit(data);
  };

  return (
    <div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          value={title}
          onChange={onChangeTitle}
          style={{ height: "40px" }}
          placeholder={newContent ? "New Document Title" : "Title"}
        />
      </div>
      <div style={style ? style : {}}>
        <LexicalEditor
          placeholder="Document content goes here..."
          content={content}
          onChange={onChangeContent}
          editorStyle={{ height: editorHeight || "calc(100vh - 280px)" }}
          commentsEnabled={false}
          setCommentsPanelOpen={setCommentsPanelOpen}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between m-t-md">
        {noCancel ? (
          <div></div>
        ) : (
          <button
            style={{ width: 100, height: 39 }}
            className="btn btn-default btn-sm mb-0 mr-1"
            onClick={(e) => {
              e.preventDefault();
              toggleDocs();
            }}
          >
            Cancel
          </button>
        )}
        <button type="submit" className="btn btn-dark mb-0" onClick={submitDoc}>
          {btnLabel ? btnLabel : newContent ? "Create" : "Update"}
        </button>
      </div>
    </div>
  );
}

export default DocEditor;
