import React from "react";
import "./Toaster.css";
import { connect } from "react-redux";
import { clearMessage } from "../../state/actions/toaster";
import Icon from "../icons/Icon";

class Toaster extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.message) {
      let duration = nextProps.duration || 5;
      setTimeout(() => {
        nextProps.clearMessage();
      }, duration * 1000);
    }
  }

  close = () => {
    this.props.clearMessage();
  };

  render() {
    let { type, message } = this.props;
    if (!message || message.length === 0) {
      return null;
    }
    let toasterClass = "";
    if (type === "success") {
      toasterClass += "alert-success";
    } else if (type === "info") {
      toasterClass += "alert-info";
    } else if (type === "warning") {
      toasterClass += "alert-warning";
    } else if (type === "error") {
      toasterClass += "alert-danger";
    }

    return (
      <div className="toaster" style={{ zIndex: 9999 }}>
        <p className={"d-flex alert " + toasterClass}>
          <a onClick={this.close} className={"toaster-close " + toasterClass}>
            <Icon
              className="mr-h font-bold"
              style={{ fontSize: 18 }}
              name="close"
            />
          </a>
          {message}
        </p>
      </div>
    );
  }
}
const data = (state) => {
  return {
    message: state.toaster.message,
    type: state.toaster.type,
    duration: state.toaster.duration,
  };
};
const mapDispatchToProps = {
  clearMessage: clearMessage,
};

export default connect(data, mapDispatchToProps)(Toaster);
