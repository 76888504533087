import React, { useEffect } from "react";
import DocsView from "./DocsView";
import { connect } from "react-redux";
import { fetchDocs } from "../../../state/actions/docs";
import { useParams } from "react-router-dom";

function DocsContainer(props) {
  const { groupId } = useParams();
  let docsLoaded = false;

  useEffect(() => {
    if (props.contentLoaded || docsLoaded) {
      return;
    }
    docsLoaded = true;
    props.loadDocs(groupId);
  }, [groupId]);

  return <DocsView {...props} groupId={groupId} />;
}

const actions = {
  loadDocs: fetchDocs,
};

const data = (state) => {
  let contents = state.group.contents || {};
  const type = "doc";

  return {
    docs: contents[type] || [],
    contentLoaded: !!state.group.contentLoaded[type],
  };
};

export default connect(data, actions)(DocsContainer);
