import { getTodaysItems } from "../../services/ContentService";
import FeedContent from "./FeedContent";
import { useEffect, useState } from "react";
import Accomplishments from "./Accomplishments";
import ModalPopup from "../modal/ModalPopup";

const Today = () => {
  const [items, setItems] = useState([]);
  const [showAcc, setShowAcc] = useState(false);

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = async () => {
    const items = await getTodaysItems();
    setItems(items);
  };

  return (
    <div className="row">
      <div className="my-3">
        <h3 className="text-center">Things to focus on the day</h3>
        {/*<div>*/}
        {/*  <button*/}
        {/*    className="btn btn-dark btn-sm"*/}
        {/*    onClick={() => setShowAcc(true)}*/}
        {/*  >*/}
        {/*    View Yesterday's Accomplishments*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
      <div className="col-md-12 col-sm-12">
        {items.map((item) => (
          <div className="white-box mb-3">
            <h4 className="text-capitalize">{item.type}</h4>
            <FeedContent feed={item} />
          </div>
        ))}
      </div>
      {showAcc && (
        <ModalPopup
          show={true}
          handleClose={() => setShowAcc(false)}
          title="Yesterday's Accomplishments"
          size="lg"
        >
          <Accomplishments />
        </ModalPopup>
      )}
    </div>
  );
};

export default Today;
