import React from "react";
import { Calendar as Cal, Views, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss"; // if using DnD

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentView: Views.DAY };
  }

  onView = (view) => {
    this.setState({ currentView: view });
  };

  onSelectSlot = (slot) => {
    if (this.state.currentView === Views.MONTH) {
      this.setState({ currentView: Views.DAY });
    } else {
      this.props.onSelectSlot(slot);
    }
  };

  render() {
    const { events = [] } = this.props;
    let scrollToTime = new Date();
    scrollToTime.setHours(8, 0, 0);

    return (
      <Cal
        selectable
        localizer={localizer}
        events={events}
        view={this.state.currentView}
        onSelectEvent={this.props.onSelectEvent}
        onSelectSlot={this.onSelectSlot}
        scrollToTime={scrollToTime}
        onView={this.onView}
      />
    );
  }
}

export default Calendar;
