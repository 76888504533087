import React, { useState, useRef, useEffect } from "react";
import ImagePreview from "./ImagePreview";
import { getFile } from "../../services/FileService";
import ModalPopup from "../modal/ModalPopup";
import FileViewerInline from "../content/files/FileViewerInline";

export default function FilePreview({ groupId, file }) {
  const [fileURL, setFileURL] = useState(null);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    previewFile();
  }, []);

  function previewFile() {
    getFile(groupId, file)
      .then((fileURL) => {
        setFileURL(fileURL);
      })
      .catch((e) => {
        // this.setState({ loading: false });
      });
  }

  const cancel = () => setShowImage(false);

  if (!fileURL) {
    return null;
  }

  return (
    <>
      <ImagePreview src={fileURL} onClick={() => setShowImage(true)} />

      {showImage && (
        <ModalPopup
          show={true}
          // fullscreen={true}
          size="xl"
          handleClose={cancel}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              overflowY: "auto",
            }}
          >
            <img src={fileURL} />
          </div>

          {/*<FileViewerInline*/}
          {/*  file={file}*/}
          {/*  fileURL={fileURL}*/}
          {/*  groupId={groupId}*/}
          {/*  close={cancel}*/}
          {/*/>*/}
        </ModalPopup>
      )}
    </>
  );
}
