import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { $createMentionNode } from "../../nodes/MentionNode";
import { getGroupMembers } from "../../../../../../services/GroupService";
import { searchContent } from "../../../../../../services/ContentService";
import { useParams } from "react-router-dom";
import { getIcon } from "../../../../../groups/GroupIcons";
const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";
const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};
const PUNC = DocumentMentionsRegex.PUNCTUATION;
const TRIGGERS = ["@"].join("");
// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";
// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";
const LENGTH_LIMIT = 75;
const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    VALID_JOINS +
    "){0," +
    LENGTH_LIMIT +
    "})" +
    ")$",
);
// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;
// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    "){0," +
    ALIAS_LENGTH_LIMIT +
    "})" +
    ")$",
);
// At most, 5 suggestions are shown in the popup.
const mentionsCache = new Map();

function useMentionLookupService(mentionString) {
  const { groupId } = useParams();
  const [results, setResults] = useState([]);
  useEffect(() => {
    const cachedResults = mentionsCache.get(mentionString);
    if (!mentionString) {
      setResults([]);
      return;
    }
    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }
    mentionsCache.set(mentionString, null);
    fetchResults(mentionString);
  }, [mentionString]);

  const fetchResults = async (mentionString) => {
    const members = await getGroupMembers(groupId);
    const filteredMembers = members.filter((member) =>
      member.name.toLowerCase().includes(mentionString.toLowerCase()),
    );

    const content = await searchContent(groupId, mentionString);
    let results = [];
    filteredMembers.forEach((member) =>
      results.push({ ...member, type: "person" }),
    );
    // Object.keys(content).forEach((type) => {
    //   content[type].forEach((con) => results.push({ ...con, type }));
    // });
    results = [...results, ...content];

    mentionsCache.set(mentionString, results);
    setResults(results);
  };
  return results;
}

function checkForAtSignMentions(text, minMatchLength) {
  let match = AtSignMentionsRegex.exec(text);
  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text) {
  return checkForAtSignMentions(text, 2);
}

class MentionTypeaheadOption extends MenuOption {
  constructor(id, name, type, picture) {
    super(id);
    this.id = id;
    this.name = name;
    this.type = type;
    this.picture = picture;
  }
}
function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}) {
  let className = "item";
  if (isSelected) {
    className += " selected";
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + option.id}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {option.picture}
      <span className="text">{option.name}</span>
    </li>
  );
}

export default function NewMentionsPlugin() {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState(null);
  const results = useMentionLookupService(queryString);
  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0,
  });

  const options = useMemo(() => {
    const options = results.map(
      (result) =>
        new MentionTypeaheadOption(
          result.contentId || result._id,
          result.name || result.title,
          result.type,
          <span style={{ marginRight: 8 }}>{getIcon(result.type, 20)}</span>,
        ),
    );
    return options;
  }, [results]);

  const onSelectOption = useCallback(
    (selectedOption, nodeToReplace, closeMenu) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.name,
          selectedOption.id,
          selectedOption.type,
        );
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor],
  );

  const checkForMentionMatch = useCallback(
    (text) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor],
  );

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
      ) =>
        anchorElementRef.current && options.length
          ? ReactDOM.createPortal(
              <div className="typeahead-popover mentions-menu">
                <ul>
                  {options.map((option, i) => (
                    <MentionsTypeaheadMenuItem
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      key={option.key}
                      option={option}
                    />
                  ))}
                </ul>
              </div>,
              anchorElementRef.current,
            )
          : null
      }
    />
  );
}

// <div className="typeahead-popover mentions-menu">
//   {Object.keys(options).map((key) => {
//     return (
//       <>
//         <h4>{key}</h4>
//         <ul>
//           {options[key].map((option, i) => (
//             <MentionsTypeaheadMenuItem
//               index={i}
//               isSelected={selectedIndex === i}
//               onClick={() => {
//                 setHighlightedIndex(i);
//                 selectOptionAndCleanUp(option);
//               }}
//               onMouseEnter={() => {
//                 setHighlightedIndex(i);
//               }}
//               key={option.key}
//               option={option}
//             />
//           ))}
//         </ul>
//       </>
//     );
//   })}
// </div>,
//
// <div className="typeahead-popover mentions-menu">
//   <h4>People</h4>
//   <ul>
//     {allOptions.map((option, i) => (
//       <MentionsTypeaheadMenuItem
//         index={i}
//         isSelected={selectedIndex === i}
//         onClick={() => {
//           setHighlightedIndex(i);
//           selectOptionAndCleanUp(option);
//         }}
//         onMouseEnter={() => {
//           setHighlightedIndex(i);
//         }}
//         key={option.key}
//         option={option}
//       />
//     ))}
//   </ul>
// </div>
