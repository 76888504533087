import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  doAddTask,
  doCompleteTask,
  fetchTasks,
  moveTaskToTop,
} from "../../../state/actions/task";
import TasksView from "./TasksView";
import { useParams } from "react-router-dom";

function TasksContainer(props) {
  const { groupId } = useParams();
  let tasksLoaded = false;

  useEffect(() => {
    if (props.contentLoaded || tasksLoaded) {
      return;
    }
    tasksLoaded = true;
    loadTasks();
  }, []);

  const completeTask = (taskId) => {
    let { completeTask } = props;
    completeTask(groupId, taskId);
  };

  const loadTasks = () => {
    let { fetchTasks } = props;
    fetchTasks(groupId);
  };

  const moveToTop = (index) => {
    let { moveToTop } = props;
    moveToTop(groupId, index);
  };

  return (
    <TasksView
      {...props}
      groupId={groupId}
      completeTask={completeTask}
      moveToTop={moveToTop}
    />
  );
}

const actions = {
  fetchTasks,
  completeTask: doCompleteTask,
  moveToTop: moveTaskToTop,
  addTask: doAddTask,
};

const data = (state, props) => {
  let contents = state.group.contents || {};
  let { group } = props;
  const type = "task";
  return {
    tasks: contents[type] || [],
    contentLoaded: !!state.group.contentLoaded[type],
  };
};

export default connect(data, actions)(TasksContainer);
