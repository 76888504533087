import { CLEAR_TOAST, NEW_TOAST } from "../actionTypes";

const initialState = { message: null, type: null, duration: 3 };

function toaster(state = initialState, action = null) {
  if (action.type === NEW_TOAST) {
    return {
      message: action.payload.message,
      type: action.payload.type,
      duration: action.payload.duration,
    };
  }

  if (action.type === CLEAR_TOAST) {
    return initialState;
  }

  return state;
}
export default toaster;
