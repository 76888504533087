import React, { useEffect, useState } from "react";
import { getGroups } from "../../services/GroupService";
import GroupsView from "./GroupsView";

const GroupsContainer = () => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    const groups = await getGroups("REGULAR");
    setGroups(groups);
  };

  return <GroupsView groups={groups} refreshGroups={loadGroups} />;
};

export default GroupsContainer;
