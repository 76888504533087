import React from "react";
import { getUser } from "../../services/DataService";

export default function OrganizationsView({ orgs, openOrg, startManage }) {
  let user = getUser();

  return (
    <div>
      <div className="d-flex align-content-center justify-content-center mt-5">
        You are part of following organizations. Please select one to proceed.
      </div>
      <div className="d-flex align-content-center justify-content-center mt-5">
        {orgs.map((org) => (
          <div
            className="white-box mr-4 d-flex flex-column justify-content-center align-items-center"
            style={{ height: 100, width: 250 }}
          >
            <div
              className="text-center flex-fill"
              style={{ wordBreak: "break-word" }}
            >
              {org.name}
            </div>
            <div className="d-flex text-center">
              <a className="link" onClick={() => openOrg(org._id)}>
                Open
              </a>
              {org.admins.indexOf(user._id) !== -1 && (
                <>
                  <span className="mx-2">|</span>
                  <a className="link" onClick={() => startManage(org._id)}>
                    Manage
                  </a>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
