import React, { Component } from "react";
import "./App.css";
import { Outlet, RouterProvider } from "react-router-dom";

import store from "./state/store";
import { Provider as ReduxProvider } from "react-redux";
import Toaster from "./components/alerts/Toaster";
import router from "./routes";

export default class App extends Component {
  render() {
    return (
      <ReduxProvider store={store}>
        <Toaster />
        <RouterProvider router={router}>
          <div id="wrapper">
            <Outlet />
          </div>
        </RouterProvider>
      </ReduxProvider>
    );
  }
}
