import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect, useRef, useState } from "react";
import { $getNearestNodeFromDOMNode, CLICK_COMMAND } from "lexical";
import { createPortal } from "react-dom";
import { setFloatingElemPositionForMention } from "./setFloatingElemPositionForMention";
import FeedContentContainer from "../../../../../content/docs/FeedContentContainer";
import { getIcon } from "../../../../../groups/GroupIcons";
import ModalPopup from "../../../../../modal/ModalPopup";

const MentionClickPlugin = ({ anchorElem }) => {
  const [editor] = useLexicalComposerContext();
  const editorRef = useRef(null);
  const [mentionNode, setMentionNode] = useState(null);

  useEffect(() => {
    return editor.registerCommand(
      CLICK_COMMAND,
      (event) => {
        const target = event.target;
        if (
          target instanceof HTMLElement &&
          target.classList.contains("mention")
        ) {
          editor.update(() => {
            const mentionNode = $getNearestNodeFromDOMNode(target);
            if (mentionNode && mentionNode.getType() === "mention") {
              setMentionNode(mentionNode);
              const editorElem = editorRef.current;
              const domRect = target.getBoundingClientRect();
              if (domRect) {
                domRect.y += 35;
                setFloatingElemPositionForMention(
                  domRect,
                  editorElem,
                  anchorElem,
                );
              }
            }
          });

          return true;
        }

        setMentionNode(null);
        return false;
      },
      0,
    );
  }, [editor, anchorElem]);

  return createPortal(
    <div ref={editorRef}>
      {mentionNode ? (
        <ModalPopup
          handleClose={() => setMentionNode(null)}
          size={mentionNode.__mentionType === "doc" ? "xl" : "md"}
        >
          <div className="dfac">
            <div className="mr-2">{getIcon(mentionNode.__mentionType, 20)}</div>
            <h4>{mentionNode.__text}</h4>
          </div>
          <div style={{ width: "100%" }}>
            <FeedContentContainer
              type={mentionNode.__mentionType}
              contentId={mentionNode.__valueId}
            />
          </div>
        </ModalPopup>
      ) : null}
    </div>,
    anchorElem,
  );
};

export default MentionClickPlugin;
