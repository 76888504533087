import React from "react";
import { Link } from "react-router-dom";
import { confirmEmail } from "../../services/DataService";
import ModalPopup from "../modal/ModalPopup";
import ResendEmailConfirmation from "./ResendEmailConfirmation";

class EmailConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  componentDidMount() {
    confirmEmail(this.props.match.params.key, (response) => {
      if (response.success) {
        this.setState({ signedUp: "success" });
      } else {
        this.setState({ signedUp: "failed" });
      }
    });
  }

  showResendEmailConfPopup = () => {
    this.setState({ resendEmailConfPopup: true });
  };

  cancelResendEmailConfPopup = () => {
    this.setState({ resendEmailConfPopup: false });
  };

  toggleModal = () => {
    let { show } = this.state;
    this.setState({ show: !show });
  };

  render() {
    let { show } = this.state;
    if (this.state.signedUp === "success") {
      return (
        <section className="slice ">
          <div className="wp-section text-white">
            <div className="container">
              <div className="row">
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <h3>Email Confirmed!</h3>

                  <div style={{ marginTop: "20px" }}>
                    Thanks for confirming your email. Please{" "}
                    <Link
                      to="/signin"
                      style={{ fontWeight: "bold" }}
                      className="btn-link"
                    >
                      login
                    </Link>{" "}
                    to continue.
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.signedUp === "failed") {
      return (
        <section className="slice ">
          <div className="wp-section text-white">
            <div className="container">
              <div className="row">
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <h3>Email Confirmation failed!</h3>

                  <div style={{ marginTop: "20px" }}>
                    Looks like you have followed an incorrect link. Please check
                    your email for recent emails.
                    <br />
                    Click{" "}
                    <span onClick={() => this.toggleModal()} className="link">
                      {" "}
                      here{" "}
                    </span>
                    to resend email confirmation again if required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {show && (
            <ModalPopup show={show} handleClose={this.toggleModal}>
              <ResendEmailConfirmation email={this.props.email} />
            </ModalPopup>
          )}
        </section>
      );
    } else {
      return null;
    }
  }
}

export default EmailConfirmation;
