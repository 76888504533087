import React, { useState } from "react";
import AnswerEditor from "./AnswerEditor";
import AnswerView from "./AnswerView";

function AnswersView({
  groupId,
  questionId,
  answers = [],
  deleteAnswer,
  updateAnswer,
}) {
  const [editAnswerId, setEditAnswerId] = useState(null);

  const cancelEdit = () => {
    setEditAnswerId(null);
  };

  const update = (answer) => {
    updateAnswer(groupId, questionId, answer._id, answer);
    setEditAnswerId(null);
  };
  // } else {
  //   add(groupId, questionId, { content: content });

  return answers.map((answer) => {
    return (
      <div key={answer._id}>
        {editAnswerId && editAnswerId === answer._id ? (
          <div>
            <AnswerEditor
              answer={answer}
              submit={update}
              cancel={cancelEdit}
              edit
            />
            <hr />
          </div>
        ) : (
          <div className="mb-2">
            <AnswerView
              answer={answer}
              startEdit={() => setEditAnswerId(answer._id)}
              startDelete={() => deleteAnswer(groupId, questionId, answer._id)}
            />
            <hr style={{ margin: "0 0 1rem 0" }} />
          </div>
        )}
      </div>
    );
  });
}

export default AnswersView;
