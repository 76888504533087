import React, { Component } from "react";
import { getUserId } from "../../../services/DataService";
import Icon from "../../icons/Icon";
import RichTextView from "../../common/texteditor/RichTextView";

export default class TaskRow extends Component {
  constructor(props) {
    super(props);
    this.state = { edit: false, userId: getUserId() };
  }

  getStatusBadgeColor = (status) => {
    if (status === "Open") {
      return "badge-info";
    }

    if (status === "In-Progress") {
      return "badge-primary";
    }

    if (status === "Completed") {
      return "badge-success";
    }
  };

  getPriorityBadgeColor = (status) => {
    if (status === "Critical") {
      return "badge-danger";
    }

    if (status === "High") {
      return "badge-warning";
    }

    if (status === "Medium") {
      return "badge-inverse";
    }

    if (status === "Low") {
      return "badge-info";
    }
  };

  render() {
    let { task, readOnly, showDescription } = this.props;

    return (
      <div
        className="white-box mb-3"
        onClick={this.props.startEdit}
        style={{
          minHeight: 22,
          cursor: !readOnly ? "pointer" : "",
          padding: "7px 10px",
        }}
      >
        <div className="d-flex justify-content-between">
          <div style={{ width: "100%" }}>
            <div>
              <div className="dfjbac">
                <div className="d-flex align-items-center mb-2">
                  <div
                    style={{
                      wordBreak: "break-all",
                      fontWeight: 500,
                    }}
                    className="mb-0"
                  >
                    {task.title}
                  </div>
                </div>

                <div className="dfjbac">
                  {/*{getTypeIcon(task.type)}*/}

                  <span
                    className={`ml-2 badge rounded-pill ${this.getStatusBadgeColor(task.status)}`}
                  >
                    {task.status}
                  </span>
                  {/*<span*/}
                  {/*  className={`ml-2 badge ${this.getPriorityBadgeColor(task.priority)}`}*/}
                  {/*>*/}
                  {/*  {task.priority.charAt(0)}*/}
                  {/*</span>*/}
                </div>
              </div>

              {showDescription && (
                <div className="mb-2">
                  <RichTextView content={task.description} />
                </div>
              )}

              <div className="dfjbac">
                <div className="dfac">
                  <span className="text-muted">
                    <Icon
                      name="person"
                      fontSize={22}
                      className="text-primary"
                      tooltip="Assignee"
                    />
                  </span>
                  <span className="ml-1">
                    {task.assignee ? task.assignee.name : ""}
                  </span>
                </div>
                <div className="dfac ml-2">
                  <Icon
                    name="calendar_month"
                    fontSize={22}
                    className="text-warning"
                    tooltip="Due On"
                  />
                  <div className="ml-1">
                    {task.dueOn
                      ? new Date(task.dueOn).toLocaleDateString()
                      : ""}
                  </div>
                </div>

                {/*<div>*/}
                {/*  {task.reportedBy && (*/}
                {/*    <>*/}
                {/*      <span className="text-muted">Reported By: </span>*/}
                {/*      <span>{task.reportedBy ? task.reportedBy.name : ""}</span>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
