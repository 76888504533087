import React from "react";
import { Modal } from "react-bootstrap";

function ModalPopup(props) {
  return (
    <Modal
      show={true}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      fullscreen={props.fullscreen}
      size={props.size}
      centered={props.centered || false}
    >
      <Modal.Header
        closeButton
        style={
          props.noBg
            ? { height: 40, backgroundColor: "unset", padding: "0.5rem 1rem" }
            : {
                // backgroundColor: "#eff1f5",
                backgroundColor: "#fff",
                // height: 40,
                // padding: "1rem"
              }
        }
      >
        {props.title && <h3 className="my-0">{props.title}</h3>}
        {props.render && props.render()}
      </Modal.Header>
      {/*<Modal.Body style={{ background: "#f4f6fa" }}>*/}
      <Modal.Body
        style={
          props.noBg
            ? {}
            : {
                // background: "#eff1f5",
                background: "#fff",
                // padding: props.fullscreen
                //   ? "0px 10px 10px 10px"
                //   : "0 10px 15px 15px"
              }
        }
      >
        {props.children}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary">Close</Button>
        <Button variant="primary">Understood</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ModalPopup;
