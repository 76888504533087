import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  KEY_ENTER_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  CLEAR_EDITOR_COMMAND,
} from "lexical";
import {
  CAN_USE_BEFORE_INPUT,
  IS_APPLE_WEBKIT,
  IS_IOS,
  IS_SAFARI,
} from "@lexical/utils";
import { $isRootTextContentEmpty, $rootTextContent } from "@lexical/text";

export default function SetEditorPlugin({ setEditor }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    setEditor(editor);
  }, [editor, setEditor]);
}
