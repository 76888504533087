import React, { PureComponent } from "react";

export default class DeleteConfirmation extends PureComponent {
  render() {
    return (
      <div>
        <div style={{ fontSize: "14px" }}>
          Are you sure want to delete {this.props.type}?
        </div>
        <div className="row mt-4">
          <div className="d-flex justify-content-between col-md-12">
            <a
              className="btn btn-light pull-right"
              onClick={this.props.handleClose}
            >
              Cancel
            </a>

            <a className="btn btn-danger" onClick={this.props.delete}>
              Delete
            </a>
          </div>
        </div>
      </div>
    );
  }
}
