import OrganizationsView from "./OrganizationsView";
import React, { useEffect, useState } from "react";
import { getOrgs, updateLastUsedOrg } from "../../services/OrganizationService";
import { useNavigate } from "react-router-dom";
import ModalPopup from "../modal/ModalPopup";
import OrgForm from "./OrgForm";

export default function OrganizationsContainer() {
  const navigate = useNavigate();
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    loadOrgs();
  }, []);

  const loadOrgs = async () => {
    const userOrgs = (await getOrgs()) || [];
    setOrgs(userOrgs);
  };

  const openOrg = async (orgId) => {
    await updateLastUsedOrg(orgId);
    navigate(`/home/feed`);
  };

  const manageOrg = (orgId) => {
    setSelectedOrg(orgId);
  };

  return (
    <>
      <OrganizationsView
        orgs={orgs}
        openOrg={openOrg}
        startManage={manageOrg}
      />
      {selectedOrg && (
        <ModalPopup
          show={true}
          handleClose={() => setSelectedOrg(null)}
          title="Manage Organization"
          size="lg"
        >
          <OrgForm submit={() => {}} />
        </ModalPopup>
      )}
    </>
  );
}
