import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import * as React from "react";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeActionMenuPlugin from "./plugins/CodeActionMenuPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import CollapsiblePlugin from "./plugins/CollapsiblePlugin";
import CommentPlugin from "./plugins/CommentPlugin";
import ComponentPickerPlugin from "./plugins/ComponentPickerPlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import EmojisPlugin from "./plugins/EmojisPlugin";
import EquationsPlugin from "./plugins/EquationsPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import InlineImagePlugin from "./plugins/InlineImagePlugin";
import KeywordsPlugin from "./plugins/KeywordsPlugin";
import { LayoutPlugin } from "./plugins/LayoutPlugin/LayoutPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import MentionsPlugin from "./plugins/MentionsPlugin";
import PageBreakPlugin from "./plugins/PageBreakPlugin";
import SpeechToTextPlugin from "./plugins/SpeechToTextPlugin";
import TabFocusPlugin from "./plugins/TabFocusPlugin";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import TableHoverActionsPlugin from "./plugins/TableHoverActionsPlugin";
import YouTubePlugin from "./plugins/YouTubePlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import MentionClickPlugin from "./plugins/MentionClickPlugin";
import { useEffect, useState } from "react";
import { CAN_USE_DOM } from "./shared/canUseDOM";
import TldrPlugin from "./plugins/TldrPlugin";

export default function EditorPlugins({
  commentsEnabled,
  onChange,
  historyState,
  floatingAnchorElem,
  isEditable,
  isLinkEditMode,
  setIsLinkEditMode,
  setCommentsPanelOpen,
}) {
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false);

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;
      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener("resize", updateViewPortWidth);
    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  return (
    <>
      <DragDropPaste />
      <AutoFocusPlugin />
      <ClearEditorPlugin />
      <ComponentPickerPlugin />
      <EmojiPickerPlugin />
      {/*<AutoEmbedPlugin />*/}
      <MentionsPlugin />
      <EmojisPlugin />
      <HashtagPlugin />
      <KeywordsPlugin />
      <SpeechToTextPlugin />
      <AutoLinkPlugin />
      {commentsEnabled && floatingAnchorElem && (
        <CommentPlugin
          providerFactory={undefined}
          anchorElem={floatingAnchorElem}
          setCommentsPanelOpen={setCommentsPanelOpen}
        />
      )}
      <HistoryPlugin externalHistoryState={historyState} />
      <CodeHighlightPlugin />
      <ListPlugin />
      <CheckListPlugin />
      <ListMaxIndentLevelPlugin maxDepth={7} />
      <TablePlugin
        // hasCellMerge={tableCellMerge}
        // hasCellBackgroundColor={tableCellBackgroundColor}
        hasCellMerge={false}
        hasCellBackgroundColor={false}
      />
      <TableCellResizer />
      <TableHoverActionsPlugin />
      <ImagesPlugin />
      <InlineImagePlugin />
      <LinkPlugin />
      <YouTubePlugin />
      <ClickableLinkPlugin disabled={isEditable} />
      <HorizontalRulePlugin />
      <EquationsPlugin />
      <TabFocusPlugin />
      <TabIndentationPlugin />
      <CollapsiblePlugin />
      <PageBreakPlugin />
      <LayoutPlugin />
      {isEditable && floatingAnchorElem && !isSmallWidthViewport && (
        <>
          <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
          <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
          <FloatingLinkEditorPlugin
            anchorElem={floatingAnchorElem}
            isLinkEditMode={isLinkEditMode}
            setIsLinkEditMode={setIsLinkEditMode}
          />
          <TableCellActionMenuPlugin
            anchorElem={floatingAnchorElem}
            cellMerge={true}
          />
          <FloatingTextFormatToolbarPlugin
            anchorElem={floatingAnchorElem}
            setIsLinkEditMode={setIsLinkEditMode}
            isCommentsEnabled={commentsEnabled}
          />
        </>
      )}
      <OnChangePlugin onChange={onChange} />
      {/*<TldrPlugin />*/}
      {floatingAnchorElem && (
        <MentionClickPlugin anchorElem={floatingAnchorElem} />
      )}
    </>
  );
}
