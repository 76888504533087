import {
  addChatMessage,
  deleteChatMessage,
  getContent,
  updateChatMessage,
} from "../../services/ContentService";
import {
  ADD_CHAT_GROUP,
  ADD_CHAT_MESSAGE,
  LOAD_CHAT_GROUPS,
  LOAD_CHAT_MESSAGES,
  SET_CHAT_GROUP,
} from "../actionTypes";
import { createGroup, getGroups } from "../../services/GroupService";

export const fetchChatGroups = () => async (dispatch) => {
  const groups = await getGroups("CHAT");
  dispatch({
    type: LOAD_CHAT_GROUPS,
    payload: {
      groups,
    },
  });
  //registerMultipleGroups(groups.map((group) => group._id));
};

export const selectChatGroup = (group) => async (dispatch) => {
  dispatch({
    type: SET_CHAT_GROUP,
    payload: {
      group,
    },
  });
};

export const createChatGroup = (group) => async (dispatch) => {
  const response = await createGroup({ ...group, type: "CHAT" });
  dispatch({
    type: ADD_CHAT_GROUP,
    payload: {
      group: response,
    },
  });
  dispatch({
    type: SET_CHAT_GROUP,
    payload: {
      group: response,
    },
  });
};

export const fetchMessages = (groupId) => async (dispatch) => {
  const messages = await getContent(groupId, "chat");
  dispatch({
    type: LOAD_CHAT_MESSAGES,
    payload: {
      items: messages,
      groupId,
    },
  });
};

export const doAddMessage = (groupId, data) => async (dispatch) => {
  // await addChatMessage(groupId, data);
  // dispatch({
  //   type: ADD_CHAT_MESSAGE,
  //   payload: {
  //     groupId,
  //     message: data,
  //   },
  // });
};

export const doUpdateMessage =
  (groupId, messageId, data) => async (dispatch) => {
    await updateChatMessage(groupId, messageId, data);
  };

export const doDeleteMessage = (groupId, messageId) => async (dispatch) => {
  await deleteChatMessage(groupId, messageId);
};
