import QuestionContent from "../content/question/QuestionContent";
import React, { useState } from "react";
import { Info } from "lucide-react";
import QuestionEditor from "../content/question/QuestionEditor";
import ModalPopup from "../modal/ModalPopup";
import { getIcon } from "../groups/GroupIcons";
import FeedContentContainer from "../content/docs/FeedContentContainer";
import GroupSelector from "../selectors/GroupSelector";

export default function ({ groupId, aiResponse, addQuestion, selectGroup }) {
  const [showReference, setShowReference] = useState(false);
  const { existing, data, subtype, reference } = aiResponse;

  if (existing) {
    if (subtype === "answer") {
      return (
        <div className="mb-3 white-box">
          <div>
            Here is an answer that is found from data across all groups.
          </div>

          <div className="my-3">{data}</div>

          <div>
            <Info className="font-lg mr-2" /> This answer was generated from{" "}
            {reference.type}:{" "}
            <a className="link" onClick={() => setShowReference(true)}>
              {reference.title}
            </a>
          </div>
          {showReference && (
            <ModalPopup
              handleClose={() => setShowReference(false)}
              size={reference.type === "doc" ? "xl" : "md"}
            >
              <div className="dfac">
                <div className="mr-2">{getIcon(reference.type, 20)}</div>
                <h4>{reference.title}</h4>
              </div>
              <div style={{ width: "100%" }}>
                <FeedContentContainer
                  type={reference.type}
                  contentId={reference.contentId}
                />
              </div>
            </ModalPopup>
          )}
        </div>
      );
    } else {
      return (
        <div className="mb-3 white-box">
          <div>
            An existing question has been found that matches with your question
            above.
          </div>

          <QuestionContent question={data} groupId={groupId} />
        </div>
      );
    }
  }

  return (
    <div className=" mb-3">
      <div className="mb-3">
        Could not find an answer to the given question in your groups. Would you
        like to create the following question and let your team answer?
      </div>
      <div className="white-box">
        {selectGroup && (
          <div className="mb-3" style={{ width: 200 }}>
            <div>Group</div>
            <GroupSelector selectGroup={selectGroup} />
          </div>
        )}
        <QuestionEditor
          groupId={groupId}
          submit={addQuestion}
          question={data}
          newContent
          noCancel
        />
      </div>
    </div>
  );
}
