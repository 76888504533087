import React, { Component } from "react";
import ModalPopup from "../modal/ModalPopup";
import { deleteGroup, removeSelfFromGroup } from "../../services/GroupService";
import { getUser } from "../../services/DataService";
import GroupName from "./GroupName";
import Share from "./share/Share";
import {
  changeView,
  resetGroup,
  toggleCalendar,
  closeCollection,
  toggleActivities,
} from "../../state/actions/group";
import { connect } from "react-redux";
import { toastMessage } from "../../state/actions/toaster";
import Icon from "../icons/Icon";

export function getMenuEnabledTypes(types) {
  return types.filter((type) => {
    return (
      type === "task" ||
      type === "text" ||
      type === "bookmark" ||
      type === "kanban" ||
      type === "files" ||
      type === "doc" ||
      type === "sub-collections" ||
      type === "drawing" ||
      type === "code" ||
      type === "question" ||
      type === "chat"
    );
  });
}

class GroupActions extends Component {
  constructor(props) {
    super(props);
    this.state = { editName: false };
  }

  deleteGroup = (id) => {
    deleteGroup(id, () => {
      this.props.history.push("/groups");
    });
  };

  onChangeView = (e) => {
    let { list } = this.props;
    this.props.changeView(list._id, e.target.value);
  };

  removeSelfFromGroup = (id) => {
    removeSelfFromGroup(id, () => {
      this.props.history.push("/groups");
    });
  };

  openModal = (component) => {
    this.setState({ showModal: true, modalComponent: component });
  };

  closeModal = () => {
    this.setState({ showModal: false, modalComponent: null });
  };

  getComponent = (isOwner) => {
    let { modalComponent, showModal } = this.state;
    let { group } = this.props;
    if (showModal) {
      if (modalComponent === "SHARE_GROUP") {
        return (
          <Share
            isOwner={isOwner}
            groupId={group._id}
            closeModal={this.closeModal}
          />
        );
      }
    }
  };

  render() {
    let { group } = this.props;
    if (!group) {
      return null;
    }
    let user = getUser();
    let isOwner = group.user.id === user._id;

    return (
      <>
        <div className="d-flex justify-content-between mb-2">
          <div
            className="align-items-center d-flex "
            style={{ width: "max-content", fontWeight: 500 }}
          >
            <GroupName isOwner={isOwner} group={group} />
          </div>

          <div className="d-flex align-items-center">
            <a
              onClick={() => this.openModal("SHARE_GROUP")}
              className="link d-flex align-items-center ml-2"
            >
              <Icon style={{ marginRight: 5 }} name="people_alt" />

              <span className="d-md-block d-none">Members</span>
            </a>
          </div>

          {/*<div className="d-flex align-items-end">*/}
          {/*  <GroupContextMenu isOwner={isOwner} group={this.props.group} />*/}

          {/*  <div>*/}
          {/*    <a*/}
          {/*      title="Close this group"*/}
          {/*      to="/groups"*/}
          {/*      className="text-dark btn-sm d-flex align-items-end p-0"*/}
          {/*      onClick={() => {*/}
          {/*        this.props.closeCollection(groupId);*/}
          {/*        this.props.history.push("/home");*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <Icon name="cancel" style={{ fontSize: 24 }} />*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {this.state.showModal && (
            <ModalPopup
              title="Members"
              show={this.state.showModal}
              handleClose={this.closeModal}
            >
              {this.getComponent(isOwner)}
            </ModalPopup>
          )}
        </div>
      </>
    );
  }
}

const data = (state) => {
  return {
    group: state.group.groupData,
    calendarOpen: state.group.calendarOpen,
  };
};

const actions = {
  changeView,
  toastMessage: toastMessage,
  toggleCalendar,
  closeCollection,
  toggleActivities,
  resetGroup,
};

export default connect(data, actions)(GroupActions);
