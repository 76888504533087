import React, { useState, forwardRef, useImperativeHandle } from "react";
import LexicalEditor from "../../common/texteditor/lexical/LexicalEditor";

const TaskDescriptionForm = forwardRef(function (props, ref) {
  const [description, setDescription] = useState(
    props.description || undefined,
  );
  const [parsedText, setParsedText] = useState("");

  const setContent = (content, parsed) => {
    setDescription(content);
    setParsedText(parsed);
  };

  useImperativeHandle(ref, function getDescription() {
    return { description, parsedText };
  });

  return (
    <>
      <LexicalEditor
        content={description}
        onChange={setContent}
        editorStyle={{ height: 200 }}
        editorType="minimal"
      />
    </>
  );
});

export default TaskDescriptionForm;
