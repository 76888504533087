import React from "react";
import DocEditor from "../content/docs/DocEditor";
import GroupSelector from "../selectors/GroupSelector";

export default function ({ groupId, aiResponse, createDoc, selectGroup }) {
  return (
    <div>
      <div className="mb-3">
        The given content looks like a potential document. Would you like to
        create the following doc?
      </div>
      <div className="white-box">
        {selectGroup && (
          <div className="mb-3" style={{ width: 200 }}>
            <div>Group</div>
            <GroupSelector selectGroup={selectGroup} />
          </div>
        )}
        <DocEditor
          groupId={groupId}
          submit={createDoc}
          doc={aiResponse.data}
          editorHeight={250}
          btnLabel="Post"
          noCancel
        />
      </div>
    </div>
  );
}
