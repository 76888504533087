import Ajax from "./Ajax";

export function createGroup(data, cb) {
  return Ajax.post("/api/v1/groups", data).then(function (json) {
    return json.data;
  });
}

export function updateGroup(groupId, data, cb) {
  Ajax.patch("/api/v1/groups/" + groupId, data).then(function (json) {
    cb(json.success);
  });
}

export function getGroups(type) {
  return Ajax.get(`/api/v1/groups${type ? "?type=" + type : ""}`).then(
    function (json) {
      return json.data;
    },
  );
}

export async function getGroup(id) {
  return Ajax.get("/api/v1/groups/" + id).then(function (json) {
    return json.data;
  });
}

export function deleteGroup(id, cb) {
  Ajax.delete("/api/v1/groups/" + id).then(function (json) {
    cb(json.data);
  });
}

export function removeSelfFromGroup(id, cb) {
  Ajax.delete("/api/v1/groups/" + id + "/users").then(function (json) {
    cb(json.data);
  });
}

export function getCollectionOwnerName(groupId, cb) {
  Ajax.get("/api/v1/groups/" + groupId + "/owner").then(function (json) {
    if (json.success) {
      cb(json.data);
    }
  });
}

export function shareGroup(groupId, data, cb) {
  Ajax.post("/api/v1/groups/" + groupId + "/share", data).then(function (json) {
    cb(json);
  });
}

export function removeUserFromGroup(groupId, userId, cb) {
  Ajax.delete("/api/v1/groups/" + groupId + "/users/" + userId).then(
    function (json) {
      cb(json.success);
    },
  );
}

export function approveGroup(groupId, cb) {
  Ajax.put("/api/v1/groups/" + groupId + "/share/approve").then(
    function (json) {
      cb(json.success);
    },
  );
}

export function rejectGroup(groupId, cb) {
  Ajax.put("/api/v1/groups/" + groupId + "/share/reject").then(function (json) {
    cb(json.success);
  });
}

export function addContentTypes(groupId, types, cb) {
  Ajax.put("/api/v1/groups/" + groupId + "/content-types", types).then(
    function (json) {
      cb(json);
    },
  );
}

export function updateGroupUserUi(groupId, data) {
  return Ajax.patch("/api/v1/groups/" + groupId + "/users/ui", data);
}

export function getGroupMembers(groupId) {
  return Ajax.get("/api/v1/groups/" + groupId + "/users").then(function (json) {
    return json.data;
  });
}

export function getGroupActivities(groupId) {
  return Ajax.get("/api/v1/groups/" + groupId + "/activities").then(
    function (json) {
      return json.data;
    },
  );
}

export function getOrgMembers() {
  return Ajax.get("/api/v1/users").then(function (json) {
    return json.data;
  });
}
