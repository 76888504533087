import React, { useRef, useState } from "react";
import Icon from "../../icons/Icon";
import DateTime from "../../widgets/DateTime";
import RichTextView from "../../common/texteditor/RichTextView";
import TaskCommentForm from "./TaskCommentForm";

export default ({ comment, index, deleteComment, updateComment }) => {
  const [startEdit, setStartEdit] = useState(false);
  const commentRef = useRef();

  if (!comment.content) {
    return null;
  }

  return (
    <div
      key={comment._id}
      className="mb-3"
      style={{
        borderBottom: "1px solid #b3b3b3",
        padding: 5,
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h4>{comment.createdBy.name}</h4>
        <small>
          <DateTime value={new Date(comment.createdOn)} />
        </small>
        <div className="ml-2 text-muted d-flex align-items-center">
          <a className="link ml-2" onClick={() => setStartEdit(true)}>
            <Icon name="edit_note" fontSize={18} className="text-primary" />
          </a>
          <a
            className="link ml-1"
            onClick={() => deleteComment(comment, index)}
          >
            <Icon name="delete" fontSize={18} className="text-danger" />
          </a>
        </div>
      </div>

      {startEdit ? (
        <>
          <TaskCommentForm ref={commentRef} comment={comment.content} />
          <div className="d-flex justify-content-end mt-2">
            <button
              className="btn btn-white btn-sm"
              onClick={() => setStartEdit(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-dark btn-sm ml-2"
              onClick={() => {
                updateComment(index, comment._id, commentRef.current);
                setStartEdit(false);
              }}
            >
              Save
            </button>
          </div>
        </>
      ) : (
        <RichTextView content={comment.content} />
      )}
    </div>
  );
};
