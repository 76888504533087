import Icon from "../icons/Icon";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { getGroup } from "../../services/GroupService";
import { ListTodo, Newspaper } from "lucide-react";

const GroupSideBar = (props) => {
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);

  useEffect(() => {
    loadGroup();
  }, []);

  const loadGroup = async () => {
    const group = await getGroup(groupId);
    setGroup(group);
  };

  return (
    <>
      {/*{group && (*/}
      {/*  <>*/}
      {/*    /!*<div*!/*/}
      {/*    /!*  className="dfac justify-content-center"*!/*/}
      {/*    /!*  style={{ fontWeight: 500 }}*!/*/}
      {/*    /!*>*!/*/}
      {/*    /!*  <h3>{group.name}</h3>*!/*/}
      {/*    /!*</div>*!/*/}
      {/*    <GroupActions group={group} />*/}
      {/*  </>*/}
      {/*)}*/}
      <ul
        className="nav d-flex flex-column nav-second-level"
        style={{ paddingLeft: 10 }}
      >
        <li>
          <NavLink
            to={`/home/groups/${groupId}/posts`}
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            style={{ display: "flex", alignItems: "center", padding: 5 }}
          >
            {/*<Icon name="view_timeline" fontSize={22}></Icon>*/}
            <Newspaper size={22} />
            <span className="ml-2">Posts</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/home/groups/${groupId}/tasks`}
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            style={{ display: "flex", alignItems: "center", padding: 5 }}
          >
            {/*<Icon name="done_all" fontSize={22}></Icon>*/}
            <ListTodo size={22} />
            <span className="ml-2">Tasks</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/home/groups/${groupId}/docs`}
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            style={{ display: "flex", alignItems: "center", padding: 5 }}
          >
            <Icon name="notes" fontSize={22}></Icon>
            <span className="ml-2">Docs</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/home/groups/${groupId}/questions`}
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            style={{ display: "flex", alignItems: "center", padding: 5 }}
          >
            <Icon name="question_answer" fontSize={22}></Icon>
            <span className="ml-2">Questions</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/home/groups/${groupId}/files`}
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            style={{ display: "flex", alignItems: "center", padding: 5 }}
          >
            <Icon name="folder" fontSize={22}></Icon>
            <span className="ml-2">Files</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/home/groups/${groupId}/calendar`}
            className={({ isActive }) => (isActive ? "link sb-nav-active" : "")}
            style={{ display: "flex", alignItems: "center", padding: 5 }}
          >
            <Icon name="calendar_month" fontSize={22}></Icon>
            <span className="ml-2">Calendar</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default GroupSideBar;
