import React from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { BarLoader } from "react-spinners";
import DeleteIcon from "../../icons/DeleteIcon";
import { toastMessage } from "../../../state/actions/toaster";
import { doUploadFiles } from "../../../state/actions/files";

class FilesUploaderInline extends React.Component {
  constructor(props) {
    super(props);
    this.state = { files: [], loading: false };
  }

  uploadFiles = async () => {
    let files = this.state.files;
    this.setState({ loading: true });
    await this.props.uploadFiles(files);
    this.props.toastMessage(
      "Files have been uploaded successfully!",
      "success",
      3,
    );
    this.setState({ files: [], loading: false });
  };

  removeFile = (index) => {
    let files = this.state.files;
    files.splice(index, 1);
    this.setState({ files: files });
  };

  onDrop = (files) => {
    this.setState({
      files,
    });
  };

  render() {
    let { loading } = this.state;
    return (
      <div className="white-box mb-3">
        <Dropzone
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .mp3"
          onDrop={this.onDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "50px",
                  border: "1px dashed #3e2d42",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input {...getInputProps()} />
                <p style={{ margin: "45px 20px" }}>
                  Drop some files here, or click to select files to upload.
                </p>
              </div>
            </section>
          )}
        </Dropzone>

        <aside>
          <ul className="list-group clear-list m-t">
            {this.state.files.map((f, index) => (
              <li className="list-group-item" key={f.name}>
                {f.name}
                <a
                  style={{ marginLeft: "15px" }}
                  onClick={() => {
                    this.removeFile(index);
                  }}
                >
                  <DeleteIcon className="mr-h" />
                </a>
              </li>
            ))}
          </ul>
        </aside>

        <BarLoader
          color={"#2d85c6"}
          loading={loading}
          css={"display: block; margin: 0 auto; border-color: red;"}
          size={150}
        />

        {this.state.files.length > 0 && !loading && (
          <div className="d-flex justify-content-end">
            <a
              className="btn btn-dark btn-sm"
              onClick={this.uploadFiles}
              // style={{ width: "100%" }}
            >
              Upload
            </a>
          </div>
        )}
      </div>
    );
  }
}

const actions = {
  toastMessage: toastMessage,
};

export default connect(null, actions)(FilesUploaderInline);
