import React from "react";
import Icon from "../../icons/Icon";
import DateTime from "../../widgets/DateTime";
import RichTextView from "../../common/texteditor/RichTextView";
import Avatar from "../../widgets/Avatar";
import { getNameInitials } from "../../../utils/Utils";
import { getUser } from "../../../services/DataService";

function AnswerView({ answer, startEdit, startDelete }) {
  const user = getUser() || {};

  return (
    <div>
      <div className="dfjbac">
        <div className="dfac">
          <Avatar
            size="sm"
            className="mr-2"
            text={getNameInitials(answer.createdBy.name)}
          />
          <div style={{ fontSize: "16px", fontWeight: "500" }}>
            {answer.createdBy.name}
          </div>
        </div>
        {user._id === answer.createdBy.id && (
          <div className="dfac">
            <div className="text-muted small mx-3">
              <DateTime value={new Date(answer.createdOn)} />
            </div>
            <a className="link mx-1" onClick={startEdit}>
              <Icon
                name="edit_note"
                style={{ display: "flex", fontSize: 16 }}
              />
            </a>
            <a className="link text-danger mr-2" onClick={startDelete}>
              <Icon name="delete" style={{ display: "flex", fontSize: 16 }} />
            </a>
          </div>
        )}
      </div>

      <div className="my-2">
        <RichTextView content={answer.content} />
      </div>
    </div>
  );
}

export default AnswerView;
