import "./editor.css";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import * as React from "react";
import { SharedHistoryContext } from "./context/SharedHistoryContext";
import Editor from "./Editor";
import EditorNodes from "./nodes/EditorNodes";
import { TableContext } from "./plugins/TablePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";

export default function LexicalEditor({
  commentsEnabled,
  placeholder = "Enter text here...",
  onChange: propsOnChange,
  content,
  editable = true,
  editorType,
  onSubmit,
  editorStyle,
  setCommentsPanelOpen,
  editorRef,
}) {
  const getInitialEditorState = () => {
    const root = $getRoot();
    if (root.getFirstChild() === null) {
      const paragraph = $createParagraphNode();
      paragraph.append($createTextNode(content));
      root.append(paragraph);
    }
  };

  const isParsableContent = () => {
    try {
      JSON.parse(content);
      return true;
    } catch (e) {}
    return false;
  };

  const initialConfig = {
    // editorState: $prepopulatedRichText,
    editorState: isParsableContent() ? content : getInitialEditorState,
    namespace: "lexical-editor",
    nodes: [...EditorNodes],
    onError: (error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
    editable,
  };

  function onChange(editorState) {
    // Call toJSON on the EditorState object, which produces a serialization safe string
    const editorStateJSON = editorState.toJSON();
    // However, we still have a JavaScript object, so we need to convert it to an actual string with JSON.stringify
    if (propsOnChange) {
      editorState.read(() => {
        propsOnChange(
          JSON.stringify(editorStateJSON),
          $getRoot().getTextContent(),
        );
      });
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <div
            className="editor-shell"
            style={editorType === "bubble" ? { border: 0 } : {}}
          >
            <Editor
              commentsEnabled={commentsEnabled}
              placeholder={placeholder}
              onChange={onChange}
              editable={editable}
              editorType={editorType}
              onSubmit={onSubmit}
              editorStyle={editorStyle}
              setCommentsPanelOpen={setCommentsPanelOpen}
              editorRef={editorRef}
            />
          </div>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}
