import React from "react";
import { getGroupMembers, getOrgMembers } from "../../../services/GroupService";
import { getUser } from "../../../services/DataService";
import Select from "react-select";

class ChatUsersSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectUsers: [] };
  }

  componentDidMount() {
    this.loadMembers();
  }

  loadMembers = async () => {
    let members = await getOrgMembers();
    let currentUser = getUser();
    members = members.filter((m) => m._id !== currentUser._id);
    this.setState({ members });
  };

  addMember = (user) => {
    let { selectUsers } = this.state;
    selectUsers.push(user);
    this.setState({ selectUsers: [...selectUsers] });
  };

  // onSelectUsers = () => this.props.onSelect(this.state.selectUsers);
  onSelectUsers = () =>
    this.props.onSelect(
      this.state.selectUsers.map((user) => ({
        id: user.value,
        name: user.label,
      })),
    );

  render() {
    let { members = [], selectUsers } = this.state;

    return (
      <div className="mt-3">
        <h3 className="text-center">Select Users</h3>

        <div className="mb-3">
          <Select
            options={members.map((member) => ({
              value: member._id,
              label: member.name,
            }))}
            onChange={(options) => this.setState({ selectUsers: options })}
            value={selectUsers}
            isMulti
          />
        </div>

        {/*{members.map((member) => {*/}
        {/*  return (*/}
        {/*    <>*/}
        {/*      <div className="d-flex justify-content-between">*/}
        {/*        <div>{member.name}</div>*/}
        {/*        <div className="ml-2">*/}
        {/*          <button*/}
        {/*            className="btn btn-outline-dark btn-sm"*/}
        {/*            onClick={() => {*/}
        {/*              this.addMember({*/}
        {/*                userId: member._id,*/}
        {/*                name: member.name,*/}
        {/*              });*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            Add*/}
        {/*          </button>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <hr />*/}
        {/*    </>*/}
        {/*  );*/}
        {/*})}*/}

        {selectUsers.length >= 1 && (
          <div className="mt-3 d-flex justify-content-between align-items-center">
            <div>{selectUsers.map((u) => u.name).join(", ")}</div>

            <div>
              <button className="btn btn-dark" onClick={this.onSelectUsers}>
                Start
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ChatUsersSelector;
