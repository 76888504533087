import React from "react";
import { connect } from "react-redux";
import ActivitiesContainer from "./ActivitiesContainer";
import Icon from "../icons/Icon";
import { toggleActivities } from "../../state/actions/group";

function ActivitiesInSidebar({ activitiesOpen, toggleActivities, group }) {
  if (!activitiesOpen) {
    return null;
  }
  return (
    <div id="right-sidebar" className="animated sidebar-open right-sidebar-act">
      <div
        className="sidebar-container"
        style={{ overflow: "auto", width: "", height: "100%" }}
      >
        <div className="px-2 py-2 d-flex justify-content-between align-items-center">
          <h3 className="text-dark d-flex align-items-center">
            <Icon style={{ marginRight: 2, fontSize: 32 }} name="view_stream" />
            Activities
          </h3>

          <a onClick={toggleActivities}>
            <Icon name="cancel" style={{ fontSize: 24 }} />
          </a>
        </div>

        <div
          className="mx-3"
          style={{
            borderRadius: 4,
            height: "calc(100vh - 150px)",
          }}
        >
          <ActivitiesContainer groupId={group._id} />
        </div>
      </div>
    </div>
  );
}

const data = (state) => {
  return {
    group: state.group.groupData,
    activitiesOpen: state.group.activitiesOpen,
  };
};

const actions = {
  toggleActivities,
};

export default connect(data, actions)(ActivitiesInSidebar);
