import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  KEY_ENTER_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  CLEAR_EDITOR_COMMAND,
} from "lexical";
import {
  CAN_USE_BEFORE_INPUT,
  IS_APPLE_WEBKIT,
  IS_IOS,
  IS_SAFARI,
} from "@lexical/utils";
import { $isRootTextContentEmpty, $rootTextContent } from "@lexical/text";

export default function EnterKeyPlugin({ onSubmit }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event) => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return false;
        }
        if (event !== null) {
          // If we have beforeinput, then we can avoid blocking
          // the default behavior. This ensures that the iOS can
          // intercept that we're actually inserting a paragraph,
          // and autocomplete, autocapitalize etc work as intended.
          // This can also cause a strange performance issue in
          // Safari, where there is a noticeable pause due to
          // preventing the key down of enter.
          if (
            (IS_IOS || IS_SAFARI || IS_APPLE_WEBKIT) &&
            CAN_USE_BEFORE_INPUT
          ) {
            return false;
          }
          event.preventDefault();
          if (event.shiftKey) {
            return editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
          } else {
            let text = $rootTextContent();
            text = text.trim();
            if (!!text) {
              const editorStateJSON = editor.getEditorState().toJSON();
              onSubmit(JSON.stringify(editorStateJSON));
              return editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
            }
          }
        }
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, onSubmit]);
}
