import { useState, useRef, useEffect } from "react";
import ImagePreview from "./ImagePreview";

export default function LocalFilePreview({ file, removeFile }) {
  const [filePreview, setFilePreview] = useState(null);

  useEffect(() => {
    previewFile();
  }, []);

  function previewFile() {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (readerEvent) => {
      if (file.type.includes("image")) {
        setFilePreview(readerEvent.target.result);
      } else if (file.type.includes("video")) {
        setFilePreview(readerEvent.target.result);
      }
    };
  }

  if (!filePreview) {
    return null;
  }

  return <ImagePreview src={filePreview} remove={removeFile} />;
}
