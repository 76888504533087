import React, { Component } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";

export class HomeBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="masthead d-flex"
        style={{
          paddingTop: "10px",
          paddingBottom: "0",
          backgroundColor: "#efefef",
          // backgroundImage: "linear-gradient( 135deg, #5EFCE8 10%, #736EFE 100%)"
        }}
      >
        <div className="container d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <img src="/img/logo/logo1.png" style={{ height: 36 }} />
            </div>
            <Navigation />
          </div>
          <div className="p text-center my-auto" style={{}}>
            <h2 style={{ fontSize: 32 }}>Home Page for your Workday!</h2>
            <h2 style={{ fontSize: 18 }} className="mt-1">
              Bring People, Data, and Context together
            </h2>

            <Link
              className="btn btn-dark"
              to="/signup"
              style={{ marginTop: "20px", padding: "10px 25px" }}
            >
              Sign Up
            </Link>

            {/*<Link*/}
            {/*  className="btn btn-outline btn-primary"*/}
            {/*  style={{*/}
            {/*    // color: "#fff",*/}
            {/*    marginTop: "20px",*/}
            {/*    padding: "10px 25px",*/}
            {/*    marginLeft: "10px"*/}
            {/*  }}*/}
            {/*  to="/signin"*/}
            {/*>*/}
            {/*  Sign In*/}
            {/*</Link>*/}
          </div>
        </div>
        {/*<div className="overlay" />*/}
      </div>
    );
  }
}

export default HomeBanner;
