import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { navigateToPage } from "../../state/actions/ui";
import { connect } from "react-redux";
import SideBar from "../layout/SideBar";
import { getUser } from "../../services/DataService";
import { getOrgs, updateLastUsedOrg } from "../../services/OrganizationService";
import ModalPopup from "../modal/ModalPopup";
import AddOrg from "./AddOrg";
import OrganizationsView from "./OrganizationsView";
import OrgForm from "./OrgForm";

function WorkHome(props) {
  let user = getUser();
  const [whatToShow, setWhatToShow] = useState("LOADING");
  const [showNewOrgModal, setShowNewOrgModal] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    props.navigateToPage("GROUPS");
    loadOrg();
  }, []);

  const loadOrg = async () => {
    if (user.lastUsedOrg) {
      setWhatToShow("MAIN");
      return;
    }
    const userOrgs = (await getOrgs()) || [];
    if (userOrgs.length === 0) {
      setWhatToShow("CREATE_ORG");
      return;
    }
    // if (userOrgs.length === 1) {
    //   //todo set last used org
    //   setWhatToShow("MAIN");
    //   return;
    // }
    setWhatToShow("SELECT_ORG");
    setOrgs(userOrgs);
  };

  const openOrg = async (orgId) => {
    await updateLastUsedOrg(orgId);
    setWhatToShow("MAIN");
  };

  const toggleNewOrgModal = () => setShowNewOrgModal(!showNewOrgModal);

  const manageOrg = (orgId) => {
    setSelectedOrg(orgId);
  };

  return (
    <div
      className="row no-gutters d-flex justify-content-center"
      style={{ height: "calc(100vh - 60px)" }}
    >
      {whatToShow === "LOADING" && (
        <div
          className="mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          ...loading
        </div>
      )}

      {whatToShow === "MAIN" && (
        <>
          <div
            className="col-md-2 pt-3"
            style={{
              background: "#f8f8f8",
              borderRight: "1px solid #e2e2e2",
            }}
          >
            <SideBar />
          </div>

          <div
            className="col-md-10 pt-3"
            style={{ height: "calc(100vh - 60px)", overflow: "auto" }}
          >
            <Outlet />
          </div>
        </>
      )}

      {whatToShow === "SELECT_ORG" && (
        <OrganizationsView
          orgs={orgs}
          openOrg={openOrg}
          startManage={manageOrg}
        />
      )}

      {whatToShow === "CREATE_ORG" && (
        <div className="d-flex align-content-center justify-content-center mt-5">
          <div>
            You are not part of any organizations. Click{" "}
            <a className="link" onClick={toggleNewOrgModal}>
              here
            </a>{" "}
            to create one.
          </div>
        </div>
      )}

      {showNewOrgModal && (
        <ModalPopup show handleClose={toggleNewOrgModal}>
          <AddOrg
            onAddOrg={() => {
              loadOrg();
              toggleNewOrgModal();
            }}
          />
        </ModalPopup>
      )}

      {selectedOrg && (
        <ModalPopup
          show={true}
          handleClose={() => setSelectedOrg(null)}
          title="Manage Organization"
          size="lg"
        >
          <OrgForm submit={() => {}} />
        </ModalPopup>
      )}
    </div>
  );
}

const actions = {
  navigateToPage,
};

export default connect(null, actions)(WorkHome);
