import CommentView from "./CommentView";

export default ({ comments, deleteComment, updateComment }) => {
  return comments.map((comment, index) => (
    <CommentView
      comment={comment}
      index={index}
      deleteComment={deleteComment}
      updateComment={updateComment}
    />
  ));
};
