import Ajax from "./Ajax";

export function getUser() {
  let stored = localStorage.getItem("user");
  if (!stored) {
    return null;
  }
  return JSON.parse(stored);
}

export function getUserId() {
  let stored = localStorage.getItem("user");
  if (!stored) {
    return null;
  }
  let user = JSON.parse(stored);
  return user._id;
}

function storeUserData(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function editStoredUserData(name, email, phone) {
  let user = getUser();
  user.name = name;
  user.email = email;
  user.phone = phone;
  localStorage.setItem("user", JSON.stringify(user));
}

export function clearStored() {
  localStorage.clear();
}

export function signUp(data, cb) {
  Ajax.post("/api/v1/users", data).then(function (json) {
    cb(json);
  });
}

export function editBasicDetails(data, cb) {
  Ajax.patch("/api/v1/users/edit-details", data).then(function (json) {
    cb(json);
  });
}

export function changePassword(data, cb) {
  Ajax.patch("/api/v1/users/change-password", data).then(function (json) {
    cb(json);
  });
}

export function confirmEmail(key, cb) {
  Ajax.post("/api/v1/users/confirm/email", { key: key }).then(
    (responseJson) => {
      cb(responseJson);
    },
  );
}

export function resendEmailConfirmation(email, cb) {
  Ajax.get("/api/v1/users/email-confirmation?email=" + email).then(
    (responseJson) => {
      cb(responseJson);
    },
  );
}

export function signIn(data) {
  return Ajax.post("/api/v1/users/authenticate", data).then(function (json) {
    if (json.success) {
      storeUserData(json.data);
    }
    return json;
  });
}

export function isExistingUser(email, cb) {
  Ajax.get("/api/v1/users/isAvailable?email=" + email).then(function (json) {
    if (json.success) {
      cb(json.data);
    }
  });
}
