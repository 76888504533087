import TaskRow from "../content/tasks/TaskRow";
import React from "react";
import TaskForm from "../content/tasks/TaskForm";
import GroupSelector from "../selectors/GroupSelector";

export function Task({ groupId, aiResponse, createTask, selectGroup }) {
  if (aiResponse.existing) {
    return (
      <div>
        <div className="mb-3">
          Here is a similar task that exists already in the group.
        </div>
        <TaskRow task={aiResponse.data} showDescription readOnly />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-3">
        There are no existing tasks for the given input in the group. Would you
        like to create the following task?
      </div>

      <div className="white-box">
        {selectGroup && (
          <div className="mb-3" style={{ width: 200 }}>
            <div>Group</div>
            <GroupSelector selectGroup={selectGroup} />
          </div>
        )}
        <TaskForm
          groupId={groupId}
          task={aiResponse.data}
          submitTask={createTask}
          expanded={true}
        />
      </div>
    </div>
  );
}
