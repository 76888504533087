import React, { Component } from "react";
import Icon from "../../icons/Icon";
export default class ShareRow extends Component {
  render() {
    var email = this.props.email;
    var deleteShare = this.props.deleteShare;

    return (
      <div className="dfjbac">
        <span className="m-l-xs">{email}</span>
        <a
          className="btn btn-xs btn-outline btn-danger mb-0"
          onClick={() => {
            deleteShare(email);
          }}
        >
          <Icon className="mr-h" name="close" />
        </a>
      </div>
    );
  }
}
