import React from "react";
import { toastMessage } from "../../state/actions/toaster";
import { connect } from "react-redux";
import { createOrg } from "../../services/OrganizationService";
import OrgForm from "./OrgForm";

function AddOrg({ toastMessage, onAddOrg }) {
  const addOrg = async (data) => {
    const org = await createOrg(data);
    toastMessage("A new organization has been created", "success", 3);
    onAddOrg(org);
  };

  return (
    <div className="row d-flex align-items-center justify-content-center">
      <div className="col-md-12">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h3>New Organization</h3>
          </div>
          <div className="ibox-content">
            <OrgForm submit={addOrg} />
          </div>
        </div>
      </div>
    </div>
  );
}

const actions = {
  toastMessage: toastMessage,
};

export default connect(null, actions)(AddOrg);
