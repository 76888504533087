import React, { Component, useState } from "react";
import { createGroup } from "../../services/GroupService";
import { closeModal } from "../../services/ModalService";
import { connect } from "react-redux";
import { compose } from "redux";
import { toastMessage } from "../../state/actions/toaster";
import { useNavigate } from "react-router-dom";

function AddGroup({ toastMessage, onAddGroup }) {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const submit = async (e) => {
    e.preventDefault();
    const group = await createGroup({ name, description, forWork: true });
    toastMessage("A new group has been created", "success", 3);
    navigate("/groups/" + group._id);
    onAddGroup();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div className="row d-flex align-items-center justify-content-center">
      <div className="col-md-12">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h3>New Group</h3>
          </div>
          <div className="ibox-content">
            <form role="form" onSubmit={submit}>
              <div className="form-group">
                {/* <label>Name</label> */}
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  placeholder="Name"
                  onChange={handleNameChange}
                  style={{ height: "40px" }}
                />
              </div>
              <div className="form-group">
                {/* <label>Description</label> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                  style={{ height: "40px" }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-md-12 d-flex justify-content-end">
                  <button className="btn btn-dark pull-right" type="submit">
                    <strong>Create</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function actions(dispatch) {
  return {
    toastMessage: toastMessage,
  };
}

export default compose(connect(null, actions))(AddGroup);
