import React, { useState } from "react";

const TaskTitle = ({ title: propsTitle, save, cancel }) => {
  const [title, setTitle] = useState(propsTitle);

  const onChange = (e) => {
    setTitle(e.target.value);
  };

  const saveTitle = () => {
    save(title);
  };

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid rgb(204, 204, 204)",
        padding: 10,
        borderRadius: 4,
      }}
    >
      <input
        style={{ border: 0 }}
        type="text"
        className="form-control"
        value={title}
        onChange={onChange}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-white btn-sm" onClick={cancel}>
          Cancel
        </button>
        <button className="btn btn-dark btn-sm ml-2" onClick={saveTitle}>
          Save
        </button>
      </div>
    </div>
  );
};

export default TaskTitle;
