import React, { useEffect, useState } from "react";
import RichTextView from "../../common/texteditor/RichTextView";
import { useParams } from "react-router-dom";
import { getContent } from "../../../services/ContentService";
import DocContent from "./DocContent";
import FeedContent from "../../feeds/FeedContent";

export default ({ type, contentId }) => {
  const { groupId } = useParams();
  const [content, setContent] = useState(null);

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = async () => {
    setContent(await getContent(groupId, type, contentId));
  };

  if (!content) {
    return null;
  }

  content.type = type;

  return <FeedContent feed={content} />;
};
