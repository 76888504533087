import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  doDeletePostComment,
  doUpdatePostComment,
  fetchPostComments,
} from "../../../state/actions/posts";
import CommentView from "./CommentView";
import CommentForm from "./CommentForm";
import EditPostComment from "./EditPostComment";

const CommentsContainer = ({ groupId, post, fetchComments, deleteComment }) => {
  const [commentToEdit, setCommentToEdit] = useState(null);

  useEffect(() => {
    fetchComments(groupId, post._id);
  }, []);

  const { comment: comments = [] } = post;

  return comments.map((comment) => (
    <div className="mb-3" key={comment._id}>
      {commentToEdit && commentToEdit._id === comment._id ? (
        <EditPostComment
          comment={comment}
          groupId={groupId}
          postId={post._id}
          cancelEdit={() => setCommentToEdit(false)}
        />
      ) : (
        <CommentView
          comment={comment}
          startEdit={() => setCommentToEdit(comment)}
          startDelete={() => deleteComment(groupId, post._id, comment._id)}
        />
      )}
    </div>
  ));
};

const actions = {
  fetchComments: fetchPostComments,
  updateComment: doUpdatePostComment,
  deleteComment: doDeletePostComment,
};

export default connect(null, actions)(CommentsContainer);
