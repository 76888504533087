import React, { useState } from "react";
import { signIn } from "../../services/DataService";
import Error from "./../alerts/Error";
import { Link, useNavigate } from "react-router-dom";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await signIn({ email, password });
      if (response.success) {
        setEmail("");
        setPassword("");
        navigate("/home/feed");
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  let inputStyle = { borderWidth: "1px", height: "40px" };

  return (
    <div className="loginColumns animated fadeInDown">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="text-center">
            <img src="/img/logo/logo.png" style={{ height: 42 }} />
          </div>

          <div
            className="ibox-content"
            style={{ padding: "30px", marginTop: "30px", borderRadius: 4 }}
          >
            <h4 className="font-bold text-center">Sign In</h4>
            <Error>{error}</Error>
            <form className="m-t" noValidate onSubmit={submit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                  style={inputStyle}
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  style={inputStyle}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary block full-width m-b"
              >
                Sign In
              </button>

              {/*<a href="#">*/}
              {/*  <small>Forgot password?</small>*/}
              {/*</a>*/}

              <p className="text-muted text-center" />
              <div className="mb-2 mt-4">
                <Link
                  className="btn btn-sm btn-white btn-block full-width"
                  to="/signup"
                >
                  Create an account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
