import React, { Component } from "react";
import PostCarousel from "./PostCarousel";
import { getNameInitials } from "../../../utils/Utils";
import CommentForm from "./CommentForm";
import { getUser } from "../../../services/DataService";
import Avatar from "../../widgets/Avatar";
import DateTime from "../../widgets/DateTime";
import Icon from "../../icons/Icon";
import DeleteIcon from "../../icons/DeleteIcon";

function CommentView({ comment, startEdit, startDelete }) {
  const currentUser = getUser();

  return (
    <div
      style={{
        padding: "7px 10px 10px 10px",
        borderRadius: 7,
        border: "1px solid #0000001d",
      }}
    >
      <div className="dfjbac">
        <div className="d-flex">
          <div>
            <Avatar
              size="sm"
              text={getNameInitials(comment.createdBy.name)}
              className="mr-2"
            />
          </div>
          <div>
            <div style={{ fontWeight: "500" }}>{comment.createdBy.name}</div>
            <div className="mt-1">{comment.content} </div>
          </div>
        </div>
        <div className="d-flex ml-2">
          <div className="text-muted small">
            <DateTime value={new Date(comment.createdOn)} />
          </div>
          {currentUser._id === comment.createdBy.id && (
            <div className="ml-2">
              <a onClick={startEdit} className="mx-1">
                <Icon name="edit_note" />
              </a>

              <a onClick={startDelete}>
                <span className="text-danger">
                  <DeleteIcon className="mr-h" />
                </span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommentView;
