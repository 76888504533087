import React, { useRef, useState } from "react";
import Icon from "../../icons/Icon";
import { doAddMessage } from "../../../state/actions/chat";
import { connect } from "react-redux";
import LocalFilePreview from "../../widgets/LocalFilePreview";
import LexicalEditorBubble from "../../common/texteditor/lexical/LexicalEditorBubble";
import { useChat } from "../../../sockets/SocketProvider";
import { CLEAR_EDITOR_COMMAND } from "lexical";

function GroupChatMessageForm({ addMessage, groupId }) {
  const [files, setFiles] = useState([]);
  const [textContent, setTextContent] = useState();
  const [editor, setEditor] = useState();
  const hiddenFilesRef = useRef();
  const socket = useChat();

  const onClickSend = (e) => {
    if (e) {
      e.preventDefault();
    }
    sendMessage(textContent);
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  };

  const onSubmit = (content) => {
    sendMessage(content);
  };

  const sendMessage = (messageContent) => {
    console.log("TextContent in sendMessage: ", messageContent);

    if (!messageContent && (!files || files.length === 0)) {
      return;
    }
    socket.emit("chat:add-message", {
      groupId: groupId,
      content: messageContent,
    });
    //addMessage(groupId, { content: messageContent, files });
    setFiles([]);
  };

  const parseContent = (content) => {
    // let parsed = content.replace(/<[^>]+>/g, " ");
    let parsed = content;
    if (parsed) {
      parsed = parsed.trim();
    }
    return parsed;
  };

  const onChangeTextContent = (value) => {
    console.log("TextContent that gets saved in state: ", value);
    setTextContent(value);
  };

  const handleFilesClick = () => {
    hiddenFilesRef.current.click();
  };

  const removeFile = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const handleFilesChange = (e) => {
    const newFiles = [];
    const nonArrayFiles = e.target.files;
    for (let i = 0; i < nonArrayFiles.length; i++) {
      newFiles.push(nonArrayFiles[i]);
    }
    setFiles(newFiles);
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        borderTop: "1px solid var(--border-color)",
        // padding: "10px 0 0 0",
        // padding: "0 0 0 0",
        border: "1px solid #e2e2e2",
        padding: 5,
        position: "fixed",
        width: "64%",
        bottom: "40px",
        zIndex: 10,
        backgroundColor: "#f8f8f8",
        borderRadius: 4,
      }}
    >
      <LexicalEditorBubble
        content=""
        onChange={onChangeTextContent}
        onSubmit={(content) => onSubmit(content)}
        setEditor={setEditor}
      />

      <div className="dfac">
        {files && (
          <div className="d-flex">
            {files.map((file, index) => (
              <div className="mr-2">
                <LocalFilePreview
                  file={file}
                  removeFile={() => removeFile(index)}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        className="chat-area-footer dfjbac"
        style={{ padding: 0, borderTop: 0 }}
      >
        <input
          type="file"
          onChange={handleFilesChange}
          ref={hiddenFilesRef}
          style={{ display: "none" }}
          multiple={true}
        />

        <div className="d-flex">
          {/*<svg*/}
          {/*  xmlns="http://www.w3.org/2000/svg"*/}
          {/*  viewBox="0 0 24 24"*/}
          {/*  fill="none"*/}
          {/*  stroke="currentColor"*/}
          {/*  strokeWidth="1.5"*/}
          {/*  strokeLinecap="round"*/}
          {/*  strokeLinejoin="round"*/}
          {/*  className="feather feather-video"*/}
          {/*>*/}
          {/*  <path d="M23 7l-7 5 7 5V7z" />*/}
          {/*  <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />*/}
          {/*</svg>*/}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-image"
            onClick={handleFilesClick}
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
            <circle cx="8.5" cy="8.5" r="1.5" />
            <path d="M21 15l-5-5L5 21" />
          </svg>

          {/*<svg*/}
          {/*  xmlns="http://www.w3.org/2000/svg"*/}
          {/*  viewBox="0 0 24 24"*/}
          {/*  fill="none"*/}
          {/*  stroke="currentColor"*/}
          {/*  strokeWidth="1.5"*/}
          {/*  strokeLinecap="round"*/}
          {/*  strokeLinejoin="round"*/}
          {/*  className="feather feather-plus-circle"*/}
          {/*>*/}
          {/*  <circle cx="12" cy="12" r="10" />*/}
          {/*  <path d="M12 8v8M8 12h8" />*/}
          {/*</svg>*/}
          {/*<svg*/}
          {/*  xmlns="http://www.w3.org/2000/svg"*/}
          {/*  viewBox="0 0 24 24"*/}
          {/*  fill="none"*/}
          {/*  stroke="currentColor"*/}
          {/*  strokeWidth="1.5"*/}
          {/*  strokeLinecap="round"*/}
          {/*  strokeLinejoin="round"*/}
          {/*  className="feather feather-paperclip"*/}
          {/*>*/}
          {/*  <path d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48" />*/}
          {/*</svg>*/}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-smile"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 14s1.5 2 4 2 4-2 4-2M9 9h.01M15 9h.01" />
          </svg>

          {/*<input type="text" placeholder="Type something here..." />*/}
        </div>
        {/*<form*/}
        {/*  onSubmit={this.sendMessage}*/}
        {/*  style={{ width: "100%", margin: "0 12px" }}*/}
        {/*>*/}
        {/*  <div className="d-flex justify-content-between">*/}
        {/*    <div className="form-group" style={{ flex: 1, marginBottom: 0 }}>*/}
        {/*      <input*/}
        {/*        ref={(input) => {*/}
        {/*          this.nameInput = input;*/}
        {/*        }}*/}
        {/*        className="form-control"*/}
        {/*        name="message"*/}
        {/*        placeholder="Enter message..."*/}
        {/*        value={content}*/}
        {/*        onChange={this.onChangeContent}*/}
        {/*        style={{*/}
        {/*          // border: "none",*/}
        {/*          // color: "var(--body-color);*/}
        {/*          // background-color: var(--input-bg);*/}
        {/*          padding: "10px",*/}
        {/*          borderRadius: "6px",*/}
        {/*          fontSize: "15px",*/}

        {/*          width: "100%",*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    /!*<div className="d-flex align-items-center">*!/*/}
        {/*    /!*  <button*!/*/}
        {/*    /!*    type="submit"*!/*/}
        {/*    /!*    className="btn btn-primary mb-0"*!/*/}
        {/*    /!*    style={{ height: "100%", borderRadius: 0, width: 50 }}*!/*/}
        {/*    /!*  >*!/*/}
        {/*    /!*    <Icon name="send" style={{ fontSize: 20 }} />*!/*/}
        {/*    /!*  </button>*!/*/}
        {/*    /!*</div>*!/*/}
        {/*  </div>*/}
        {/*</form>*/}

        <div className="d-flex">
          <button
            type="submit"
            // style={{ border: 0, background: "#fff" }}
            className="btn btn-light btn-xs mb-0"
            onClick={onClickSend}
          >
            <Icon name="send" fontSize={22} />
          </button>
        </div>
      </div>
    </div>
  );
}

const actions = {
  addMessage: doAddMessage,
};

export default connect(null, actions)(GroupChatMessageForm);
