import React from "react";

function MinimalTaskForm({ task, handleTitleChange, toggleExpanded, submit }) {
  return (
    <form onSubmit={submit} className="form-horizontal">
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          value={task ? task.title : ""}
          onChange={handleTitleChange}
          style={{ height: "40px" }}
          placeholder="What needs to be done?"
        />
      </div>
      {toggleExpanded && (
        <div>
          <a className="link" onClick={toggleExpanded}>
            Expand
          </a>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 d-flex justify-content-end">
          <button type="submit" className="btn btn-dark">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default MinimalTaskForm;
