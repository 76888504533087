import DocEditor from "./DocEditor";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doAddDoc, doUpdateDoc } from "../../../state/actions/docs";
import { connect } from "react-redux";

function NewDoc({ addDoc }) {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [commentsPanelOpen, setCommentsPanelOpen] = useState(false);
  const goBack = () => navigate(-1);

  const onDocAdd = (data) => {
    addDoc(groupId, data);
    goBack();
  };

  return (
    <div
      className={`row align-items-center ${commentsPanelOpen ? "justify-content-between" : "justify-content-center"}`}
    >
      <div
        className={`col-sm-12 ${commentsPanelOpen ? "col-md-8" : "col-md-9"}`}
      >
        <DocEditor
          groupId={groupId}
          submit={onDocAdd}
          toggleDocs={goBack}
          setCommentsPanelOpen={setCommentsPanelOpen}
          newContent
        />
      </div>
    </div>
  );
}

const actions = {
  addDoc: doAddDoc,
};

export default connect(null, actions)(NewDoc);
