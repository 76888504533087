import React from "react";
import RichTextView from "../../common/texteditor/RichTextView";

export default ({ doc, preview, setCommentsPanelOpen }) => {
  return (
    <div className="notes-content" style={{ borderTop: 0, paddingTop: 0 }}>
      {preview ? (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {doc.content.replace(/<[^>]+>/g, " ")}
        </div>
      ) : (
        <RichTextView
          content={doc.content}
          commentsEnabled={false}
          setCommentsPanelOpen={setCommentsPanelOpen}
        />
      )}
    </div>
  );
};
