import { createBrowserRouter } from "react-router-dom";
import Home from "./components/home/Home";
import SignIn from "./components/layout/SignIn";
import SignUp from "./components/layout/SignUp";
import SignUpConfirmation from "./components/layout/SignUpConfirmation";
import Profile from "./components/layout/Profile";
import EmailConfirmation from "./components/layout/EmailConfirmation";
import Main from "./components/layout/Main";
import WorkHome from "./components/groups/WorkHome";
import GroupContainer from "./components/groups/GroupContainer";
import ChatContainer from "./components/content/chat/ChatContainer";
import FeedsContainer from "./components/feeds/FeedsContainer";
import React from "react";
import PostsContainer from "./components/content/MediaPosts/PostsContainer";
import QuestionsContainer from "./components/content/question/QuestionsContainer";
import FilesContainerInline from "./components/content/files/FilesContainer";
import CalendarContainer from "./components/content/calendar/CalendarContainer";
import DocsContainer from "./components/content/docs/DocsContainer";
import TasksContainer from "./components/content/tasks/TasksContainer";
import GroupsContainer from "./components/groups/GroupsContainer";
import DocContainer from "./components/content/docs/DocContainer";
import NewDoc from "./components/content/docs/NewDoc";
import Today from "./components/feeds/Today";
import OrganizationsContainer from "./components/groups/OrganizationsContainer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "signup/confirmation",
    element: <SignUpConfirmation />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "confirm/email/:key",
    element: <EmailConfirmation />,
  },
  {
    path: "home",
    element: <Main />,
    children: [
      {
        path: "",
        element: <WorkHome />,
        children: [
          { path: "feed", element: <FeedsContainer /> },
          { path: "groups", element: <GroupsContainer /> },
          { path: "chat", element: <ChatContainer /> },
          {
            path: "groups/:groupId",
            element: <GroupContainer />,
            children: [
              { path: "posts", element: <PostsContainer /> },
              { path: "tasks", element: <TasksContainer /> },
              { path: "docs", element: <DocsContainer /> },
              { path: "questions", element: <QuestionsContainer /> },
              { path: "files", element: <FilesContainerInline /> },
              { path: "calendar", element: <CalendarContainer /> },
              { path: "docs/new", element: <NewDoc /> },
              { path: "docs/:docId", element: <DocContainer /> },
            ],
          },
          { path: "", element: <Today /> },
        ],
      },
      {
        path: "home/groups/:groupId",
        element: <GroupContainer />,
      },
      { path: "orgs", element: <OrganizationsContainer /> },
    ],
  },
]);

export default router;
