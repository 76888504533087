import React from "react";
import PostCarousel from "./PostCarousel";
import CommentsContainer from "./CommentsContainer";
import RichTextView from "../../common/texteditor/RichTextView";
import PostActions from "./PostActions";
import NewPostComment from "./NewPostComment";

function PostContent({ groupId, post }) {
  return (
    <div>
      <div
        className="notes-content my-3"
        style={{ borderTop: 0, paddingTop: 0 }}
      >
        <RichTextView content={post.content} />
      </div>

      {post.files && post.files.length >= 1 && (
        <div>
          <PostCarousel files={post.files} groupId={groupId} />
        </div>
      )}

      <PostActions groupId={groupId} post={post} />

      <div style={{ marginBottom: 15 }} />

      <NewPostComment groupId={groupId} postId={post._id} />

      <div className="my-2">
        <CommentsContainer groupId={groupId} post={post} />
      </div>
    </div>
  );
}

export default PostContent;
