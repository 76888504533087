import { doUpdateAnswer } from "../../../state/actions/question";
import { connect } from "react-redux";
import QuestionTitle from "./QuestionTitle";
import QuestionContent from "./QuestionContent";

function QuestionView({
  question,
  embedded,
  groupId,
  updateAnswer,
  startEdit,
  startDelete,
}) {
  return (
    <div
      style={{
        padding: "15px 20px 20px 20px",
        borderRadius: 7,
        border: "1px solid #0000001d",
        backgroundColor: "#fff",
      }}
    >
      <QuestionTitle
        question={question}
        embedded={embedded}
        groupId={groupId}
        startEdit={startEdit}
        startDelete={startDelete}
      />

      <QuestionContent
        groupId={groupId}
        question={question}
        updateAnswer={updateAnswer}
      />
    </div>
  );
}

const actions = {
  updateAnswer: doUpdateAnswer,
};

export default connect(null, actions)(QuestionView);
