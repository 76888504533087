import React, { Component } from "react";
import Icon from "../../icons/Icon";
import DateTime from "../../widgets/DateTime";

export default class FileViewInline extends Component {
  getFileIcon = (type) => {
    if (!type) {
      return <Icon name="description" />;
    }
    if (type.indexOf("image") !== -1) {
      return <Icon fontSize={24} name="image" />;
    } else if (type === "application/pdf") {
      return <Icon fontSize={24} name="picture_as_pdf" />;
    } else if (type.indexOf("video") !== -1) {
      return <Icon fontSize={24} name="video_file" />;
    } else if (type.indexOf("audio") !== -1) {
      return <Icon fontSize={24} name="volume_up" />;
    } else {
      return <Icon fontSize={24} name="description" />;
    }
  };

  render() {
    let { file, highlight, embedded } = this.props;

    if (!file) {
      return null;
    }

    return (
      <div
        className={`mr-2 feed-element mb-2 ${highlight ? "file-active" : ""}`}
        style={{
          padding: "15px 20px 20px 20px",
          borderRadius: 7,
          border: "1px solid #0000001d",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <a
          className="link"
          onClick={() => {
            this.props.openFile(file);
          }}
        >
          <div className="file-name dfjbac">
            <div className="d-flex">
              {this.getFileIcon(file.meta.mimetype)}
              <div
                className="ml-1 mb-2 overflow-hidden"
                style={{ wordBreak: "break-word" }}
              >
                {file.name}
              </div>
            </div>
            {!embedded && (
              <div>
                <small>
                  <DateTime value={new Date(file.dateCreated)} />
                </small>
              </div>
            )}
          </div>
        </a>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#ff0000",
            cursor: "pointer",
          }}
          onClick={() => this.props.deleteFile(file.group, file._id)}
        >
          <Icon name="delete" fontSize={24} />
        </div>
      </div>
    );
  }
}
