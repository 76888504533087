import React from "react";
import ViewOnlyLexicalEditor from "./lexical/ViewOnlyLexicalEditor";

const RichTextView = ({
  content,
  onClick,
  commentsEnabled,
  setCommentsPanelOpen,
}) => {
  try {
    JSON.parse(content);
    return (
      <ViewOnlyLexicalEditor
        content={content}
        editorStyle={{
          padding: 5,
          minHeight: 20,
        }}
        commentsEnabled={commentsEnabled}
        setCommentsPanelOpen={setCommentsPanelOpen}
      />
    );
  } catch (e) {}

  return (
    <div className="tiptap">
      <div
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        dangerouslySetInnerHTML={{ __html: content }}
        className="ProseMirror"
      />
    </div>
  );
};

export default RichTextView;
