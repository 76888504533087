import React, { useEffect, useState } from "react";
import { getGroups } from "../../services/GroupService";
import Select from "react-select";

export default function ({ selectGroup, placeholder }) {
  const [groups, setGroups] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    let groups = await getGroups("REGULAR");
    groups = groups.map((group) => ({
      value: group._id,
      label: group.name,
    }));
    setGroups(groups);
  };

  const onChange = (group) => {
    setSelected(group);
    selectGroup(group.value);
  };

  return (
    <Select
      placeholder={placeholder || ""}
      options={groups}
      onChange={onChange}
      value={selected}
      isClearable={false}
    />
  );
}
