import React, { useState } from "react";
import "./Chats.css";
import Icon from "../../icons/Icon";
import ModalPopup from "../../modal/ModalPopup";
import ChatUsersSelector from "./ChatUsersSelector";
import { createChatGroup } from "../../../state/actions/chat";
import { connect } from "react-redux";
import { getUser } from "../../../services/DataService";

function NewChatsList({ groups = [], select, selected, createChat }) {
  const [newGroup, setNewGroup] = useState(false);

  const toggleNewGroup = () => {
    setNewGroup(!newGroup);
  };

  const createGroup = (users) => {
    createChat({ users });
    toggleNewGroup();
  };

  const getChatName = (group) => {
    if (group.type === "CHAT") {
      let users = group.users || [];
      return users.map((user) => user.name).join(", ");
    } else {
      return group.name;
    }
  };

  return (
    <>
      <div
        style={{
          maxHeight: "calc(100vh - 250px)",
          overflow: "auto",
        }}
      >
        {groups.map((group) => (
          <div
            key={group._id}
            className={`msg border-bottom ${group._id === selected ? "active" : ""}`}
            style={group._id !== selected ? { paddingLeft: "11px" } : {}}
            onClick={() => select(group)}
          >
            {/*<img*/}
            {/*  className="msg-profile"*/}
            {/*  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%2812%29.png"*/}
            {/*  alt=""*/}
            {/*/>*/}
            {group.type === "CHAT" ? (
              <Icon fontSize={20} name="person" />
            ) : (
              <Icon fontSize={20} name="tag" />
            )}
            <div className="msg-detail">
              <div className="msg-username mb-0">{group.name}</div>
            </div>
          </div>
        ))}
      </div>
      {/*<div className="msg online active">*/}
      {/*  <img*/}
      {/*    className="msg-profile"*/}
      {/*    src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%282%29.png"*/}
      {/*    alt=""*/}
      {/*  />*/}
      {/*  <div className="msg-detail">*/}
      {/*    <div className="msg-username">Lea Debere</div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <button
        className="btn btn-dark btn-sm mt-3 d-flex align-items-center justify-content-center"
        onClick={toggleNewGroup}
      >
        <Icon
          name="add"
          style={{ fontSize: 20, color: "#fff", fontWeight: "bold" }}
        />{" "}
        Chat
      </button>

      {newGroup && (
        <ModalPopup handleClose={toggleNewGroup} title="New Chat">
          <ChatUsersSelector onSelect={createGroup} />
        </ModalPopup>
      )}
    </>
  );
}

const actions = {
  createChat: createChatGroup,
};

export default connect(null, actions)(NewChatsList);
