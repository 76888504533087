import { useEffect, useState } from "react";
import TaskNodes from "./TaskNodes";
import TaskForm from "./TaskForm";
import TaskView from "./TaskView";
import { groupTasks } from "./utils";

function TasksView({ groupId, tasks, addTask }) {
  const [newTask, setNewTask] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);

  useEffect(() => {
    if (tasks && tasks.length >= 1) {
      setTaskToEdit(tasks[0]);
    }
  }, [tasks]);

  const toggleNewTask = () => {
    setNewTask(!newTask);
  };

  const startEditTask = (task = -1) => {
    setTaskToEdit(task);
  };

  const createNewTask = (task) => {
    addTask(groupId, {
      ...task,
    });
    toggleNewTask();
  };

  const getColor = (key) => {
    if (key === "DUE_EARLIER") {
      return "text-danger";
    }
    if (key === "TODAY") {
      return "text-primary";
    }
    // if (key === "THIS_WEEK") {
    //   return "text-info";
    // }
    if (key === "LATER") {
      return "text-success";
    }
    return "";
  };

  tasks = groupTasks(tasks);

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <h3>Tasks</h3>

        <div>
          <button
            className="btn btn-dark btn-sm"
            onClick={(e) => {
              e.preventDefault();
              toggleNewTask();
            }}
          >
            {newTask ? "View Tasks" : "New Task"}
          </button>
        </div>
      </div>

      <div className="row">
        <div className={`col-md-6 ${taskToEdit ? "" : "offset-3"}`}>
          {!!newTask ? (
            <div className="white-box p-3">
              <div className="dfjbac mb-2">
                <h3>New Task</h3>
                <button
                  className="btn btn-white btn-sm"
                  onClick={toggleNewTask}
                >
                  Cancel
                </button>
              </div>
              <TaskForm groupId={groupId} submitTask={createNewTask} />
            </div>
          ) : (
            <div>
              {tasks.length === 0 ? (
                <div className="text-center">There are no tasks.</div>
              ) : (
                <div className="feed-activity-list">
                  {/*<TaskNodes*/}
                  {/*  groupId={groupId}*/}
                  {/*  tasks={tasks}*/}
                  {/*  startEdit={startEditTask}*/}
                  {/*/>*/}

                  {Object.keys(tasks).map((key) => {
                    return (
                      <div>
                        <div
                          className={`text-capitalize mb-2 ${getColor(key)}`}
                          // style={{ fontWeight: 500 }}
                        >
                          {key.replaceAll("_", " ").toLowerCase()}
                        </div>
                        {tasks[key].length >= 1 ? (
                          <div>
                            <TaskNodes
                              groupId={groupId}
                              tasks={tasks[key]}
                              startEdit={startEditTask}
                            />
                          </div>
                        ) : (
                          <div>No tasks</div>
                        )}
                        <div
                          style={{
                            borderBottom: "1px solid #0000001d",
                            marginBottom: 10,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        {taskToEdit && (
          <div className="col-md-6  ml-0">
            <div className="white-box mt-3">
              <TaskView
                taskId={taskToEdit._id}
                groupId={groupId}
                startEdit={startEditTask}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TasksView;
