import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import * as React from "react";
import { useState } from "react";
import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ContentEditable from "./ui/ContentEditable";
import EditorPlugins from "./EditorPlugins";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";

export default function Editor({
  commentsEnabled,
  placeholder = "Enter text here...",
  onChange,
  editorType,
  editorStyle,
  setCommentsPanelOpen,
  editorRef,
}) {
  const { historyState } = useSharedHistoryContext();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <>
      <ToolbarPlugin
        setIsLinkEditMode={setIsLinkEditMode}
        editorType={editorType}
      />

      <div className={`editor-container`}>
        <RichTextPlugin
          contentEditable={
            <div className="editor-scroller" style={editorStyle}>
              <div className="editor" ref={onRef}>
                <ContentEditable
                  placeholder={placeholder}
                  style={editorStyle}
                />
              </div>
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <EditorRefPlugin editorRef={editorRef} />
        <EditorPlugins
          onChange={onChange}
          commentsEnabled={commentsEnabled}
          floatingAnchorElem={floatingAnchorElem}
          historyState={historyState}
          isEditable={true}
          isLinkEditMode={isLinkEditMode}
          setIsLinkEditMode={setIsLinkEditMode}
          setCommentsPanelOpen={setCommentsPanelOpen}
        />
      </div>
    </>
  );
}
