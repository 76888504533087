import React, { useState } from "react";

function OrgForm({ submit }) {
  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const onSubmit = async (e) => {
    e.preventDefault();
    submit({ name, description });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <form role="form" onSubmit={onSubmit}>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          value={name}
          placeholder="Name"
          onChange={handleNameChange}
          style={{ height: "40px" }}
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <input
          type="text"
          className="form-control"
          placeholder="Description"
          value={description}
          onChange={handleDescriptionChange}
          style={{ height: "40px" }}
        />
      </div>
      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-end">
          <button className="btn btn-dark pull-right" type="submit">
            <strong>Update</strong>
          </button>
        </div>
      </div>
      <hr />
      Add Members
      <hr />
      Credits
      <div>
        todo: a way for orgs to add credits and probably use it for things AI
        etc.
      </div>
    </form>
  );
}

export default OrgForm;
