export function getLanguageOptions() {
  return [
    {
      label: "Javascript",
      value: "javascript",
    },
    {
      label: "Java",
      value: "text/x-java",
    },
    {
      label: "Python",
      value: "python",
    },
    {
      label: "SQL",
      value: "text/x-sql",
    },
    { label: "JSX", value: "text/jsx" },
    { label: "HTML", value: "text/html" },
  ];
}

export function isMatch(pwd1, pwd2) {
  return pwd1 === pwd2;
}

export function charLength(pwd) {
  return pwd.length >= 8;
}

export function lowerCase(pwd) {
  for (let i = 0; i < pwd.length; i++) {
    return (
      isNaN(pwd.charAt(i) * 1) && pwd.charAt(i) === pwd.charAt(i).toLowerCase()
    );
  }
}

export function upperCase(pwd) {
  for (let i = 0; i < pwd.length; i++) {
    if (
      !specialCharacters(pwd) &&
      isNaN(pwd.charAt(i) * 1) &&
      pwd.charAt(i) === pwd.charAt(i).toUpperCase()
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export function specialCharacters(pwd) {
  let format = /!@#$%^&*()_+-=\[]{};':"\\|,.<>\/?]+/;

  return format.test(pwd);
}

export function isValidHttpUrl(str) {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
}

export function getRandomNumericString() {
  return "" + new Date().getTime();
}

export function getCodeLanguageLabel(language) {
  let options = getLanguageOptions();
  let langObject = language
    ? options[options.findIndex((o) => o.value === language)]
    : null;
  return langObject ? langObject.label : "";
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
export function getNameInitials(name) {
  return name[0].toUpperCase() + name[1].toUpperCase();
}
