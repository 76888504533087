import {
  getAccomplishments,
  getTodaysItems,
} from "../../services/ContentService";
import FeedContent from "./FeedContent";
import { useEffect, useState } from "react";

const Accomplishments = () => {
  const [summary, setSummary] = useState("");
  let loaded = false;

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = async () => {
    if (!loaded) {
      loaded = true;
      let summary = await getAccomplishments();
      summary = summary.replace("```html", "");
      summary = summary.replace("```", "");
      setSummary(summary);
    }
  };

  return (
    <div className="row">
      <div className="my-3">
        <h3 className="text-center">Your accomplishments yesterday</h3>
      </div>
      {!summary ? (
        <div>...loading</div>
      ) : (
        <div className="col-md-12 col-sm-12">
          <div dangerouslySetInnerHTML={{ __html: summary }} />
        </div>
      )}
    </div>
  );
};

export default Accomplishments;
