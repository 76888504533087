import React from "react";
import { format } from "date-fns";
import Calendar from "./Calendar";
import ModalPopup from "../../modal/ModalPopup";
import EventForm from "./EventForm";

class CalendarView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectEvent = (event) => {
    this.setState({ selectedEvent: event });
  };

  onSelectSlot = ({ start, end }) => {
    this.setState({ newEvent: { start, end } });
  };

  cancelNewEvent = () => {
    this.setState({ newEvent: null });
  };

  cancelSelectedEvent = () => {
    this.setState({ selectedEvent: null });
  };

  addEvent = (data) => {
    let { newEvent } = this.state;
    this.props.addEvent({ ...data, start: newEvent.start, end: newEvent.end });
    this.cancelNewEvent();
  };

  updateEvent = (data) => {
    this.props.updateEvent({ ...data });
    this.cancelSelectedEvent();
  };

  getEventStr = (event) => {
    let eventStr = "";
    let { start, end } = event;
    try {
      eventStr =
        format(start, "dd/MM/yyyy HH:mm") + " - " + format(end, "HH:mm");
    } catch (e) {
      console.log(e);
    }

    return eventStr;
  };

  render() {
    let { newEvent, selectedEvent } = this.state;

    return (
      <div style={{ padding: "0 30px" }}>
        <Calendar
          events={this.props.events}
          onSelectSlot={this.onSelectSlot}
          onSelectEvent={this.onSelectEvent}
        />
        {!!newEvent && (
          <ModalPopup
            title={this.getEventStr(newEvent)}
            handleClose={this.cancelNewEvent}
          >
            <EventForm submit={this.addEvent} create />
          </ModalPopup>
        )}

        {!!selectedEvent && (
          <ModalPopup
            title={this.getEventStr(selectedEvent)}
            handleClose={this.cancelSelectedEvent}
          >
            <EventForm event={selectedEvent} submit={this.updateEvent} edit />
          </ModalPopup>
        )}
      </div>
    );
  }
}

export default CalendarView;
