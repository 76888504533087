import { useState } from "react";
import Icon from "../../icons/Icon";
import QuestionEditor from "./QuestionEditor";
import QuestionView from "./QuestionView";
import {
  doAddQuestion,
  doDeleteQuestion,
  doUpdateQuestion,
} from "../../../state/actions/question";
import { connect } from "react-redux";

const QuestionsView = ({
  groupId,
  questions = [],
  addQuestion,
  updateQuestion,
  deleteQuestion,
}) => {
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [questionIdToEdit, setQuestionIdToEdit] = useState(false);

  const toggleShowAddQuestion = () => {
    setShowAddQuestion(!showAddQuestion);
  };

  const submitAdd = (data) => {
    addQuestion(groupId, data);
    setShowAddQuestion(false);
  };

  const submitUpdate = (question) => {
    updateQuestion(groupId, questionIdToEdit, { ...question });
    setQuestionIdToEdit(null);
  };

  const uniqueQuestions = Array.from(new Set(questions.map((q) => q._id))).map(
      (id) => questions.find((q) => q._id === id)
  );

  return (
    <div className="col-md-8 offset-md-2">
      <div className={`float-e-margins`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Questions</h3>

          <div>
            <button
              className="btn btn-dark btn-sm"
              onClick={(e) => {
                e.preventDefault();
                toggleShowAddQuestion();
              }}
            >
              {showAddQuestion ? (
                <Icon name="close" style={{ fontSize: 20, fontWeight: 500 }} />
              ) : (
                "New Question"
              )}
            </button>
          </div>
        </div>

        <div>
          {showAddQuestion && (
            <div className="white-box mb-2">
              <QuestionEditor
                groupId={groupId}
                submit={submitAdd}
                cancel={toggleShowAddQuestion}
                newContent
              />
            </div>
          )}

          {uniqueQuestions.length === 0 && !addQuestion && (
            <div className="text-center">
              There are no question snippets at the moment
            </div>
          )}

          {uniqueQuestions.map((question) => {
            return (
              <div className={`float-e-margins mb-3`} key={question._id}>
                {questionIdToEdit && questionIdToEdit === question._id ? (
                  <div className="white-box">
                    <QuestionEditor
                      groupId={groupId}
                      question={question}
                      submit={submitUpdate}
                      cancel={() => setQuestionIdToEdit(null)}
                    />
                  </div>
                ) : (
                  <QuestionView
                    question={question}
                    groupId={groupId}
                    startEdit={() => {
                      setQuestionIdToEdit(question._id);
                    }}
                    startDelete={() => deleteQuestion(groupId, question._id)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const actions = {
  addQuestion: doAddQuestion,
  updateQuestion: doUpdateQuestion,
  deleteQuestion: doDeleteQuestion,
};

export default connect(null, actions)(QuestionsView);
