import React from "react";
import ActivitiesView from "./ActivitiesView";
import { getGroupActivities } from "../../services/GroupService";

class ActivitiesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchActivities();
  }

  fetchActivities = async () => {
    const activities = await getGroupActivities(this.props.groupId);
    this.setState({ activities });
  };

  render() {
    let { activities = [] } = this.state;
    return <ActivitiesView activities={activities} />;
  }
}

export default ActivitiesContainer;
