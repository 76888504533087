import {
  ADD_FEED,
  ADD_SUB_CONTENT,
  EDIT_CONTENT,
  LOAD_FEEDS,
  LOAD_SUB_CONTENTS,
  REMOVE_SUB_CONTENT,
  RESET_FEEDS,
} from "../actionTypes";

const initialState = {
  items: [],
  loaded: {},
};

function feedsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FEEDS: {
      return initialState;
    }

    case LOAD_FEEDS: {
      const { feeds = [] } = action.payload;
      let { items } = state;
      items = items.concat(feeds);
      return {
        ...state,
        items,
        loaded: true,
      };
    }

    case ADD_FEED: {
      let { feed } = action.payload;
      let { items } = state;
      items = [feed, ...items];
      return {
        ...state,
        items,
      };
    }

    case EDIT_CONTENT: {
      let { itemId, content } = action.payload;
      let { items } = state;
      let index = items.findIndex((i) => i._id === itemId);
      if (index !== -1) {
        items = [
          ...items.slice(0, index),
          { ...content },
          ...items.slice(index + 1),
        ];
      }
      return {
        ...state,
        items,
      };
    }

    case LOAD_SUB_CONTENTS: {
      const { items: contents = [], contentId, subtype } = action.payload;

      let { items } = state;
      if (contentId) {
        let index = items.findIndex((i) => i._id === contentId);
        if (index !== -1 && subtype) {
          items = [
            ...items.slice(0, index),
            { ...items[index], [subtype]: contents },
            ...items.slice(index + 1),
          ];
          return {
            ...state,
            items,
          };
        }
      }

      return state;
    }

    case ADD_SUB_CONTENT: {
      let { item, contentId, subtype } = action.payload;
      let { items } = state;
      if (contentId && subtype) {
        const index = items.findIndex((i) => i._id === contentId);
        if (index !== -1) {
          let subContents = items[index][subtype] || [];
          items = [
            ...items.slice(0, index),
            { ...items[index], [subtype]: [item, ...subContents] },
            ...items.slice(index + 1),
          ];

          return {
            ...state,
            items,
          };
        }
      }

      return state;
    }

    case REMOVE_SUB_CONTENT: {
      let { contentId, subtype, subContentId } = action.payload;
      let { items } = state;
      if (contentId && subtype) {
        const index = items.findIndex((i) => i._id === contentId);
        if (index !== -1) {
          let subContents = items[index][subtype] || [];
          let subIndex = subContents.findIndex((sc) => sc._id === subContentId);
          if (subIndex !== -1) {
            items = [
              ...items.slice(0, index),
              {
                ...items[index],
                [subtype]: [
                  ...subContents.slice(0, subIndex),
                  ...subContents.slice(index + 1),
                ],
              },
              ...items.slice(index + 1),
            ];
            return {
              ...state,
              items,
            };
          }
        }
      }

      return state;
    }

    default:
      return state;
  }
}

export default feedsReducer;
