import React, { Component } from "react";
import { deleteFile, updateFile } from "../../../services/FileService";
import Icon from "../../icons/Icon";
import { connect } from "react-redux";
import { toastMessage } from "../../../state/actions/toaster";
import DeleteIcon from "../../icons/DeleteIcon";
import ModalPopup from "../../modal/ModalPopup";
import DeleteConfirmation from "../../widgets/DeleteConfirmation";
import EditableGroupNameField from "../../widgets/EditableGroupNameField";

class FileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      showDelete: false,
    };
  }

  componentDidMount() {
    this.removeExtensionFromFileName(this.props.file.name);
  }

  removeExtensionFromFileName = (fileName) => {
    let nameArray = fileName.split("");
    let dotIndex = nameArray.reverse().indexOf(".");
    dotIndex = fileName.length - dotIndex;
    let extension = fileName.slice(dotIndex);
    this.setState({ extension, fileName: fileName.slice(0, dotIndex - 1) });
    // return fileName.slice(0, dotIndex - 1);
  };

  getFileViewer = () => {
    let { file, fileURL } = this.props;
    let type = file.meta.mimetype;
    if (type === "image/jpeg" || type === "image/png") {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <img src={fileURL} />
        </div>
      );
    } else if (type.indexOf("video") !== -1) {
      return (
        <video
          style={{
            height: "calc(100vh - 180px)",
            // width: "100%"
          }}
          controls
          preload="none"
          autoPlay
        >
          <source src={fileURL} />
          Sorry, your browser doesn't support embedded videos.
        </video>
      );
    } else if (type.indexOf("audio") !== -1) {
      return (
        <audio
          controls
          src={fileURL}
          preload="none"
          autoPlay
          style={{
            height: "100px",
            // width: "100%"
          }}
        >
          Sorry, your browser doesn't support embedded videos.
        </audio>
      );
    } else {
      return (
        <embed
          src={fileURL}
          style={{
            height: "calc(100vh - 160px)",
            width: "100%",
          }}
        />
      );
    }
  };

  onChangeName = (e) => {
    this.setState({ fileName: e.target.value });
  };

  toggleDelete = () => {
    let { showDelete } = this.state;
    this.setState({ showDelete: !showDelete });
  };

  deleteFile = () => {
    let { file } = this.props;
    deleteFile(file.list, file._id, (response) => {
      if (response) {
        this.props.handleClose();
      }
    });
  };

  submit = (fileName) => {
    let { extension } = this.state;
    let { file } = this.props;
    if (fileName && fileName.length >= 1) {
      updateFile(file.list, file._id, {
        name: fileName + "." + extension,
      }).then((response) => {
        if (response) {
          this.setState({ edit: false });
          this.props.toastMessage("File name updated", "success", 3);
        }
      });
    } else {
      this.props.toastMessage("Please enter File name", "error", 3);
    }
  };

  render() {
    let { file, embedded } = this.props;
    let { edit, fileName, extension, showDelete } = this.state;

    if (!file) {
      return null;
    }

    return (
      <div className={`${!embedded ? "ibox float-e-margins " : ""} m-b-xs`}>
        <div className={!embedded ? "ibox-content" : ""}>
          <div className="d-flex align-items-center justify-content-between m-b-sm">
            <EditableGroupNameField
              name={fileName}
              onChangeName={this.submit}
            />

            <br />

            <div>
              <small>
                Added On: {new Date(file.dateCreated).toLocaleString()}
              </small>
            </div>
            <div>
              <a onClick={this.toggleDelete} className="text-danger">
                <DeleteIcon style={{ fontSize: 18 }} className="mr-h" />
              </a>
              <a href={this.props.fileURL} download>
                <Icon
                  style={{ fontWeight: "bold", fontSize: 20 }}
                  className="mr-2"
                  name="file_download"
                />
              </a>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {this.getFileViewer()}
          </div>
        </div>
        {showDelete && (
          <ModalPopup
            title={"Delete Item"}
            size="lg"
            handleClose={this.toggleDelete}
          >
            <DeleteConfirmation
              handleClose={this.toggleDelete}
              delete={this.deleteFile}
              type="file"
            />
          </ModalPopup>
        )}
      </div>
    );
  }
}

const actions = {
  toastMessage: toastMessage,
};

export default connect(null, actions)(FileViewer);
