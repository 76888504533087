import Icon from "../icons/Icon";

export default function ImagePreview({ src, remove, onClick }) {
  return (
    <div
      style={{
        border: "1px solid #e2e2e2",
        borderRadius: 4,
        padding: 3,
        position: "relative",
      }}
    >
      {remove && (
        <Icon
          className="btn-dark"
          name="close"
          style={{
            fontSize: 12,
            position: "absolute",
            top: -7,
            right: -7,
            border: "1px solid #212529",
            borderRadius: "100%",
            cursor: "pointer",
          }}
          onClick={remove}
        />
      )}
      <img
        src={src}
        alt=""
        height={40}
        onClick={onClick ? onClick : () => {}}
      />
    </div>
  );
}
