import React, { Component, useState } from "react";
import { signUp } from "../../services/DataService";
import Error from "./../alerts/Error";
import { Link, useNavigate } from "react-router-dom";

export default function SignUp({}) {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const submit = (e) => {
    e.preventDefault();
    setError("");
    signUp(this.state, (response) => {
      if (response.success) {
        navigate("/signup/confirmation");
        setUser({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      } else {
        setError(response.message);
      }
    });
  };

  const handleNameChange = (e) => {
    setUser({ ...user, name: e.target.value });
  };

  const handleEmailChange = (e) => {
    setUser({ ...user, email: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setUser({ ...user, password: e.target.value });
  };

  const handleConfirmPasswordChange = (e) => {
    setUser({ ...user, confirmPassword: e.target.value });
  };

  const inputStyle = { borderWidth: "1px", height: "40px" };

  return (
    <div className="loginColumns animated fadeInDown">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="text-center">
            <img src="/img/logo/logo.png" style={{ height: 42 }} />
          </div>

          <div
            className="ibox-content"
            style={{ padding: "30px", marginTop: "30px", borderRadius: 4 }}
          >
            <h4 className="font-bold text-center">Sign Up</h4>
            <Error>{error}</Error>

            <form className="m-t" noValidate onSubmit={submit}>
              <div className="form-group">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Name"
                  value={user.name}
                  onChange={handleNameChange}
                  style={inputStyle}
                />
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={user.email}
                  onChange={handleEmailChange}
                  style={inputStyle}
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={user.password}
                  onChange={handlePasswordChange}
                  style={inputStyle}
                />
              </div>

              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  value={user.confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  style={inputStyle}
                />
              </div>
              <button
                className="btn btn-primary block full-width m-b"
                type="submit"
              >
                <strong>Sign Up</strong>
              </button>
              <p className="text-muted text-center" />
              <Link
                className="btn btn-sm btn-white btn-block full-width"
                style={{ marginTop: "30px" }}
                to="/signin"
              >
                Existing User? Sign In
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
