import React, { createContext, useContext, useEffect } from "react";
import { connectToNamespace, disconnectFromNamespace } from "./Socket";
import { useDispatch } from "react-redux";
import {
  ADD_CHAT_MESSAGE,
  ADD_CONTENT,
  EDIT_CHAT_MESSAGE,
  REMOVE_CHAT_MESSAGE,
  REMOVE_CONTENT,
} from "../state/actionTypes";

const NAMESPACES = ["tasks", "files", "docs", "media-posts", "questions"];

const ChatContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const socket = connectToNamespace("chats");

  useEffect(() => {
    const namespaceConnections = {};

    NAMESPACES.forEach((namespace) => {
      const socket = connectToNamespace(namespace);
      namespaceConnections[namespace] = socket;
      const evt = namespace.slice(0, -1);

      // create event
      socket.on(`${evt}:create`, (data) => {
        console.log("create event", data);
        dispatch({
          type: ADD_CONTENT,
          payload: {
            group: data.group,
            item: data.item,
            type: data.contentType,
          },
        });
      });

      // delete event
      socket.on(`${evt}:delete`, (data) => {
        console.log("delete event", data);
        dispatch({
          type: REMOVE_CONTENT,
          payload: {
            itemId: data.contentId,
            type: data.contentType,
          },
        });
      });
    });

    socket.on("chat:add-message", (data) => {
      dispatch({
        type: ADD_CHAT_MESSAGE,
        payload: {
          groupId: data.group,
          message: data,
        },
      });
    });

    socket.on("chat:edit-message", (data) => {
      dispatch({
        type: EDIT_CHAT_MESSAGE,
        payload: {
          groupId: data.group,
          messageId: data.contentId,
          content: data.content,
        },
      });
    });

    socket.on("chat:delete-message", (data) => {
      dispatch({
        type: REMOVE_CHAT_MESSAGE,
        payload: {
          groupId: data.group,
          itemId: data.contentId,
        },
      });
    });

    return () => {
      NAMESPACES.forEach((namespace) => {
        const socket = namespaceConnections[namespace];
        const evt = namespace.slice(0, -1);
        socket.off(`${evt}:create`);
        socket.off(`${evt}:delete`);
        disconnectFromNamespace(namespace);
      });
    };
  }, [dispatch, socket]);

  return <ChatContext.Provider value={socket}>{children}</ChatContext.Provider>;
};

export default SocketProvider;
export const useChat = () => useContext(ChatContext);
