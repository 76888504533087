import React, { useEffect, useState } from "react";
import { getGroups } from "../../services/GroupService";
import { getFile } from "../../services/FileService";
import ModalPopup from "../modal/ModalPopup";
import FileViewerInline from "../content/files/FileViewerInline";
import FeedView from "./FeedView";
import TaskViewModal from "../content/tasks/TaskViewModal";
import Today from "./Today";
import Accomplishments from "./Accomplishments";

const FeedsView = ({ feeds = [] }) => {
  const [groups, setGroups] = useState([]);
  const [fileToShow, setFileToShow] = useState(null);
  const [taskToView, setTaskToView] = useState(null);
  const [tab, setTab] = useState("all");

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    const groups = await getGroups("REGULAR");
    setGroups(groups);
  };

  const openFile = async (file) => {
    const fileURL = await getFile(file.group, file._id);
    setFileToShow({ fileURL, file });
  };

  const closeFile = () => setFileToShow(null);

  const getGroupName = (id) => {
    const filtered = groups.filter((group) => group._id === id);
    if (filtered && filtered[0]) {
      return filtered[0].name;
    }
    return "";
  };

  const startViewTask = (task = -1) => setTaskToView(task);
  const cancelTaskView = () => setTaskToView(null);

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-8">
        <div className="d-flex justify-content-center mb-3">
          <div data-toggle="buttons-checkbox" className="btn-group">
            <button
              className={`btn btn-xs ${tab === "all" ? "btn-outline-primary active" : "btn-outline-secondary"}`}
              type="button"
              style={{ width: 100 }}
              onClick={() => setTab("all")}
            >
              <i className="fa fa-bold"></i> All
            </button>
            <button
              className={`btn btn-xs ${tab === "today" ? "btn-outline-primary active" : "btn-outline-secondary"}`}
              type="button"
              style={{ width: 120 }}
              onClick={() => setTab("today")}
            >
              <i className="fa fa-underline"></i> Today
            </button>
            <button
              className={`btn btn-xs ${tab === "yesterday" ? "btn-outline-primary active" : "btn-outline-secondary"}`}
              type="button"
              style={{ width: 140 }}
              onClick={() => setTab("yesterday")}
            >
              <i className="fa fa-italic"></i> Yesterday
            </button>
          </div>
        </div>
        {tab === "all" && (
          <div className="row">
            <div className="my-3">
              <h3 className="text-center">What's happening in your teams</h3>
            </div>
            <div className="p-1">
              {feeds.map((feed, index) => {
                return (
                  <FeedView
                    key={feed._id + "_" + index}
                    feed={feed}
                    groupName={getGroupName(feed.group).toUpperCase()}
                    openFile={openFile}
                    openTask={() => startViewTask(feed)}
                  />
                );
              })}
            </div>
          </div>
        )}
        {tab === "today" && <Today />}
        {tab === "yesterday" && <Accomplishments />}
      </div>
      {fileToShow && (
        <ModalPopup show={true} size="xl" handleClose={closeFile}>
          <FileViewerInline
            file={fileToShow.file}
            fileURL={fileToShow.fileURL}
            groupId={fileToShow.file.group}
            close={closeFile}
          />
        </ModalPopup>
      )}

      {taskToView != null && (
        <TaskViewModal
          task={taskToView}
          groupId={taskToView.group}
          toggleEditTask={cancelTaskView}
        />
      )}
    </div>
  );
};

export default FeedsView;
