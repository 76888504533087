import React, { Component } from "react";
import {
  fetchGroup,
  toggleAllTypesView,
  toggleCalendar,
  toggleChat,
  toggleTypeView,
} from "../../state/actions/group";
import { connect } from "react-redux";
import CalendarInSidebar from "../content/calendar/CalendarInSidebar";
import ActivitiesInSidebar from "../activities/ActivitiesInSidebar";
import { Outlet } from "react-router-dom";
import GroupActions from "./GroupActions";

class GroupView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { group } = this.props;
    if (!group) {
      return null;
    }

    return (
      <div style={{ width: "100%" }}>
        <div className="row no-gutters">
          <div className="col-lg-12">
            <GroupActions group={group} />
          </div>
        </div>

        <div className="d-flex flex-column mt-3 ">
          <CalendarInSidebar />
          <ActivitiesInSidebar />

          <div className="row no-gutters">
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
}

const data = (state) => {
  return {
    calendarOpen: state.group.calendarOpen,
    group: state.group.groupData,
  };
};

const actions = {
  fetchGroup,
  toggleTypeView,
  toggleAllTypesView,
  toggleCalendar,
  toggleChat,
};

export default connect(data, actions)(GroupView);
