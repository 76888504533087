import React from "react";

const Avatar = ({ text, size, className }) => {
  const colors = [
    "#e895a7",
    "#615088",
    "#9C528B",
    "#4E937A",
    "#55557e",
    "#809fcc",
    "#9d974b",
  ];

  const getBgColor = (text) => {
    if (!text) {
      return "#bec2c8";
    }
    const first = text.toLowerCase().charCodeAt(0);
    const a = "a".charCodeAt(0);
    const val = (first - a) % 7;
    return colors[val];
  };

  const width = size === "sm" ? "30px" : "40px";
  const height = size === "sm" ? "30px" : "40px";

  return (
    <div
      className={`avatar ${className ? className : ""}`}
      style={{ backgroundColor: getBgColor(text), width, height }}
    >
      {text}
    </div>
  );
};

export default Avatar;
