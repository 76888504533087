import React from "react";
import {
  changePassword,
  editBasicDetails,
  editStoredUserData,
  getUser,
} from "../../services/DataService";
import TopNav from "./TopNav";
import { toastMessage } from "../../state/actions/toaster";
import {
  charLength,
  isMatch,
  lowerCase,
  specialCharacters,
  upperCase,
} from "../../utils/Utils";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let user = getUser();
    this.setState({
      name: user.name,
      email: user.email,
      _id: user._id,
    });
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  //   onChangePhone = e => {
  //     this.setState({ phone: e.target.value });
  //   };

  editBasicDetails = (e) => {
    e.preventDefault();
    let { name, email, _id } = this.state;
    if (!name || name.length === 0) {
      this.props.toastMessage("Please fill your name", "error", 3);
      return;
    } else if (!email || email.length === 0) {
      this.props.toastMessage("Please fill your email", "error", 3);
      return;
    } else {
      editBasicDetails({ name, email, _id }, (response) => {
        if (response.success) {
          editStoredUserData(name, email);
          this.props.toastMessage("Details updated successfully", "success", 3);
        }
      });
    }
  };

  onChangeCurrentPassword = (e) => {
    this.setState({ currentPassword: e.target.value });
  };

  onChangeNewPassword = (e) => {
    this.setState({ newPassword: e.target.value });
  };

  onChangeConfirmNewPassword = (e) => {
    this.setState({ confirmNewPassword: e.target.value });
  };

  changePassword = (e) => {
    e.preventDefault();
    let { currentPassword, newPassword, confirmNewPassword, _id, email } =
      this.state;
    if (!currentPassword || currentPassword.length === 0) {
      this.props.toastMessage("Please fill your current password", "error", 3);
      return;
    } else if (!newPassword || newPassword.length === 0) {
      this.props.toastMessage("Please fill your new password", "error", 3);
      return;
    } else if (!confirmNewPassword || confirmNewPassword.length === 0) {
      this.props.toastMessage(
        "Please confirm your new current password",
        "error",
        3,
      );
      return;
    } else {
      //   let one = isMatch(newPassword, confirmNewPassword);
      //   let two = charLength(newPassword);
      //   let three = lowerCase(newPassword);
      //   let four = upperCase(newPassword);
      //   let five = specialCharacters(newPassword);

      if (isMatch(newPassword, confirmNewPassword)) {
        if (charLength(newPassword)) {
          if (lowerCase(newPassword)) {
            if (upperCase(newPassword)) {
              if (specialCharacters(newPassword)) {
                changePassword(
                  {
                    currentPassword,
                    newPassword,
                    confirmNewPassword,
                    _id,
                    email,
                  },
                  (response) => {
                    if (response.success) {
                      this.props.toastMessage(
                        "Password Changed Successfully",
                        "success",
                        3,
                      );
                    } else {
                      this.props.toastMessage(response.message, "error", 3);
                    }
                  },
                );
              } else {
                this.setState({
                  specialCharacters: true,
                  upperCase: false,
                  lowerCase: false,
                  charLength: false,
                  isMatch: false,
                });
                return;
              }
            } else {
              this.setState({
                upperCase: true,
                specialCharacters: false,
                lowerCase: false,
                charLength: false,
                isMatch: false,
              });
              return;
            }
          } else {
            this.setState({
              lowerCase: true,
              specialCharacters: false,
              upperCase: false,
              charLength: false,
              isMatch: false,
            });
            return;
          }
        } else {
          this.setState({
            charLength: true,
            specialCharacters: false,
            upperCase: false,
            lowerCase: false,
            isMatch: false,
          });
          return;
        }
      } else {
        this.setState({
          isMatch: true,
          specialCharacters: false,
          upperCase: false,
          lowerCase: false,
          charLength: false,
        });
        return;
      }
    }
  };
  render() {
    let {
      name,
      email,
      phone,
      isMatch,
      charLength,
      lowerCase,
      upperCase,
      specialCharacters,
    } = this.state;
    return (
      <div style={{ margin: 0, height: "100vh" }}>
        <TopNav />
        <div style={{ backgroundColor: "white" }} className="pt-5">
          <div className="d-flex align-items-center justify-content-center">
            <div
              style={{
                boxShadow:
                  "0 0.375rem 0.75rem rgb(140 152 164 / 8%) !important",
                border: ".0625rem solid rgba(231,234,243,.7) !important",
                borderRadius: "0.75rem !important",
              }}
              className="card col-lg-6"
            >
              <div style={{ backgroundColor: "unset" }} className="card-header">
                <h3 className="card-title">Basic information</h3>
              </div>

              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    this.editBasicDetails(e);
                  }}
                >
                  <div className="row mb-4">
                    <label
                      for="firstNameLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Full name
                      <i
                        className="bi-question-circle text-body ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Displayed on public forums, such as Front."
                        aria-label="Displayed on public forums, such as Front."
                      ></i>
                    </label>

                    <div className="col-sm-9">
                      <div className="input-group input-group-sm-vertical">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          id="firstNameLabel"
                          placeholder="Your first name"
                          aria-label="Your first name"
                          onChange={(e) => this.onChangeName(e)}
                          value={name ? name : ""}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      for="emailLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Email
                    </label>

                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="emailLabel"
                        placeholder="Email"
                        aria-label="Email"
                        onChange={(e) => this.onChangeEmail(e)}
                        value={email ? email : ""}
                      />
                    </div>
                  </div>
                  {/* 
                  <div className="row mb-4">
                    <label
                      for="phoneLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Phone{" "}
                      <span className="form-label-secondary">(Optional)</span>
                    </label>

                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="js-input-mask form-control"
                        name="phone"
                        id="phoneLabel"
                        placeholder="+91"
                        aria-label="+91"
                        value={phone ? phone : ""}
                        onChange={e => this.onChangePhone(e)}
                        data-hs-mask-options='{
                               "mask": "+0(000)000-00-00"
                             }'
                      />
                    </div>
                  </div> */}

                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-center mt-5">
            <div
              id="passwordSection"
              style={{
                boxShadow:
                  "0 0.375rem 0.75rem rgb(140 152 164 / 8%) !important",
                border: ".0625rem solid rgba(231,234,243,.7) !important",
                borderRadius: "0.75rem !important",
              }}
              className="card col-lg-6"
            >
              <div style={{ backgroundColor: "unset" }} className="card-header">
                <h3 className="card-title">Change your password</h3>
              </div>

              <div className="card-body">
                <form
                  id="changePasswordForm"
                  onSubmit={(e) => {
                    this.changePassword(e);
                  }}
                >
                  <div className="row mb-4">
                    <label
                      for="currentPasswordLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Current password
                    </label>

                    <div className="col-sm-9">
                      <input
                        type="password"
                        className="form-control"
                        name="currentPassword"
                        id="currentPasswordLabel"
                        placeholder="Enter current password"
                        aria-label="Enter current password"
                        onChange={(e) => this.onChangeCurrentPassword(e)}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      for="newPassword"
                      className="col-sm-3 col-form-label form-label"
                    >
                      New password
                    </label>

                    <div className="col-sm-9">
                      <input
                        type="password"
                        className="form-control"
                        name="newPassword"
                        id="newPassword"
                        placeholder="Enter new password"
                        aria-label="Enter new password"
                        onChange={(e) => this.onChangeNewPassword(e)}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <label
                      for="confirmNewPasswordLabel"
                      className="col-sm-3 col-form-label form-label"
                    >
                      Re-enter new password
                    </label>

                    <div className="col-sm-9">
                      <div className="mb-3">
                        <input
                          type="password"
                          className="form-control"
                          name="confirmNewPassword"
                          id="confirmNewPasswordLabel"
                          placeholder="Confirm your new password"
                          aria-label="Confirm your new password"
                          onChange={(e) => this.onChangeConfirmNewPassword(e)}
                        />
                        {isMatch && (
                          <p className="text-danger">Password does not match</p>
                        )}
                      </div>

                      <Alert variant="info">
                        <p className="mb-2">Password requirements:</p>

                        <ul>
                          <li className={charLength ? "text-red" : ""}>
                            Minimum 8 characters long - the more, the better
                          </li>
                          <li className={lowerCase ? "text-red" : ""}>
                            At least one lowercase character
                          </li>
                          <li className={upperCase ? "text-red" : ""}>
                            At least one uppercase character
                          </li>
                          <li className={specialCharacters ? "text-red" : ""}>
                            At least one number or symbol
                          </li>
                        </ul>
                      </Alert>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  toastMessage: toastMessage,
};

export default connect(null, actions)(Profile);
