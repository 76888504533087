import "./editor.css";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import * as React from "react";
import { SharedHistoryContext } from "./context/SharedHistoryContext";
import Editor from "./Editor";
import EditorNodes from "./nodes/EditorNodes";
import { TableContext } from "./plugins/TablePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import ContentEditable from "./ui/ContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import EditorPlugins from "./EditorPlugins";
import { useState } from "react";

export default function ViewOnlyLexicalEditor({
  content,
  commentsEnabled,
  setCommentsPanelOpen,
}) {
  const initialConfig = {
    editorState: content,
    namespace: "lexical-editor",
    nodes: [...EditorNodes],
    onError: (error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
    editable: false,
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <div className={`editor-shell`} style={{ border: 0 }}>
            <div className={`editor-container`}>
              <RichTextPlugin
                contentEditable={
                  <div
                    className="editor-scroller"
                    style={{
                      resize: "none",
                      minHeight: "auto",
                    }}
                  >
                    <div className="editor" ref={onRef}>
                      <ContentEditable
                        style={{
                          padding: 0,
                          minHeight: "auto",
                        }}
                      />
                    </div>
                  </div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <EditorPlugins
                commentsEnabled={commentsEnabled}
                setCommentsPanelOpen={setCommentsPanelOpen}
                isEditable={false}
                floatingAnchorElem={floatingAnchorElem}
              />
            </div>
          </div>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}
