import React, { useState } from "react";
import { addContent } from "../../../services/ContentService";

export default ({ groupId, parentTaskId, onCreate }) => {
  const [title, setTitle] = useState("");
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const submit = async (e) => {
    e.preventDefault();
    const task = await addContent(groupId, "task", {
      title,
      parent: parentTaskId,
    });
    onCreate(task.data);
  };

  return (
    <form onSubmit={submit} className="form-horizontal">
      <div className="dfac form-group">
        <div style={{ flex: 4 }}>
          <input
            autoFocus
            type="text"
            className="form-control"
            value={title}
            onChange={handleTitleChange}
            style={{ height: "40px" }}
            placeholder="Enter subtask title here"
          />
        </div>
        <div style={{ flex: 1 }}>
          <button type="submit" className="btn btn-dark ml-2 btn-sm">
            Create
          </button>
        </div>
      </div>
    </form>
  );
};
