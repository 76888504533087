import { combineReducers } from "redux";
import groupReducer from "./GroupReducer";
import toasterReducer from "./ToasterReducer";
import chatReducer from "./ChatReducer";
import uiReducer from "./UIReducer";
import feedsReducer from "./FeedsReducer";

export default combineReducers({
  group: groupReducer,
  toaster: toasterReducer,
  chat: chatReducer,
  ui: uiReducer,
  feeds: feedsReducer,
});
