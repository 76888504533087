import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getGroups } from "../../services/GroupService";
import { addFeed } from "../../state/actions/feed";
import { connect } from "react-redux";
import TaskForm from "../content/tasks/TaskForm";
import QuestionEditor from "../content/question/QuestionEditor";
import PostEditor from "../content/MediaPosts/PostEditor";
import DocEditor from "../content/docs/DocEditor";
import { doAddPost } from "../../state/actions/posts";
import { toastMessage } from "../../state/actions/toaster";
import { getTypeLabel } from "./FeedTitle";
import FilesUploaderInline from "../content/files/FilesUploaderInline";

const typeOptions = [
  { value: "media-post", label: "Post" },
  { value: "task", label: "Task" },
  { value: "doc", label: "Doc" },
  { value: "question", label: "Question" },
  { value: "files", label: "Upload a File" },
];

const getTypeObj = (val) => typeOptions.filter((t) => t.value === val)[0];

const FeedEditor = (props) => {
  const { draftContent } = props;
  const [type, setType] = useState(getTypeObj("media-post"));
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    let groups = await getGroups("REGULAR");
    groups = groups.map((group) => ({
      value: group._id,
      label: group.name,
    }));
    setGroups(groups);
    if (props.groupId) {
      let matched = groups.find((group) => group.value === props.groupId);
      if (matched) {
        setGroup(matched);
      }
    }
  };

  const addFeed = (data) => {
    props.createFeed(type.value, group.value, data).then(() => {
      props.onCreateFeed();
      props.toastMessage(
        `A ${getTypeLabel(type.value)} has been created`,
        "success",
      );
    });
  };

  const getContentForm = () => {
    if (type.value === "media-post") {
      return (
        <PostEditor
          groupId={group ? group.value : null}
          submit={addFeed}
          post={draftContent ? { content: draftContent } : undefined}
          newContent
        />
      );
    }
    if (type.value === "task") {
      return (
        <TaskForm
          groupId={group ? group.value : null}
          submitTask={addFeed}
          task={draftContent ? { description: draftContent } : undefined}
          expanded={!!draftContent}
        />
      );
    }
    if (type.value === "doc") {
      return (
        <DocEditor
          groupId={group ? group.value : null}
          submit={addFeed}
          setCommentsPanelOpen={false}
          editorHeight={400}
          doc={draftContent ? { content: draftContent } : undefined}
          newContent
          noCancel
        />
      );
    }
    if (type.value === "question") {
      return (
        <QuestionEditor
          groupId={group ? group.value : null}
          submit={addFeed}
          question={draftContent ? { content: draftContent } : undefined}
          newContent
          noCancel
        />
      );
    }
    if (type.value === "files") {
      return (
        <FilesUploaderInline
          groupId={group ? group.value : null}
          uploadFiles={addFeed}
          inline
        />
      );
    }
  };

  return (
    <div>
      <div>
        {!props.groupId && (
          <>
            <div>Group</div>
            <div className="mb-3">
              <Select
                options={groups}
                onChange={(value) => setGroup(value)}
                value={group}
                isClearable={false}
              />
            </div>
          </>
        )}

        <div>Type</div>
        <div className="mb-3">
          <Select
            options={typeOptions}
            onChange={(selected) => setType(selected)}
            value={type}
            isClearable={false}
          />
        </div>

        {getContentForm()}
      </div>
    </div>
  );
};

const actions = {
  createFeed: addFeed,
  addPost: doAddPost,
  toastMessage,
};

export default connect(null, actions)(FeedEditor);
