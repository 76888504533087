import React, { Component } from "react";
import { getUser } from "../../../services/DataService";
import "./Chats.css";
import { getGroupMembers } from "../../../services/GroupService";
import ChatMessageView from "./ChatMessageView";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default class ChatContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ currentUser: getUser() });
  }

  showName = (participants = []) => {
    let { group } = this.props;

    if (participants.length > 2) {
      return true;
    } else if (participants.length === 2) {
      return false;
    } else if (participants.length === 0) {
      getGroupMembers(group._id).then((users) => {
        if (users.length > 2) {
          return true;
        } else if (users.length == 2) {
          return false;
        } else if (users.length === 0) {
          return false;
        }
      });
    }
  };

  isDayDifference = (index) => {
    let { messages = [] } = this.props;

    if (messages.length > 1 && index >= 1) {
      let message1 = new Date(messages[index - 1].dateCreated);
      let message2 = new Date(messages[index].dateCreated);
      let d1 = new Date(
        message1.getUTCFullYear(),
        message1.getUTCMonth() + 1,
        message1.getUTCDate(),
      );
      let d2 = new Date(
        message2.getUTCFullYear(),
        message2.getUTCMonth() + 1,
        message2.getUTCDate(),
      );
      if (d2 > d1) {
        return (
          message2.getUTCDate() +
          " " +
          monthNames[message2.getUTCMonth()] +
          " " +
          message2.getUTCFullYear()
        );
      }
    }
  };

  render() {
    let { currentUser } = this.state;
    let { messages = [], group } = this.props;
    if (!currentUser) {
      return null;
    }

    let { participants } = group;
    let showName = this.showName(participants);

    return (
      <>
        {messages.map((message, index) => {
          let isSelf = currentUser._id === message.user.id;
          let isDayDifference = this.isDayDifference(index);
          return (
            <ChatMessageView
              key={message._id + "_" + index}
              message={message}
              isDayDifference={isDayDifference}
              isSelf={isSelf}
              groupId={group._id}
            />
          );
        })}
      </>
    );
  }
}
