import React from "react";

export default class EditableGroupNameField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { name: props.name || "" };
    this.nameRef = React.createRef();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ name: nextProps.name || "" });
  }

  onNameSubmit = (e) => {
    e.preventDefault();
    this.nameRef.current.blur();
  };

  render() {
    let { name } = this.state;
    return (
      <form onSubmit={this.onNameSubmit}>
        <input
          ref={this.nameRef}
          className="form-control board-name"
          value={name}
          title={name}
          onChange={(e) => {
            this.setState({ name: e.target.value });
          }}
          onBlur={() => {
            this.props.onChangeName(this.state.name);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              e.target.blur();
            }
          }}
          style={{ width: "auto", textOverflow: "ellipsis" }}
        />
      </form>
    );
  }
}
