import { SET_UI_PAGE } from "../actionTypes";

const initialState = { currentPage: null };

function toaster(state = initialState, action = null) {
  if (action.type === SET_UI_PAGE) {
    return {
      ...state,
      currentPage: action.payload.page,
    };
  }

  return state;
}
export default toaster;
