import React, { Component } from "react";
import "./home.css";
import { getIcon } from "../groups/GroupIcons";
import Icon from "../icons/Icon";

export default class Features extends Component {
  render() {
    return (
      <div>
        <section
          className="content-section text-center py-4 text-bt"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="container">
            <div className="content-section-heading">
              <h2 className="py-3 mb-3">Features</h2>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("task", 28)}</div>
                  <div className="font-lg">Todo Lists</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("doc", 28)}</div>
                  <div className="font-lg">Notes</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("text", 28)}</div>
                  <div className="font-lg">Text Lists</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>
                    {getIcon("bookmark", 28)}
                  </div>
                  <div className="font-lg">URL Bookmarks</div>
                </div>
              </div>
            </div>
            <div className="py-3" />
            <div className="row my-3">
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("kanban", 28)}</div>
                  <div className="font-lg">Kanban Board</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>
                    {getIcon("question", 28)}
                  </div>
                  <div className="font-lg">Question & Answers</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("code", 28)}</div>
                  <div className="font-lg">Code Snippets</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("drawing", 28)}</div>
                  <div className="font-lg">Drawing Tool</div>
                </div>
              </div>
            </div>

            <div className="py-3" />
            <div className="row my-3">
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("files", 28)}</div>
                  <div className="font-lg">Files</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>
                    <Icon style={{ fontSize: 28 }} name="group" />
                  </div>
                  <div className="font-lg">Groups</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>{getIcon("chat", 28)}</div>
                  <div className="font-lg">Chat</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>
                    {getIcon("media-post", 28)}
                  </div>
                  <div className="font-lg">Media Posts</div>
                </div>
              </div>
            </div>

            <div className="py-3" />
            <div className="row my-3 justify-content-center">
              <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div style={{ marginRight: 5 }}>
                    <Icon style={{ fontSize: 28 }} name="calendar_month" />
                  </div>
                  <div className="font-lg">Calendar</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
