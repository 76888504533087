import React, { Component } from "react";
import Icon from "../../icons/Icon";

export default class FileView extends Component {
  getFileIcon = (type) => {
    if (!type) {
      return <Icon name="description" />;
    }
    if (type.indexOf("image") !== -1) {
      return <Icon fontSize={42} name="image" />;
    } else if (type === "application/pdf") {
      return <Icon fontSize={42} name="picture_as_pdf" />;
    } else if (type.indexOf("video") !== -1) {
      return <Icon fontSize={42} name="video_file" />;
    } else if (type.indexOf("audio") !== -1) {
      return <Icon fontSize={42} name="volume_up" />;
    } else {
      return <Icon fontSize={42} name="description" />;
    }
  };

  render() {
    let file = this.props.file;
    if (!file) {
      return null;
    }

    return (
      <div className="file-box mb-2" style={{ height: 200, width: 300 }}>
        <div className="file">
          <a
            className="link"
            onClick={() => {
              this.props.openFile(file);
            }}
          >
            <span className="corner" />

            <div className="icon">{this.getFileIcon(file.meta.mimetype)}</div>
            <div className="file-name">
              <div className="mb-2 overflow-hidden">{file.name}</div>
              <div className="d-flex justify-content-between align-items-center">
                <small>{new Date(file.dateCreated).toLocaleString()}</small>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
