import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  doDeleteAnswer,
  doUpdateAnswer,
  fetchAnswers,
} from "../../../state/actions/question";
import AnswersView from "./AnswersView";

const AnswersContainer = ({
  groupId,
  question,
  fetchAnswers,
  deleteAnswer,
  updateAnswer,
}) => {
  useEffect(() => {
    fetchAnswers(groupId, question._id);
  }, []);

  const { answer: answers = [] } = question;

  return (
    <AnswersView
      groupId={groupId}
      questionId={question._id}
      answers={answers}
      deleteAnswer={deleteAnswer}
      updateAnswer={updateAnswer}
    />
  );
};

const actions = {
  fetchAnswers: fetchAnswers,
  deleteAnswer: doDeleteAnswer,
  updateAnswer: doUpdateAnswer,
};

export default connect(null, actions)(AnswersContainer);
