import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { createUsingAI } from "../../services/ContentService";
import { Forward } from "lucide-react";
import QuestionAnswer from "./QuestionAnswer";
import { Task } from "./Task";
import FeedEditor from "../feeds/FeedEditor";
import { connect } from "react-redux";
import { toastMessage } from "../../state/actions/toaster";
import { addFeed } from "../../state/actions/feed";
import { getTypeLabel } from "../feeds/FeedTitle";
import Doc from "./Doc";
import MediaPost from "./MediaPost";

function AI({ cancel, toastMessage, doAddFeed }) {
  const { groupId: paramGroupId } = useParams();
  const [groupId, setGroupId] = useState(paramGroupId);
  const [content, setContent] = useState("");
  const [aiResponse, setAiResponse] = useState();
  const [showCreate, setShowCreate] = useState(false);

  const callAI = async () => {
    if (!content) {
      return;
    }
    setAiResponse(null);
    const response = await createUsingAI(groupId, { content });
    setAiResponse(response);
  };

  const showCreation = () => {
    setShowCreate(true);
  };

  const createContent = async (data) => {
    const { type } = aiResponse;
    await doAddFeed(type, groupId, data);
    toastMessage(`A ${getTypeLabel(type)} has been created`, "success");
    setContent("");
    setAiResponse(null);
  };

  const selectGroup = (group) => setGroupId(group);

  const renderResponseView = () => {
    const { type } = aiResponse;
    if (type === "question") {
      return (
        <QuestionAnswer
          groupId={groupId}
          aiResponse={aiResponse}
          addQuestion={createContent}
          selectGroup={paramGroupId ? undefined : selectGroup}
          noCancel
        />
      );
    }
    if (type === "task") {
      return (
        <Task
          groupId={groupId}
          aiResponse={aiResponse}
          createTask={createContent}
          selectGroup={paramGroupId ? undefined : selectGroup}
        />
      );
    }
    if (type === "doc") {
      return (
        <Doc
          groupId={groupId}
          createDoc={createContent}
          aiResponse={aiResponse}
          selectGroup={paramGroupId ? undefined : selectGroup}
          noCancel
        />
      );
    }
    if (type === "media-post") {
      return (
        <MediaPost
          groupId={groupId}
          createPost={createContent}
          aiResponse={aiResponse}
          selectGroup={paramGroupId ? undefined : selectGroup}
        />
      );
    }
  };

  if (showCreate) {
    return (
      <div>
        <div className="d-flex justify-content-end mb-3">
          <a className="link" onClick={() => setShowCreate(false)}>
            Back
          </a>
        </div>
        <div className="white-box">
          <h3>New Content</h3>
          <FeedEditor
            onCreateFeed={cancel}
            draftContent={content}
            groupId={groupId}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="my-3">
        <div className="mb-1">Ask (to do) something</div>
        <div>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="form-control"
            style={{ borderRadius: 4, borderColor: "#ccc" }}
            rows={4}
          />
        </div>
        <div className="d-flex justify-content-end my-3">
          <button className="btn btn-dark btn-sm" onClick={callAI}>
            <Forward />
          </button>
        </div>

        {aiResponse && (
          <div>
            <hr />
            {renderResponseView()}
            <div className="my-3 d-flex justify-content-center">
              <div>
                Not happy,{" "}
                <a className="link" onClick={showCreation}>
                  create
                </a>{" "}
                your own?
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const dispatchFns = {
  toastMessage,
  doAddFeed: addFeed,
};

export default connect(null, dispatchFns)(AI);
