import React, { useState } from "react";
import { clearStored } from "../../services/DataService";
import UserMenu from "./UserMenu";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BrainCircuit,
  MessageCircleQuestion,
  Plus,
  Sparkles,
} from "lucide-react";
import Ask from "../ai/Ask";
import ModalPopup from "../modal/ModalPopup";
import AI from "../ai/AI";

function TopNav({}) {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [showAsk, setShowAsk] = useState(false);
  const [showAI, setShowAI] = useState(false);

  const logOut = () => {
    navigate("/signin");
    clearStored();
  };

  const openUserProfile = () => {
    navigate("/profile");
  };

  const cancelAI = () => setShowAI(false);

  return (
    <div className="row border-bottom">
      <nav
        className="navbar navbar-static-top"
        role="navigation"
        style={{
          margin: 0,
          padding: "10px 15px 10px 15px",
        }}
      >
        <Link
          style={{ cursor: "pointer", padding: "0 20px" }}
          to={"/home/feed"}
          className="navbar-header"
        >
          {/*Contxt*/}
          {/*font Outfit*/}
          <img src="/img/logo/logo1.png" style={{ height: 34 }} />
          {/*<div style={{ height: 34 }} />*/}
        </Link>

        {/*<SearchBar />*/}

        <div className="">
          <a className="cp mx-2 text-primary" onClick={() => setShowAI(true)}>
            <Sparkles />
          </a>
        </div>

        <div style={{ marginRight: 20 }}>
          <UserMenu openUserProfile={openUserProfile} logOut={logOut} />
        </div>
      </nav>

      {showAI && (
        <ModalPopup title="AI Assistant" handleClose={cancelAI} size="lg">
          <AI cancel={cancelAI} />
        </ModalPopup>
      )}
    </div>
  );
}

export default TopNav;
