import {
  addContent,
  deleteDoc,
  getContent,
  updateDoc,
} from "../../services/ContentService";
import {
  ADD_CONTENT,
  EDIT_CONTENT,
  LOAD_CONTENTS,
  REMOVE_CONTENT,
} from "../actionTypes";

export const doAddDoc = (groupId, data) => async (dispatch) => {
  const response = await addContent(groupId, "doc", data);
  dispatch({
    type: ADD_CONTENT,
    payload: {
      groupId,
      item: response.data,
      type: "doc",
    },
  });
  return response;
};

export const doUpdateDoc = (groupId, docId, data) => async (dispatch) => {
  const response = await updateDoc(groupId, docId, data);
  dispatch({
    type: EDIT_CONTENT,
    payload: { groupId, itemId: docId, type: "doc", content: response.data },
  });
};

export const fetchDocs = (groupId) => async (dispatch) => {
  const docs = await getContent(groupId, "doc");
  dispatch({
    type: LOAD_CONTENTS,
    payload: { groupId, items: docs, type: "doc" },
  });
};

export const doDeleteDoc = (groupId, docId) => async (dispatch) => {
  await deleteDoc(groupId, docId);
  dispatch({
    type: REMOVE_CONTENT,
    payload: { groupId, itemId: docId, type: "doc" },
  });
};
