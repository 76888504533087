import React from "react";
import TaskRow from "./TaskRow";

function TaskNodes(props) {
  let { tasks, groupId, startEdit } = props;
  return tasks.map((task, index) => (
    <TaskRow
      key={task._id}
      task={task}
      index={index}
      tasksCount={tasks.length}
      groupId={groupId}
      startEdit={() => startEdit(task)}
    />
  ));
}

export default TaskNodes;
