import { addContent, getFeeds } from "../../services/ContentService";
import { uploadFiles } from "../../services/FileService";
import { ADD_FEED, LOAD_FEEDS, RESET_FEEDS } from "../actionTypes";
import { doAddPost } from "./posts";
import { doAddDoc } from "./docs";
import { doAddTask } from "./task";
import { doAddQuestion } from "./question";
import { doUploadFiles } from "./files";

export const fetchFeeds = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_FEEDS });
    const feeds = await getFeeds();
    dispatch({ type: LOAD_FEEDS, payload: { feeds } });
  };
};

export const addFeed = (type, groupId, data) => async (dispatch) => {
  let response;
  if (type === "media-post") {
    response = await doAddPost(groupId, data)(dispatch);
  }
  if (type === "doc") {
    response = await doAddDoc(groupId, data)(dispatch);
  }
  if (type === "task") {
    response = await doAddTask(groupId, data)(dispatch);
  }
  if (type === "question") {
    response = await doAddQuestion(groupId, data)(dispatch);
  }
  if (type === "files") {
    response = await doUploadFiles(groupId, data)(dispatch);
  }
  // const response = await addContent(groupId, type, data);
  dispatch({
    type: ADD_FEED,
    payload: {
      feed: { ...response.data, type },
    },
  });

  // { value: "media-post", label: "Post" },
  // { value: "task", label: "Task" },
  // { value: "doc", label: "Doc" },
  // { value: "question", label: "Question" },
};
