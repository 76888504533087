import React, { Component } from "react";
import { Link } from "react-router-dom";
import Icon from "../icons/Icon";

export default class GroupLink extends Component {
  render() {
    let group = this.props.group;

    return (
      <div
        className="chat-element d-flex pb-3 mt-3"
        style={{ borderBottom: 0 }}
      >
        <span className="float-left">
          <div
            style={{
              // height: 75,
              // width: 75,
              height: 50,
              width: 50,
              backgroundColor: group.isOwner ? "#999999" : "#6c757d",
              color: group.isOwner ? "" : "#fff",
              borderRadius: 4,
            }}
            className="d-flex align-items-center justify-content-center"
          >
            {group.isOwner ? (
              <Icon style={{ fontSize: 26 }} name="workspaces" />
            ) : (
              <Icon style={{ fontSize: 26 }} name="workspaces" />
            )}
          </div>
        </span>
        <div className="media-body d-flex align-items-center mx-3 flex-fill">
          <div className="flex-fill">
            <div className="dfjbac" style={{ width: "100%" }}>
              <Link to={"/home/groups/" + group._id}>
                <strong
                  style={{
                    wordBreak: "break-all",
                    fontSize: 16,
                  }}
                >
                  {group.name}
                </strong>
              </Link>
              {!group.isOwner && (
                <div className="d-flex justify-content-between">
                  <small className="badge badge-info">
                    {group.userName + "'s"}
                  </small>
                </div>
              )}
            </div>

            <p className="m-b-xs">{group.description}</p>
          </div>
        </div>
      </div>
    );
  }
}
