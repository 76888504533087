import React, { Component } from "react";
import "./home.css";
import { Link } from "react-router-dom";

export default class Navigation extends Component {
  render() {
    return (
      <div>
        <a className="link text-primary mx-2" href="#">
          Use Cases
        </a>
        <a className="link text-primary mx-2" href="#">
          Sign Up
        </a>

        <Link className="btn btn-outline-dark btn-sm" to="/signin">
          Sign In
        </Link>

        {/* <nav id="sidebar-wrapper">
          <ul className="sidebar-nav">
            <li className="sidebar-nav-item">
              <a className="js-scroll-trigger" href="#page-top">
                Home
              </a>
            </li>
            <li className="sidebar-nav-item">
              <a className="js-scroll-trigger" href="#about">
                About
              </a>
            </li>
            <li className="sidebar-nav-item">
              <a className="js-scroll-trigger" href="#services">
                Sign Up
              </a>
            </li>
            <li className="sidebar-nav-item">
              <a className="js-scroll-trigger" href="#portfolio">
                Sign In
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
    );
  }
}
