import React from "react";

class SignUpConfirmation extends React.Component {
  render() {
    var messageNode = (
      <div style={{ color: "white" }}>
        <h3>Thank you for signing up with Bundl.</h3>

        <div style={{ marginTop: "20px", fontSize: 14 }}>
          We need you to confirm your email before accessing your account.
          <br />
          For confirming your email, Please check your email and follow the
          instructions mentioned.
        </div>
      </div>
    );
    return (
      <section className="slice ">
        <div className="wp-section">
          <div className="container">
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                {messageNode}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SignUpConfirmation;
