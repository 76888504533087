import React, { useState } from "react";
import { getNameInitials } from "../../../utils/Utils";
import { connect } from "react-redux";
import { doAddPostComment } from "../../../state/actions/posts";
import { getUser } from "../../../services/DataService";
import Avatar from "../../widgets/Avatar";

const CommentForm = ({ comment, edit, onSubmit, cancel }) => {
  const [content, setContent] = useState(comment ? comment.content : "");

  const submit = () => {
    if (!content) {
      return;
    }
    onSubmit(content);
    setContent("");
  };

  const user = getUser() || {};

  return (
    <div className="d-flex">
      <Avatar size="sm" text={getNameInitials(user.name)} className="mr-2" />
      <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
        <textarea
          style={{ borderRadius: 7 }}
          className="form-control full-width "
          placeholder="Add your comment here...."
          onChange={(e) => setContent(e.target.value)}
          value={content}
        />
        <div className="ml-2 d-flex flex-column justify-content-center">
          {edit && (
            <button className="btn btn-default btn-xs" onClick={cancel}>
              Cancel
            </button>
          )}
          <button className="btn btn-dark btn-xs mb-0" onClick={submit}>
            {edit ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

const actions = {
  addComment: doAddPostComment,
};

export default connect(null, actions)(CommentForm);
