import React, { useEffect } from "react";
import QuestionsView from "./QuestionsView";
import { connect } from "react-redux";
import { fetchQuestions } from "../../../state/actions/question";
import { useParams } from "react-router-dom";

function QuestionsContainer(props) {
  const { groupId } = useParams();
  let questionsLoaded = false;

  useEffect(() => {
    if (props.contentLoaded || questionsLoaded) {
      return;
    }
    questionsLoaded = true;
    props.loadQuestions(groupId);
  }, [groupId]);

  return <QuestionsView {...props} groupId={groupId} />;
}

const actions = {
  loadQuestions: fetchQuestions,
};

const data = (state) => {
  let contents = state.group.contents || {};
  const type = "question";
  return {
    questions: contents[type],
    contentLoaded: !!state.group.contentLoaded[type],
  };
};

export default connect(data, actions)(QuestionsContainer);
