import FeedTitle from "./FeedTitle";
import FeedContent from "./FeedContent";
import React from "react";

const FeedView = ({ feed, groupName, openFile, openTask }) => {
  return (
    <div className="mb-3 white-box">
      <FeedTitle feed={feed} groupName={groupName} />
      <FeedContent feed={feed} openFile={openFile} openTask={openTask} />
    </div>
  );
};

export default FeedView;
