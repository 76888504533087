import React from "react";
import { Carousel } from "react-bootstrap";
import { getFile } from "../../../services/FileService";

class PostCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { images: [] };
  }

  componentDidMount() {
    this.loadFiles();
  }

  loadFiles = () => {
    let { files, groupId } = this.props;
    let { images } = this.state;
    this.setState({ loading: true });
    files.forEach((file) => {
      getFile(groupId, file)
        .then((fileURL) => {
          images.push(fileURL);
          this.setState({ images: [...images], loading: false });
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    let { images = [] } = this.state;
    return (
      <Carousel indicators={false} interval={null} controls={images.length > 1}>
        {images.map((image) => (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image}
              alt=""
              style={{ borderRadius: 10 }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

export default PostCarousel;
