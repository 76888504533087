export const SOCKET_INITIATED = "SOCKET_INITIATED";
export const RESET_GROUP = "RESET_GROUP";
export const SET_GROUP = "SET_GROUP";
export const ADD_CONTENT = "ADD_CONTENT";
export const ADD_SUB_CONTENT = "ADD_SUB_CONTENT";
export const EDIT_SUB_CONTENT = "EDIT_SUB_CONTENT";
export const REMOVE_SUB_CONTENT = "REMOVE_SUB_CONTENT";
export const LOAD_CONTENTS = "LOAD_CONTENTS";
export const SET_CONTENT_LOADED = "SET_CONTENT_LOADED";
export const LOAD_SUB_CONTENTS = "LOAD_SUB_CONTENTS";
export const REMOVE_CONTENT = "REMOVE_CONTENT";
export const EDIT_CONTENT = "EDIT_CONTENT";
export const CHANGE_GROUP_VIEW = "CHANGE_GROUP_VIEW";
export const SHOW_MOVE_OR_COPY = "SHOW_MOVE_OR_COPY";
export const HIDE_MOVE_OR_COPY = "HIDE_MOVE_OR_COPY";
export const DO_NOTHING = "DO_NOTHING";
export const NEW_TOAST = "NEW_TOAST";
export const CLEAR_TOAST = "CLEAR_TOAST";
export const TOGGLE_TYPE_VIEW = "TOGGLE_TYPE_VIEW";
export const TOGGLE_ALL_TYPES_VIEW = "TOGGLE_ALL_TYPES_VIEW";
export const SHOW_ALL_TYPES_VIEW = "SHOW_ALL_TYPES_VIEW";
export const TOGGLE_CALENDAR_OPEN = "TOGGLE_CALENDAR_OPEN";
export const TOGGLE_ACTIVITIES = "TOGGLE_ACTIVITIES";
export const TOGGLE_CHAT_OPEN = "TOGGLE_CHAT_OPEN";

export const RESET_CHAT = "RESET_CHAT";
export const SET_CHAT_GROUP = "SET_CHAT_GROUP";
export const LOAD_CHAT_MESSAGES = "LOAD_CHAT_MESSAGES";
export const REMOVE_CHAT_MESSAGE = "REMOVE_CHAT_MESSAGE";
export const EDIT_CHAT_MESSAGE = "EDIT_CHAT_MESSAGE";
export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
export const LOAD_CHAT_GROUPS = "LOAD_CHAT_GROUPS";
export const ADD_CHAT_GROUP = "ADD_CHAT_GROUP";
export const REMOVE_CHAT_GROUP = "REMOVE_CHAT_GROUP";

export const SET_UI_PAGE = "SET_UI_PAGE";

export const RESET_FEEDS = "RESET_FEEDS";
export const LOAD_FEEDS = "LOAD_FEEDS";
export const ADD_FEED = "ADD_FEED";
