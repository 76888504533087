import React from "react";

export default ({ style, className }) => {
  return (
    <span
      className={`material-icons-outlined ${className ? className : ""}`}
      style={style || { fontSize: 16 }}
    >
      delete_outline
    </span>
  );
};
