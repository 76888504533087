import React from "react";
import PostContent from "./PostContent";
import PostTitle from "./PostTitle";

function PostView({ groupId, post, startEdit, startDelete }) {
  return (
    <div className="mb-4 float-e-margins" key={post._id}>
      <PostTitle post={post} startEdit={startEdit} startDelete={startDelete} />
      <PostContent groupId={groupId} post={post} />
    </div>
  );
}

export default PostView;
