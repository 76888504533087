import React from "react";
import DateTime from "../../widgets/DateTime";
import { useNavigate } from "react-router-dom";

function DocsView({ groupId, docs = [] }) {
  const navigate = useNavigate();

  return (
    <div className="col-md-8 offset-md-2">
      <div className={`ibox float-e-margins`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Docs</h3>

          <div>
            <button
              className="btn btn-dark btn-sm"
              onClick={(e) => {
                navigate(`/home/groups/${groupId}/docs/new`);
              }}
            >
              New Doc
            </button>
          </div>
        </div>

        <div
          className={`d-flex flex-wrap ${docs.length >= 3 ? "justify-content-between" : ""}`}
        >
          {docs.length === 0 && (
            <div className="text-center">There are no docs at the moment.</div>
          )}

          {docs.map((doc, index) => {
            return (
              <div className="col-md-4 mb-3" key={doc._id}>
                <div
                  className="card mr-2"
                  style={{
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                    cursor: "pointer",
                  }}
                  key={doc._id}
                  onClick={() => {
                    navigate(`/home/groups/${groupId}/docs/${doc._id}`);
                  }}
                >
                  <div className="card-body text-center">
                    <h3>{doc.title}</h3>

                    <div className="my-3">
                      <small className=" dfjbac text-muted">
                        <span>{doc.user.name}</span>
                        <span className="mx-1"></span>
                        <DateTime value={new Date(doc.dateCreated)} />
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DocsView;
