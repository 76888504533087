import React, { Component, useEffect, useState } from "react";
import {
  shareGroup,
  getGroupMembers,
  removeUserFromGroup,
  getCollectionOwnerName,
} from "../../../services/GroupService";
import { isExistingUser, getUser } from "../../../services/DataService";
import ShareRow from "./ShareRow";
import UserShareForm from "./UserShareForm";
import { compose } from "redux";
import { connect } from "react-redux";
import { toastMessage } from "../../../state/actions/toaster";
import Icon from "../../icons/Icon";
import { useNavigate } from "react-router-dom";

function Share({ groupId, isOwner, closeModal, toastMessage }) {
  const navigate = useNavigate();
  const [emails, setEmails] = useState([]);
  const [users, setUsers] = useState([]);
  const [owner, setOwner] = useState();

  useEffect(() => {
    loadMembers();
  }, []);

  const loadMembers = async () => {
    let users = await getGroupMembers(groupId);

    if (users.length >= 1) {
      let user = getUser() || {};
      let index = users.findIndex((u) => {
        return u._id === user._id;
      });
      if (index !== -1) users.splice(index, 1);
    }
    setUsers(users);

    if (!isOwner) {
      getCollectionOwnerName(groupId, (owner) => {
        setOwner(owner.name);
      });
    }
  };

  const removeEmail = (email) => {
    let emailIndex = emails.indexOf(email);
    emails.splice(emailIndex, 1);
    setEmails(emails);
  };

  const addEmail = (email, cb) => {
    isExistingUser(email, (response) => {
      if (response.available) {
        if (emails.indexOf(email) === -1) {
          emails.push(email);
        }
        setEmails(emails);
        cb(null);
      } else {
        cb(true);
      }
    });
  };

  const shareToUsers = () => {
    shareGroup(
      groupId,
      {
        emails,
      },
      function (res) {
        if (res.success) {
          closeModal();

          toastMessage(
            "Done! Your group has been shared with the given emails.",
            "success",
            3,
          );

          navigate("/groups/" + groupId);
        } else {
          toastMessage(res.message, "error", 3);
        }
      },
    );
  };

  const removeUserFromGroup = (userId, index) => {
    removeUserFromGroup(groupId, userId, (success) => {
      if (success) {
        users.splice(index, 1);
        setUsers(users);
      }
    });
  };

  let shareNodes = emails.map((c) => {
    return (
      <li key={c._id}>
        <ShareRow email={c} deleteShare={removeEmail} />
      </li>
    );
  });

  return (
    <div>
      {isOwner && (
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h3>Invite New Members</h3>
          </div>
          <div className="ibox-content">
            <UserShareForm addEmail={addEmail} />

            <div>
              <ul className="todo-list m-t small-list">{shareNodes}</ul>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 d-flex justify-content-end">
                <a className="btn btn-primary" onClick={shareToUsers}>
                  Invite
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h3>Existing Members</h3>
        </div>
        <div className="ibox-content">
          {!isOwner && (
            <div
              className="d-flex justify-content-between p-2"
              style={{
                background: "#f3f3f4",
                borderRadius: 4,
                color: "inherit",
                marginBottom: 5,
              }}
            >
              <span className="mr-1">{owner}</span>
            </div>
          )}
          {users.length > 0 && (
            <div>
              <hr />
              <h5></h5>

              {users.map((user, index) => (
                <div
                  className="d-flex justify-content-between p-2"
                  style={{
                    background: "#f3f3f4",
                    borderRadius: 4,
                    color: "inherit",
                    marginBottom: 5,
                  }}
                >
                  <React.Fragment>
                    <span className="mr-1">{user.name}</span>
                    {isOwner && (
                      <span
                        onClick={() => removeUserFromGroup(user._id, index)}
                        style={{ cursor: "pointer" }}
                        className="text-danger"
                      >
                        <Icon className="mr-h" name="close" />
                      </span>
                    )}
                  </React.Fragment>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function actions(dispatch) {
  return {
    toastMessage: toastMessage,
  };
}

export default compose(connect(null, actions))(Share);
