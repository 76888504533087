import React, { Component, useEffect } from "react";
import PostsView from "./PostsView";
import { connect } from "react-redux";
import {
  doAddPost,
  doDeletePost,
  doUpdatePost,
  fetchPosts,
} from "../../../state/actions/posts";
import { useParams } from "react-router-dom";

function PostsContainer(props) {
  const { groupId } = useParams();
  let postsLoaded = false;

  useEffect(() => {
    if (props.contentLoaded || postsLoaded) {
      return;
    }
    postsLoaded = true;
    props.loadPosts(groupId);
  }, [groupId]);

  return <PostsView {...props} groupId={groupId} />;
}

const actions = {
  loadPosts: fetchPosts,
  addPost: doAddPost,
  updatePost: doUpdatePost,
  doDeletePost: doDeletePost,
};

const data = (state) => {
  let contents = state.group.contents || {};
  const type = "media-post";
  return {
    posts: contents[type],
    contentLoaded: !!state.group.contentLoaded[type],
  };
};

export default connect(data, actions)(PostsContainer);
