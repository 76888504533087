import React, { useState } from "react";
import LexicalEditor from "../../common/texteditor/lexical/LexicalEditor";

function AnswerEditor({ answer = {}, submit, cancel, edit }) {
  const [content, setContent] = useState(answer ? answer.content : "");
  const [parsedText, setParsedText] = useState("");

  const onChange = (content, parsed) => {
    setContent(content);
    setParsedText(parsed);
  };

  const submitAnswer = () => {
    if (!content) {
      return;
    }
    submit({ ...answer, content, parsedText });
    setContent("");
  };

  return (
    <div>
      <div>
        <LexicalEditor
          placeholder="Type your answer here"
          content={content}
          onChange={onChange}
          editorStyle={{ height: 120 }}
          editorType="minimal"
        />
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2">
        {edit && (
          <a className="btn btn-default btn-sm mb-0 mr-2" onClick={cancel}>
            Cancel
          </a>
        )}
        <button
          type="submit"
          className="btn btn-sm btn-dark mb-0"
          onClick={submitAnswer}
        >
          {edit ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
}

export default AnswerEditor;
