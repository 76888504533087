import React, { Component } from "react";
export default class UserShareForm extends Component {
  constructor(props) {
    super(props);
    this.state = { emails: [] };
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  addEmail = (e) => {
    e.preventDefault();
    this.setState({ message: "" });
    let email = this.state.email;
    this.props.addEmail(email, (error) => {
      if (error) {
        this.setState({
          message: "Sorry! The mentioned email is not part of system.",
        });
      } else {
        this.setState({ email: "" });
      }
    });
  };
  render() {
    let { email, message } = this.state;

    return (
      <form className="m-t" noValidate onSubmit={this.addEmail}>
        <div className="form-group mb-0">
          <div className="input-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={this.handleEmailChange}
              style={{ height: "40px" }}
            />
            <span className="input-group-btn">
              <button
                type="submit"
                className="btn btn-dark"
                style={{ height: "40px" }}
              >
                Add
              </button>
            </span>
          </div>
        </div>

        {message && <div className="text-danger">{message}</div>}
      </form>
    );
  }
}
