import React from "react";
import PostEditor from "../content/MediaPosts/PostEditor";
import GroupSelector from "../selectors/GroupSelector";

export default function ({ groupId, aiResponse, createPost, selectGroup }) {
  return (
    <div>
      <div className="mb-3">
        The given content looks like a potential post. Would you like to create
        the following post?
      </div>
      <div className="white-box">
        {selectGroup && (
          <div className="mb-3" style={{ width: 200 }}>
            <div>Group</div>
            <GroupSelector selectGroup={selectGroup} />
          </div>
        )}
        <PostEditor
          groupId={groupId}
          submit={createPost}
          post={aiResponse.data}
          editorHeight={250}
          btnLabel="Post"
        />
      </div>
    </div>
  );
}
