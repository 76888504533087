import CommentForm from "./CommentForm";
import React from "react";
import { doAddPostComment } from "../../../state/actions/posts";
import { connect } from "react-redux";

const NewPostComment = ({ groupId, postId, addComment }) => {
  const add = (content) => {
    addComment(groupId, postId, { content });
  };

  return <CommentForm onSubmit={add} />;
};

const actions = {
  addComment: doAddPostComment,
};

export default connect(null, actions)(NewPostComment);
