import React, { useEffect } from "react";
import { connect } from "react-redux";
import GroupView from "./GroupView";
import { fetchGroup } from "../../state/actions/group";
import { navigateToPage } from "../../state/actions/ui";
import { useParams } from "react-router-dom";

function GroupContainer(props) {
  const params = useParams();
  const groupId = params.groupId;

  useEffect(() => {
    props.fetchGroup(groupId);
    props.navigateToPage("GROUP");
  }, [groupId]);

  let { group } = props;
  if (!group) {
    return null;
  }
  return <GroupView group={group} />;
}

const data = (state) => {
  return {
    group: state.group.groupData,
  };
};

const actions = {
  fetchGroup,
  navigateToPage,
};

export default connect(data, actions)(GroupContainer);
