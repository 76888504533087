import React, { useEffect } from "react";
import Icon from "../../icons/Icon";
import {
  doAddPostReaction,
  doFetchPostReactions,
  doRemovePostReaction,
} from "../../../state/actions/posts";
import { connect } from "react-redux";
import { getUser } from "../../../services/DataService";

function PostActions({
  groupId,
  post,
  addReaction,
  fetchPostReactions,
  removeReaction,
}) {
  const user = getUser() || {};
  const { reaction: reactions = [] } = post;
  let index = reactions.findIndex(
    (reaction) => reaction.createdBy.id === user._id,
  );
  useEffect(() => {
    fetchPostReactions(groupId, post._id);
  }, []);

  const toggleLike = () => {
    if (index === -1) {
      addReaction(groupId, post._id, { content: "LIKE", type: "EMOJI" });
    } else {
      removeReaction(groupId, post._id, reactions[index]._id);
    }
  };

  return (
    <div className="dfac ml-2">
      <span style={{ fontSize: 18 }} className="mr-1">
        {reactions.length}
      </span>

      <Icon
        name="thumb_up"
        fontSize={24}
        onClick={toggleLike}
        className={`cp ${index !== -1 ? "text-primary" : ""}`}
      />
    </div>
  );
}

const actions = {
  addReaction: doAddPostReaction,
  removeReaction: doRemovePostReaction,
  fetchPostReactions: doFetchPostReactions,
};

export default connect(null, actions)(PostActions);
