import React from "react";
import { connect } from "react-redux";
import { toggleCalendar } from "../../../state/actions/group";
import CalendarContainer from "./CalendarContainer";
import Icon from "../../icons/Icon";

function CalendarInSidebar({ calendarOpen, toggleCalendar, group }) {
  if (!calendarOpen) {
    return null;
  }
  return (
    <div id="right-sidebar" className="animated sidebar-open right-sidebar-cal">
      <div
        className="sidebar-container"
        style={{ overflow: "auto", width: "", height: "100%" }}
      >
        <div className="px-4 py-2 d-flex justify-content-between align-items-center">
          <h3 className="text-dark d-flex align-items-end">
            <Icon
              style={{ marginRight: 2, fontSize: 32 }}
              name="calendar_month"
            />
            Calendar
          </h3>

          <a onClick={toggleCalendar}>
            <Icon name="cancel" style={{ fontSize: 24 }} />
          </a>
        </div>

        <div
          className="mx-4 p-4 bg-white "
          style={{
            borderRadius: 4,
            height: "calc(100vh - 150px)",
          }}
        >
          <CalendarContainer groupId={group._id} />
        </div>
      </div>
    </div>
  );
}

const data = (state) => {
  return {
    group: state.group.groupData,
    calendarOpen: state.group.calendarOpen,
  };
};

const actions = {
  toggleCalendar,
};

export default connect(data, actions)(CalendarInSidebar);
