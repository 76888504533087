import { $applyNodeReplacement, TextNode } from "lexical";
import * as React from "react";
function $convertMentionElement(domNode) {
  const textContent = domNode.textContent;
  const valueId = domNode.getAttribute("data-mention-value-id");
  const mentionType = domNode.getAttribute("data-mention-type");
  if (textContent !== null) {
    const node = $createMentionNode(textContent, valueId, mentionType);
    return {
      node,
    };
  }
  return null;
}

// const mentionStyle = 'background-color: rgba(24, 119, 232, 0.2)';
const mentionStyle =
  "background-color: #5805ff0d; color: #6a00f5; padding: .1rem .3rem; border-radius: .4rem";

export class MentionNode extends TextNode {
  static getType() {
    return "mention";
  }
  static clone(node) {
    return new MentionNode(
      node.__mention,
      node.__text,
      node.__valueId,
      node.__mentionType,
      node.__key,
    );
  }
  static importJSON(serializedNode) {
    const node = $createMentionNode(
      serializedNode.mentionName,
      serializedNode.valueId,
      serializedNode.mentionType,
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }
  constructor(mentionName, text, valueId, mentionType, key) {
    super(text ?? mentionName, key);
    this.__mention = mentionName;
    this.__valueId = valueId;
    this.__mentionType = mentionType;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      valueId: this.__valueId,
      mentionType: this.__mentionType,
      type: "mention",
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.style.cssText = mentionStyle;
    dom.style.cursor = "pointer";
    dom.className = "mention";
    dom.setAttribute("data-lexical-mention", "true");
    dom.setAttribute("data-mention-value-id", this.__valueId);
    dom.setAttribute("data-mention-type", this.__mentionType);
    // dom.onclick = (e) => {
    //   dom.innerHTML(`<div className="typeahead-popover mentions-menu">
    //     <div>
    //       <div>{showMentionDetails.type}</div>
    //       <div>{showMentionDetails.valueId}</div>
    //       <div>{showMentionDetails.label}</div>
    //     </div>
    //   </div>`);
    // };
    return dom;
  }

  exportDOM() {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-mention", "true");
    element.setAttribute("data-mention-value-id", this.__valueId);
    element.setAttribute("data-mention-type", this.__mentionType);
    element.textContent = this.__text;
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute("data-lexical-mention")) {
          return null;
        }
        return {
          conversion: $convertMentionElement,
          priority: 1,
        };
      },
    };
  }
  isTextEntity() {
    return true;
  }
  canInsertTextBefore() {
    return false;
  }
  canInsertTextAfter() {
    return false;
  }
}

export function $createMentionNode(mentionName, mentionValueId, mentionType) {
  const mentionNode = new MentionNode(
    mentionName,
    undefined,
    mentionValueId,
    mentionType,
  );
  mentionNode.setMode("segmented").toggleDirectionless();
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node) {
  return node instanceof MentionNode;
}
