import React from "react";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { RowMenu, RowMenuItem } from "../../widgets/RowMenu";
import { connect } from "react-redux";
import { moveOrCopy } from "../../../state/actions/group";
import { doDeleteDoc } from "../../../state/actions/docs";
import DeleteIcon from "../../icons/DeleteIcon";
import CopyIcon from "../../icons/CopyIcon";
import MoveIcon from "../../icons/MoveIcon";

class DocsRowMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  deleteDoc = () => {
    let { doc, groupId, deleteDoc } = this.props;
    deleteDoc(groupId, doc._id);
  };

  copy = () => {
    this.moveOrCopy("copy");
  };

  move = () => {
    this.moveOrCopy("move");
  };

  moveOrCopy = (action) => {
    let { doc: item, groupId } = this.props;
    this.props.moveOrCopy({
      groupId,
      item,
      action,
      from: "doc",
    });
  };

  render() {
    return (
      <RowMenu>
        <RowMenuItem>
          <a onClick={this.copy}>
            <CopyIcon className="mr-h" />
            Copy
          </a>
        </RowMenuItem>
        <RowMenuItem>
          <a onClick={this.move}>
            <MoveIcon className="mr-h" />
            Move
          </a>
        </RowMenuItem>

        <RowMenuItem onClick={this.deleteDoc}>
          <span className="text-danger">
            <DeleteIcon className="mr-h" />
            Delete
          </span>
        </RowMenuItem>
      </RowMenu>
    );
  }
}

const actions = {
  deleteDoc: doDeleteDoc,
  moveOrCopy,
};

export default connect(null, actions)(DocsRowMenu);
