import React, { Component } from "react";
import "./home.css";

export default class UseCases extends Component {
  render() {
    return (
      <div>
        <section
          className="content-section py-4 text-bt"
          // style={{ backgroundColor: "#2d85c6" }}
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container">
            <div className="content-section-heading">
              <h2 className="py-3 mb-3">Use Cases</h2>
            </div>
            <div className="row mb-4">
              <div className="col-lg-4 col-md-6 mb-lg-0">
                <h3 style={{ fontSize: 18 }}>Storage</h3>
                <p className="text-faded mb-0 font-lg">
                  Use it as personal or group storage.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 mb-lg-0">
                <h3 style={{ fontSize: 18 }}>Productivity</h3>
                <p className="text-faded mb-0 font-lg">
                  Use it as a tool to manage your everyday activities
                </p>
              </div>

              <div className="col-lg-4 col-md-6 mb-lg-0">
                <h3 style={{ fontSize: 18 }}>Study Space</h3>
                <p className="text-faded mb-0 font-lg">
                  Use it for your study such as colleges and interview
                  preparation.
                </p>
              </div>
            </div>

            <div className="row my-4">
              <div className="col-lg-4 col-md-6 mb-md-0">
                <h3 style={{ fontSize: 18 }}>Planning With Friends</h3>
                <p className="text-faded mb-0 font-lg">
                  Trip planning, Event Management, etc...
                </p>
              </div>

              <div className="col-lg-4 col-md-6">
                <h3 style={{ fontSize: 18 }}>File Sharing</h3>
                <p className="text-faded mb-0 font-lg">
                  Share files with your friends and family
                </p>
              </div>

              <div className="col-lg-4 col-md-6">
                <h3 style={{ fontSize: 18 }}>Your own personal channel</h3>
                <p className="text-faded mb-0 font-lg">
                  No need to use whatsapp or Gmail anymore to move data between
                  your devices
                </p>
              </div>
            </div>

            <div className="row my-4">
              <div className="col-lg-4 col-md-6 mb-md-0">
                <h3 style={{ fontSize: 18 }}>Group Chat</h3>
                <p className="text-faded mb-0 font-lg">
                  Create closed chat rooms with friends and family
                </p>
              </div>

              <div className="col-lg-4 col-md-6">
                <h3 style={{ fontSize: 18 }}>Group Networks</h3>
                <p className="text-faded mb-0 font-lg">
                  Create your own networks with your friends and family
                </p>
              </div>

              <div className="col-lg-4 col-md-6">
                <h3 style={{ fontSize: 18 }}>Workspace for your company</h3>
                <p className="text-faded mb-0 font-lg">
                  Create workspace for your company where you can share info,
                  ask questions and collaborate.
                </p>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-lg-4 col-md-6 mb-md-0">
                <h3 style={{ fontSize: 18 }}>Project Management</h3>
                <p className="text-faded mb-0 font-lg">Manage your projects</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
