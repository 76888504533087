import React from "react";

class ActivitiesView extends React.PureComponent {
  getActivityContent = (activity) => {
    if (activity.action === "add") {
      return (
        <>
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {activity.itemType}
          </span>
          <span> added by {activity.actor.name}</span>
        </>
      );
    } else if (activity.action === "update") {
      return (
        <>
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {activity.itemType}
          </span>
          <span> updated by {activity.actor.name}</span>
        </>
      );
    } else if (activity.action === "delete") {
      return (
        <>
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {activity.itemType}
          </span>
          <span> deleted by {activity.actor.name}</span>
        </>
      );
    }
  };
  render() {
    let { activities = [] } = this.props;

    return (
      <div>
        {activities.map((activity) => {
          return (
            <div style={{ cursor: "pointer" }} className="activity-item">
              {this.getActivityContent(activity)}
              <div>
                <small>{new Date(activity.updatedOn).toLocaleString()}</small>
              </div>
              {/* {activity.itemType} - {activity.action} by {activity.actor.name} */}
              <hr className="my-2" />
            </div>
          );
        })}
      </div>
    );
  }
}

export default ActivitiesView;
